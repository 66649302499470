import { Button, Grid, Row, Col, FormGroup, ControlLabel, FormControl } from "react-bootstrap";
import Loader from "../Loader";
import React, { PureComponent } from "react";
import { StyleSheet, css } from "aphrodite";
import AlertModal from "../AlertModal";
import AuditReportModal from "../AuditReportModal";
import DeleteConfirmModal from "../DeleteConfirmModal";
import * as account from "../../lib/api/account";
import logger from "../../lib/logger";

const styles = StyleSheet.create({
    center: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginBottom: 20,
    },
});

const strings = {
    email: "Email",
    export: "Generate Knowledge Report",
    delete: "Delete Data",
    audit: "Audit Report",
};

export default class CategoryPage extends PureComponent {
    state = {
        email: "",
        loading: false,
        showDeleteConfirmModal: false,
        showAlertModal: false,
        showAuditReportModal: false,
        reportData: null,
        alertMessage: null,
    };

    _handleEmailChange = e => {
        this.setState({ email: e.target.value });
    };

    _handleExport = () => {
        this.setState({ laoding: true });
        account
            .ccpaExport(this.state.email)
            .then(data => {
                const element = document.createElement("a");
                element.setAttribute(
                    "href",
                    "data:text/plain;charset=utf-8," + encodeURIComponent(data),
                );
                element.setAttribute("download", "accountData.csv");
                element.style.display = "none";
                document.body.appendChild(element);
                element.click();
                document.body.removeChild(element);
                this.setState({ laoding: false });
            })
            .catch(err => {
                if (err.response && err.response.status === 404) {
                    this.setState({
                        loading: false,
                        showDeleteConfirmModal: false,
                        alertMessage: `No data was found for ${this.state.email}.`,
                        showAlertModal: true,
                    });
                } else {
                    logger.warn(err);
                    this.setState({
                        loading: false,
                        showDeleteConfirmModal: false,
                        alertMessage: `Unable to export account. Please try again later.`,
                        showAlertModal: true,
                    });
                }
            });
    };

    _handleDeleteClose = () => {
        this.setState({ showDeleteConfirmModal: false });
    };

    _handleDeleteConfirm = () => {
        this.setState({ loading: true });
        account
            .ccpaDelete(this.state.email)
            .then(() => {
                this.setState({ showDeleteConfirmModal: false, loading: false, email: "" });
            })
            .catch(err => {
                if (err.response && err.response.status === 404) {
                    this.setState({
                        loading: false,
                        showDeleteConfirmModal: false,
                        alertMessage: `No data was found for ${this.state.email}.`,
                        showAlertModal: true,
                    });
                } else {
                    logger.warn(err);
                    this.setState({
                        loading: false,
                        showDeleteConfirmModal: false,
                        alertMessage: `Unable to delete account. Please try again later.`,
                        showAlertModal: true,
                    });
                }
                logger.warn(err);
                this.setState({ loading: false });
            });
    };

    _openHandleDeleteModal = () => {
        this.setState({ showDeleteConfirmModal: true });
    };

    _handleAlertClose = () => {
        this.setState({ showAlertModal: false });
    };

    _handleAudit = () => {
        this.setState({ loading: true });
        account
            .ccpaAudit(this.state.email)
            .then(responseData => {
                this.setState({
                    reportData: responseData,
                    showAuditReportModal: true,
                });
            })
            .finally(() => {
                this.setState({ loading: false });
            });
    };

    _handleAuditReportClose = () => {
        this.setState({ showAuditReportModal: false });
    };

    render() {
        return (
            <Grid>
                <Row bsClass="m-t">
                    <Col md={12}>
                        <h4>CCPA</h4>
                    </Col>
                </Row>
                <Row bsClass="m-t">
                    <Col md={4} />
                    <Col md={4}>
                        <FormGroup>
                            <ControlLabel>{strings.email}</ControlLabel>
                            <FormControl
                                type="text"
                                value={this.state.email}
                                onChange={this._handleEmailChange}
                            />
                        </FormGroup>
                        <div className={css(styles.center)}>
                            {this.state.loading ? (
                                <Loader />
                            ) : (
                                <Button
                                    bsStyle="primary"
                                    onClick={this._handleExport}
                                    disabled={!this.state.email}
                                >
                                    {strings.export}
                                </Button>
                            )}
                        </div>
                        <div className={css(styles.center)}>
                            {this.state.loading ? (
                                <Loader />
                            ) : (
                                <Button
                                    bsStyle="primary"
                                    onClick={this._openHandleDeleteModal}
                                    disabled={!this.state.email}
                                >
                                    {strings.delete}
                                </Button>
                            )}
                        </div>
                        <div className={css(styles.center)}>
                            {this.state.loading ? (
                                <Loader />
                            ) : (
                                <Button
                                    bsStyle="primary"
                                    onClick={this._handleAudit}
                                    disabled={!this.state.email}
                                >
                                    {strings.audit}
                                </Button>
                            )}
                        </div>
                    </Col>
                    <Col md={4} />
                </Row>
                <DeleteConfirmModal
                    show={this.state.showDeleteConfirmModal}
                    title={`Please confirm that you would like to permanently information for: ${this.state.email}`}
                    handleClose={this._handleDeleteClose}
                    handleConfirm={this._handleDeleteConfirm}
                />
                <AlertModal
                    show={this.state.showAlertModal}
                    alertMessage={this.state.alertMessage}
                    handleClose={this._handleAlertClose}
                />
                <AuditReportModal
                    show={this.state.showAuditReportModal}
                    title={`Report`}
                    handleClose={this._handleAuditReportClose}
                    reportData={this.state.reportData}
                />
            </Grid>
        );
    }
}
