/* eslint-disable react/jsx-no-bind */

import * as landingPages from "../lib/landingPages";
import * as util from "../lib/util";
import PropTypes from "prop-types";
import React, { PureComponent } from "react";
import { Button, Table } from "react-bootstrap";
import { css, StyleSheet } from "aphrodite";

const styles = StyleSheet.create({
    table: {
        marginTop: "5px",
    },
    linkColHeader: {
        width: "25%",
    },
    linkCell: {
        padding: 0,
        position: "relative",
    },
    linkButton: {
        position: "absolute",
        top: 0,
        left: 0,
        height: "100%",
        width: "100%",
        textAlign: "left",
    },
});

const strings = {
    copyUrl: "Copy URL",
    copy: "Copy",
    edit: "Edit",
};

class LandingPageTable extends PureComponent {
    static propTypes = {
        history: PropTypes.object.isRequired,
        items: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.string.isRequired,
                slug: PropTypes.string,
            }),
        ).isRequired,
        itemToSlug: PropTypes.func,
        extraColumns: PropTypes.arrayOf(
            PropTypes.shape({
                headerText: PropTypes.string.isRequired,
                mapItemToCell: PropTypes.func.isRequired,
            }),
        ).isRequired,
        landingPageUrl: PropTypes.string.isRequired,
        editBaseUrl: PropTypes.string.isRequired,
        mapItemToEditStatePayload: PropTypes.func.isRequired,
    };

    render() {
        return (
            <Table striped bordered hover className={css(styles.table)}>
                <thead>
                    <tr>
                        {this.props.extraColumns.map((columnInfo, i) => (
                            <th key={`col-header-${i}-${columnInfo.headerText}`}>
                                {columnInfo.headerText}
                            </th>
                        ))}
                        <th className={css(styles.linkColHeader)}>{strings.copyUrl}</th>
                        <th className={css(styles.linkColHeader)}>{strings.edit}</th>
                    </tr>
                </thead>
                <tbody>
                    {this.props.items.map(item => {
                        return (
                            <tr key={`row-${item.id}`}>
                                {this.props.extraColumns.map(columnInfo =>
                                    columnInfo.mapItemToCell(item),
                                )}
                                <td className={css(styles.linkCell)}>
                                    <Button
                                        bsStyle={"link"}
                                        className={css(styles.linkButton)}
                                        onClick={() =>
                                            util.copyToClipboard(
                                                landingPages.getFullLandingPageUrl(
                                                    this.props.landingPageUrl,
                                                    this.props.itemToSlug
                                                        ? this.props.itemToSlug(item)
                                                        : item.slug,
                                                ),
                                            )
                                        }
                                    >
                                        {strings.copy}
                                    </Button>
                                </td>
                                <td className={css(styles.linkCell)}>
                                    <Button
                                        bsStyle={"link"}
                                        className={css(styles.linkButton)}
                                        onClick={() =>
                                            this.props.history.push(
                                                `${this.props.editBaseUrl}/${item.id}`,
                                                this.props.mapItemToEditStatePayload(item),
                                            )
                                        }
                                    >
                                        {strings.edit}
                                    </Button>
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </Table>
        );
    }
}

export default LandingPageTable;
