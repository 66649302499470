import { Grid, Row, Col, Tabs, Tab } from "react-bootstrap";
import FailedToLoadAlert from "../FailedToLoadAlert";
import FeaturedProductTab from "../FeaturedProductTab";
import ImageSetUploader from "../ImageSetUploader";
import StoreModifierTab from "../StoreModifierTab";
import StoreProductTab from "../StoreProductTab";
import StoreProductModal from "../StoreProductModal";
import Loader from "../Loader";
import React, { PureComponent } from "react";
import { StyleSheet, css } from "aphrodite";
import * as stores from "../../lib/api/stores";
import * as productType from "../../enums/productType";
import * as menuStatus from "../../enums/menuStatus";
import PropTypes from "prop-types";
import logger from "../../lib/logger";

const styles = StyleSheet.create({
    alertFlag: {
        display: "inline-block",
        marginRight: 10,
    },
    tabWrapper: {
        marginTop: 20,
    },
});

const strings = {
    loading: "Loading",
    titleSuffix: "| Habit CMS",
    featured: "Featured",
    images: "Images",
    heroImage: "Hero Image",
    isolatedImage: "Isolated Image",
    modifiers: "Modifiers",
    edit: "Edit",
    store: "Store",
    items: "Items",
    combos: "Combos",
};

export default class StorePage extends PureComponent {
    static propTypes = {
        match: PropTypes.object.isRequired,
    };

    state = {
        store: null,
        loading: true,
    };

    componentDidMount() {
        this._isMounted = true;
        document.title = `${strings.loading} ${strings.titleSuffix}`;
        stores
            .getStore(this.props.match.params.storeId)
            .then(resp => {
                if (this._isMounted) {
                    this.setState({ store: resp, loading: false });
                    document.title = `${resp.name} ${strings.titleSuffix}`;
                }
            })
            .catch(err => {
                logger.warn(err);
                if (this._isMounted) {
                    this.setState({ loading: false });
                }
            });
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    _handleUploadImage = (image, crop) => {
        return stores.addImage(this.state.store.id, image, crop).then(images => {
            if (this._isMounted) {
                this.setState({
                    store: {
                        ...this.state.store,
                        images,
                    },
                });
            }
            return images;
        });
    };

    _handleSaveInfo = store => {
        if (this._isMounted) {
            this.setState({ store });
        }
    };

    _onAddFeaturedProduct = featuredProductId => {
        return stores
            .featureProduct(this.state.store.id, featuredProductId)
            .then(resp => {
                if (this._isMounted) {
                    this.setState({
                        store: {
                            ...this.state.store,
                            featuredProducts: [...this.state.store.featuredProducts, resp],
                        },
                    });
                }
                return resp;
            })
            .catch(err => logger.warn(err));
    };

    _onRemoveFeaturedProduct = featuredProductId => {
        return stores.deleteFeaturedProduct(this.state.store.id, featuredProductId).then(resp => {
            if (this._isMounted) {
                this.setState({
                    store: {
                        ...this.state.store,
                        featuredProducts: this.state.store.featuredProducts.filter(
                            fp => fp.id !== featuredProductId,
                        ),
                    },
                });
            }
            return resp;
        });
    };

    _reorderFeaturedProduct = (featuredProduct, newIndex) => {
        stores
            .reorderFeaturedProduct(this.state.store.id, featuredProduct.id, newIndex)
            .then(() => {
                if (this._isMounted) {
                    const newFeatured = this.state.store.featuredProducts.filter(
                        f => f.id !== featuredProduct.id,
                    );
                    newFeatured.splice(newIndex, 0, featuredProduct);
                    this.setState({
                        store: {
                            ...this.state.store,
                            featuredProducts: newFeatured,
                        },
                    });
                }
            })
            .catch(e => {
                logger.warn(e);
            });
    };

    _onEditItem = i => {
        this.setState({
            currentProduct: i,
            currentProductType: productType.item,
        });
    };

    _onEditCombo = c => {
        this.setState({
            currentProduct: c,
            currentProductType: productType.combo,
        });
    };

    _onCancelEdit = () => {
        this.setState({
            currentProduct: null,
        });
    };

    _onToggleModifierStatus = modifier => {
        const newStatus =
            modifier.status === menuStatus.available.value
                ? menuStatus.unavailable.value
                : menuStatus.available.value;
        stores
            .updateModifier(this.state.store.id, modifier.modifierId, newStatus)
            .then(() => {
                if (this._isMounted) {
                    this.setState({
                        store: {
                            ...this.state.store,
                            modifiers: this.state.store.modifiers.map(m => {
                                if (m.modifierId === modifier.modifierId) {
                                    return {
                                        ...modifier,
                                        status: newStatus,
                                    };
                                }
                                return m;
                            }),
                        },
                    });
                }
            })
            .catch(e => logger.warn(e));
    };

    _onSaveProductUpdate = (status, statusReason) => {
        stores
            .updateProduct(
                this.state.store.id,
                this.state.currentProduct.id,
                this.state.currentProductType,
                status,
                statusReason,
            )
            .then(() => {
                if (this._isMounted) {
                    this.setState({
                        currentProduct: null,
                        store: {
                            ...this.state.store,
                            items:
                                this.state.currentProductType === productType.item
                                    ? this.state.store.items.map(i => {
                                          if (i.id === this.state.currentProduct.id) {
                                              return {
                                                  ...i,
                                                  status,
                                                  statusReason,
                                              };
                                          }
                                          return i;
                                      })
                                    : this.state.store.items,
                            combos:
                                this.state.currentProductType === productType.combo
                                    ? this.state.store.combos.map(c => {
                                          if (c.id === this.state.currentProduct.id) {
                                              return {
                                                  ...c,
                                                  status,
                                                  statusReason,
                                              };
                                          }
                                          return c;
                                      })
                                    : this.state.store.combos,
                        },
                    });
                }
            })
            .catch(err => logger.warn(err));
    };

    render() {
        if (this.state.loading) {
            return <Loader />;
        } else if (!this.state.store) {
            return <FailedToLoadAlert type={strings.store} />;
        }
        const store = this.state.store;
        return (
            <Grid>
                <Row bsClass="m-t">
                    <Col md={12}>
                        <h4>{store.name}</h4>
                    </Col>
                </Row>
                <Row bsClass="m-t">
                    <Col md={12}>
                        <div className={css(styles.tabWrapper)}>
                            <Tabs defaultActiveKey={1} id="uncontrolled-tab-example">
                                <Tab eventKey={1} title={strings.images}>
                                    <ImageSetUploader
                                        label={strings.heroImage}
                                        images={store ? store.images : null}
                                        onSubmit={this._handleUploadImage}
                                        minWidth={414}
                                        minHeight={350}
                                    />
                                </Tab>
                                <Tab eventKey={2} title={strings.featured}>
                                    <FeaturedProductTab
                                        onAdd={this._onAddFeaturedProduct}
                                        onRemove={this._onRemoveFeaturedProduct}
                                        onReorder={this._reorderFeaturedProduct}
                                        featuredProducts={store.featuredProducts}
                                    />
                                </Tab>
                                <Tab eventKey={3} title={strings.items}>
                                    <StoreProductTab
                                        products={store.items}
                                        onEdit={this._onEditItem}
                                    />
                                </Tab>
                                <Tab eventKey={4} title={strings.combos}>
                                    <StoreProductTab
                                        products={store.combos}
                                        onEdit={this._onEditCombo}
                                    />
                                </Tab>
                                <Tab eventKey={5} title={strings.modifiers}>
                                    <StoreModifierTab
                                        modifiers={store.modifiers}
                                        onToggleStatus={this._onToggleModifierStatus}
                                    />
                                </Tab>
                            </Tabs>
                        </div>
                    </Col>
                </Row>
                {this.state.currentProduct ? (
                    <StoreProductModal
                        key={this.state.currentProduct.id}
                        show
                        handleSave={this._onSaveProductUpdate}
                        handleClose={this._onCancelEdit}
                        status={this.state.currentProduct.status}
                        statusReason={this.state.currentProduct.statusReason}
                        title={`${this.state.store.name} - ${this.state.currentProduct.name}`}
                    />
                ) : null}
            </Grid>
        );
    }
}
