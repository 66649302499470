import { Alert, Glyphicon } from "react-bootstrap";
import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { StyleSheet, css } from "aphrodite";

const styles = StyleSheet.create({
    alertFlag: {
        display: "inline-block",
        marginRight: 10,
    },
});

export default class AlertFlag extends PureComponent {
    static propTypes = {
        label: PropTypes.string.isRequired,
    };

    render() {
        return (
            <Alert bsClass={`alert alert-danger ${css(styles.alertFlag)}`}>
                <Glyphicon glyph="exclamation-sign" />
                {this.props.label}
            </Alert>
        );
    }
}
