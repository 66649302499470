/* eslint-disable react/jsx-no-bind */

import * as nros from "../../lib/api/nros";
import FailedToLoadAlert from "../FailedToLoadAlert";
import PropTypes from "prop-types";
import LandingPageHeader from "../LandingPageHeader";
import LandingPageTable from "../LandingPageTable";
import Loader from "../Loader";
import logger from "../../lib/logger";
import React, { PureComponent } from "react";
import { Col, Grid, Row } from "react-bootstrap";
import { StyleSheet, css } from "aphrodite";

const styles = StyleSheet.create({
    storeNumberCell: {
        width: "15%",
    },
});

const strings = {
    documentTitle: "NRO Landing Pages | Habit CMS",
    pageTitle: "NRO Landing Pages",
    filterByStoreNameNumber: "Filter by Store Name or Number:",
    storeNumber: "Store Number",
    storeName: "Store Name",
    nroUrl: "nro",
    editNROUrl: "/nro",
    newSlug: "new",
    nroTable: "NRO Table",
};

class NROsPage extends PureComponent {
    static propTypes = {
        history: PropTypes.object.isRequired,
    };

    state = {
        nros: [],
        showInactiveNROs: false,
        filter: "",
        isLoading: true,
        isError: false,
        inUseNames: new Set(),
        inUseStoreNumbers: new Set(),
        inUseSlugs: new Set(),
    };

    componentDidMount() {
        this._isMounted = true;
        document.title = strings.documentTitle;
        Promise.all([nros.getNROs(), nros.getNROStores()])
            .then(([nrosResp, storesResp]) => {
                if (this._isMounted) {
                    const nroList = [];

                    for (const nro of nrosResp.nros) {
                        const store = storesResp.stores.find(s => s.id === nro.storeId);
                        // If corresponding store not found, NRO not added to table
                        if (store) {
                            nroList.push({
                                ...nro,
                                store,
                            });
                        }
                    }

                    this.setState({
                        nros: nroList,
                        inUseNames: new Set(nrosResp.nros.map(nro => nro.name)),
                        inUseStoreNumbers: new Set(nroList.map(nro => nro.store.storeNumber)),
                        inUseSlugs: new Set(nrosResp.nros.map(nro => nro.slug)),
                    });
                }
            })
            .catch(e => {
                logger.warn(e);
                if (this._isMounted) {
                    this.setState({ isError: true });
                }
            })
            .finally(() => {
                if (this._isMounted) {
                    this.setState({ isLoading: false });
                }
            });
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    render() {
        const loweredFilter = this.state.filter.toLowerCase().trim();
        const visibleNROs = this.state.nros.filter(nro => {
            if (!nro.isActive && !this.state.showInactiveNROs) {
                return false;
            }

            return (
                nro.store.name.toLowerCase().indexOf(loweredFilter) !== -1 ||
                nro.store.storeNumber.toString().indexOf(loweredFilter) !== -1
            );
        });

        return (
            <Grid>
                <LandingPageHeader
                    title={strings.pageTitle}
                    showInactivePages={this.state.showInactiveNROs}
                    onChangeShowInactivePages={() =>
                        this.setState({ showInactiveNROs: !this.state.showInactiveNROs })
                    }
                    filterLabel={strings.filterByStoreNameNumber}
                    filter={this.state.filter}
                    onChangeFilter={e => this.setState({ filter: e.target.value })}
                    onAddPage={() =>
                        this.props.history.push(`${strings.editNROUrl}/${strings.newSlug}`, {
                            inUseNames: this.state.inUseNames,
                            inUseStoreNumbers: this.state.inUseStoreNumbers,
                            inUseSlugs: this.state.inUseSlugs,
                        })
                    }
                />
                <Row>
                    <Col md={12}>
                        <LandingPageTable
                            history={this.props.history}
                            items={visibleNROs}
                            itemToSlug={nro => nro.slug}
                            extraColumns={[
                                {
                                    headerText: strings.storeNumber,
                                    mapItemToCell: nro => (
                                        <td
                                            key={`${strings.storeNumber}-${nro.id}`}
                                            className={css(styles.storeNumberCell)}
                                        >
                                            {nro.store.storeNumber}
                                        </td>
                                    ),
                                },
                                {
                                    headerText: strings.storeName,
                                    mapItemToCell: nro => (
                                        <td key={`${strings.storeName}-${nro.id}`}>
                                            {nro.store.name}
                                        </td>
                                    ),
                                },
                            ]}
                            landingPageUrl={strings.nroUrl}
                            editBaseUrl={strings.editNROUrl}
                            mapItemToEditStatePayload={nro => ({
                                nro,
                                inUseNames: this.state.inUseNames,
                                inUseStoreNumbers: this.state.inUseStoreNumbers,
                                inUseSlugs: this.state.inUseSlugs,
                            })}
                        />
                        {this.state.isLoading ? <Loader /> : null}
                    </Col>
                </Row>
                {this.state.isError ? (
                    <Row>
                        <FailedToLoadAlert type={strings.nroTable} />
                    </Row>
                ) : null}
            </Grid>
        );
    }
}

export default NROsPage;
