import * as purchaseSource from "../enums/purchaseSource";
import dateFns from "date-fns";
import PropTypes from "prop-types";
import React, { PureComponent } from "react";
import { Button, OverlayTrigger, Table, Tooltip } from "react-bootstrap";

const strings = {
    store: "Store",
    orderDate: "OrderDate",
    date: "Placed Date",
    pickupTime: "Pickup Time",
    status: "Status",
    guestName: "Guest Name",
    checkId: "Check Id",
    source: "Source",
    receipt: "Receipt",
    mobile: "Mobile",
    kiosk: "Kiosk",
    web: "Web",
};

export default class OrdersPage extends PureComponent {
    static propTypes = {
        orders: PropTypes.array.isRequired,
    };

    _formatCreateDate = dateString => {
        const date = new Date(dateString);
        return dateFns.format(date, "MM-DD-YYYY hh:mma");
    };

    _formatPickupTime = dateString => {
        const date = new Date(dateString);
        return dateFns.format(date, "hh:mma");
    };

    _getPurchaseSourceString = s => {
        if (s.purchaseSource === purchaseSource.kiosk.value) {
            return strings.kiosk;
        } else if (
            s.purchaseSource === purchaseSource.mobileIos.value ||
            s.purchaseSource === purchaseSource.mobileAndroid.value
        ) {
            return strings.mobile;
        } else if (s.purchaseSource === purchaseSource.web.value) {
            return purchaseSource.web.label;
        } else if (s.purchaseSource === purchaseSource.webMobile.value) {
            return purchaseSource.webMobile.label;
        }
        return s.purchaseSource;
    };

    _renderStatusTooltip = (id, cancelReason) => (
        <Tooltip id={`tooltip-${id}`}>{cancelReason}</Tooltip>
    );

    _renderStatus = order => {
        const { status, cancelReason, id } = order;
        if (status !== "error" && status !== "cancelled") {
            return status;
        }
        return (
            <OverlayTrigger placement="top" overlay={this._renderStatusTooltip(id, cancelReason)}>
                <a>{status}</a>
            </OverlayTrigger>
        );
    };

    render() {
        return (
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>{strings.store}</th>
                        <th>{strings.date}</th>
                        <th>{strings.pickupTime}</th>
                        <th>{strings.status}</th>
                        <th>{strings.guestName}</th>
                        <th>{strings.checkId}</th>
                        <th>{strings.source}</th>
                        <th>{strings.receipt}</th>
                    </tr>
                </thead>
                <tbody>
                    {this.props.orders.map(s => (
                        <tr key={s.id}>
                            <td>{`${s.storeName} - #${s.storeNumber}`}</td>
                            <td>{this._formatCreateDate(s.createDate)}</td>
                            <td>{this._formatPickupTime(s.pickupDate)}</td>
                            <td>{this._renderStatus(s)}</td>
                            <td>{s.guestName ? s.guestName : null}</td>
                            <td>{s.checkNumber}</td>
                            <td>{this._getPurchaseSourceString(s)}</td>
                            <td>
                                <Button href={s.receiptUrl} target="_blank">
                                    {strings.receipt}
                                </Button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        );
    }
}
