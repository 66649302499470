import { Modal, Button, FormGroup, FormControl, ControlLabel, Checkbox } from "react-bootstrap";
import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import ImageSetUploader from "./ImageSetUploader";
import Loader from "./Loader";
import DeleteConfirmModal from "./DeleteConfirmModal";
import * as tileType from "../enums/tileType";
import * as items from "../lib/api/items";
import * as combos from "../lib/api/combos";
import Select from "react-select";
import { StyleSheet, css } from "aphrodite";

const strings = {
    tile: "Tile",
    cancel: "Cancel",
    delete: "Delete",
    save: "Save",
    confirmDelete: "Are you sure you want to delete this tile?",
    type: "Type",
    image: "Image",
    description: "Description",
    prompt: "Prompt",
    nearbyMiles: "Only Show if User Within Miles (optional)",
    url: "Url",
    item: "Item",
    combo: "Combo",
    active: "Active",
};

const defaultState = {
    showDeleteConfirmModal: false,
    type: null,
    image: null,
    active: false,
    imageData: null,
    crop: null,
    url: null,
    description: null,
    prompt: null,
    nearbyMiles: null,
    productId: null,
    productName: null,
    itemOptions: [],
    comboOptions: [],
    loading: false,
};

const styles = StyleSheet.create({
    checkbox: {
        display: "inline",
        marginLeft: 8,
        top: 5,
    },
});

export default class TileModal extends PureComponent {
    static propTypes = {
        show: PropTypes.bool.isRequired,
        tile: PropTypes.object,
        handleClose: PropTypes.func.isRequired,
        handleSave: PropTypes.func.isRequired,
        handleDelete: PropTypes.func.isRequired,
    };

    state = defaultState;

    static getDerivedStateFromProps(props, state) {
        if (!state.type) {
            if (!props.tile) {
                return {
                    type: tileType.url.value,
                    url: "",
                    prompt: "",
                    description: "",
                    nearbyMiles: null,
                    productId: null,
                };
            } else {
                let productId = null;
                let nearbyMiles = null;
                if (props.tile.type === tileType.item.value) {
                    productId = props.tile.data.itemId;
                    nearbyMiles = props.tile.data.nearbyMiles;
                } else if (props.tile.type === tileType.combo.value) {
                    productId = props.tile.data.comboId;
                }
                return {
                    type: props.tile.type,
                    url: props.tile.data.url,
                    prompt: props.tile.data.prompt,
                    description: props.tile.data.description,
                    active: props.tile.isActive,
                    productId,
                    productName: props.tile.data.name,
                    nearbyMiles,
                };
            }
        }
        if (!props.show) {
            return defaultState;
        }
        return null;
    }

    componentDidMount() {
        this._isMounted = true;
        this._loadOptions(this.state.type);
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    _openDeleteConfirmModal = () => this.setState({ showDeleteConfirmModal: true });
    _closeDeleteConfirmModal = () => this.setState({ showDeleteConfirmModal: false });

    _handleChange = e => {
        this.setState({ question: e.target.value });
    };

    _handleDelete = () => {
        this.setState({ showDeleteConfirmModal: false });
        this.props.handleDelete();
    };

    _onTypeChange = e => {
        this.setState({ type: e.target.value, productId: null, productName: null });
        this._loadOptions(e.target.value);
    };

    _loadOptions = type => {
        if (type === tileType.item.value && !this.state.itemOptions.length) {
            items.getDropdown().then(res => {
                const itemOptions = res.map(i => ({
                    value: i.id,
                    label: i.name,
                }));
                if (this._isMounted) {
                    this.setState({ itemOptions });
                }
            });
        } else if (type === tileType.combo.value && !this.state.comboOptions.length) {
            combos.getDropdown().then(res => {
                const comboOptions = res.map(c => ({
                    value: c.id,
                    label: c.name,
                }));
                if (this._isMounted) {
                    this.setState({ comboOptions });
                }
            });
        }
    };

    _onImage = (image, crop, imageData) => {
        return this.setState({
            image,
            crop,
            imageData,
        });
    };

    _onUrlChange = e => {
        this.setState({ url: e.target.value });
    };

    _onDescription = e => {
        this.setState({ description: e.target.value });
    };

    _onPrompt = e => {
        this.setState({ prompt: e.target.value });
    };

    _onNearbyMiles = e => {
        this.setState({ nearbyMiles: e.target.value });
    };

    _onSave = () => {
        const newTile = {
            type: this.state.type,
            isActive: this.state.active,
            image: this.state.image,
            crop: this.state.crop,
            url: this.state.url,
            description: this.state.description,
            prompt: this.state.prompt,
            nearbyMiles: this.state.nearbyMiles,
            productId: this.state.productId,
        };
        this.setState({ loading: true });
        this.props.handleSave(newTile).then(() => {
            if (this._isMounted) {
                this.setState({
                    loading: false,
                });
            }
        });
    };

    _handleOptionsInputChange = inputValue => {
        if (this.state.type === tileType.item.value) {
            return this.state.itemOptions.filter(
                i => i.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1,
            );
        }
        return this.state.comboOptions.filter(
            i => i.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1,
        );
    };

    _onProductId = o => {
        this.setState({ productId: o.value, productName: o.label });
    };

    _onActiveChange = () => {
        this.setState({ active: !this.state.active });
    };
    render() {
        if (this.state.showDeleteConfirmModal) {
            return (
                <DeleteConfirmModal
                    show={this.state.showDeleteConfirmModal}
                    title={strings.confirmDelete}
                    handleClose={this._closeDeleteConfirmModal}
                    handleConfirm={this.props.handleDelete}
                />
            );
        }
        if (this.state.loading) {
            return (
                <Modal show={this.props.show}>
                    <Modal.Header closeButton>
                        <Modal.Title>{strings.tile}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Loader />
                    </Modal.Body>
                </Modal>
            );
        }
        return (
            <Modal show={this.props.show} onHide={this.props.handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{strings.tile}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {this.props.tile ? null : (
                        <FormGroup>
                            <ControlLabel>{strings.type}</ControlLabel>
                            <FormControl
                                componentClass="select"
                                value={this.state.type}
                                onChange={this._onTypeChange}
                            >
                                <option value={tileType.url.value}>{tileType.url.label}</option>
                                <option value={tileType.item.value}>{tileType.item.label}</option>
                                <option value={tileType.combo.value}>{tileType.combo.label}</option>
                            </FormControl>
                        </FormGroup>
                    )}
                    {this.state.type === tileType.url.value ? null : (
                        <FormGroup>
                            <ControlLabel>
                                {this.state.type === tileType.item.value
                                    ? strings.item
                                    : strings.combo}
                            </ControlLabel>
                            <Select
                                key={this.state.type}
                                options={
                                    this.state.type === tileType.item.value
                                        ? this.state.itemOptions
                                        : this.state.comboOptions
                                }
                                onChange={this._onProductId}
                                value={{
                                    value: this.state.productId,
                                    label: this.state.productName,
                                }}
                            />
                        </FormGroup>
                    )}
                    <ImageSetUploader
                        label={strings.image}
                        images={
                            this.props.tile && !this.state.imageData
                                ? this.props.tile.data.images
                                : null
                        }
                        previewImage={this.state.imageData}
                        onSubmit={this._onImage}
                        minWidth={414}
                        minHeight={350}
                    />
                    <FormGroup>
                        <ControlLabel>{strings.active}</ControlLabel>
                        <Checkbox
                            className={css(styles.checkbox)}
                            checked={this.state.active}
                            onChange={this._onActiveChange}
                        />
                    </FormGroup>
                    {this.state.type === tileType.url.value ? (
                        <FormGroup>
                            <ControlLabel>{strings.url}</ControlLabel>
                            <FormControl value={this.state.url} onChange={this._onUrlChange} />
                        </FormGroup>
                    ) : null}
                    <FormGroup>
                        <ControlLabel>{strings.description}</ControlLabel>
                        <FormControl
                            value={this.state.description}
                            onChange={this._onDescription}
                        />
                    </FormGroup>

                    <FormGroup>
                        <ControlLabel>{strings.prompt}</ControlLabel>
                        <FormControl value={this.state.prompt} onChange={this._onPrompt} />
                    </FormGroup>

                    {this.state.type === tileType.item.value ? (
                        <FormGroup>
                            <ControlLabel>{strings.nearbyMiles}</ControlLabel>
                            <FormControl
                                value={
                                    this.state.nearbyMiles !== null ? this.state.nearbyMiles : ""
                                }
                                onChange={this._onNearbyMiles}
                                type="number"
                            />
                        </FormGroup>
                    ) : null}
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={this.props.handleClose}>{strings.cancel}</Button>
                    {this.props.tile ? (
                        <Button onClick={this._openDeleteConfirmModal} bsStyle="danger">
                            {strings.delete}
                        </Button>
                    ) : null}
                    <Button onClick={this._onSave} bsStyle="primary">
                        {strings.save}
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }
}
