import AddRenumberModal from "../AddRenumberModal";
import { Grid, Row, Col, Table, Button } from "react-bootstrap";
import Loader from "../Loader";
import React, { PureComponent } from "react";
import * as stores from "../../lib/api/stores";
import logger from "../../lib/logger";
import { css, StyleSheet } from "aphrodite";

const styles = StyleSheet.create({
    addButton: {
        marginTop: "16px",
        marginBottom: "16px",
    },
});

const strings = {
    title: "Renumber Stores | Habit CMS",
    bodyTitle: "Store Re-numbers",
    oldNumber: "Old Number",
    newNumber: "New Number",
    addedOn: "Added On",
    delete: "Delete",
    addRenumber: "Add Re-Number",
};

export default class RenumberStoresPage extends PureComponent {
    state = {
        loading: true,
        renumberData: [],
        unrenumberedStores: [],
        showAddRenumberModal: false,
    };

    componentDidMount() {
        this._isMounted = true;
        document.title = strings.title;
        this._loadRenumberData();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    _loadRenumberData = () => {
        if (!this.state.loading) {
            this.setState({ loading: true });
        }

        Promise.all([stores.getRenumbers(), stores.getUnRenumberedStores()])
            .then(response => {
                const renumberData = response[0].renumbers;
                const unrenumberedStores = response[1].storeNumbers;
                this.setState({
                    loading: false,
                    renumberData: renumberData,
                    unrenumberedStores: unrenumberedStores,
                });
            })
            .catch(err => {
                logger.warn(err);
                if (this._isMounted) {
                    this.setState({ loading: false });
                }
            });
    };

    _handleDeleteRenumber = storeNumber => {
        this.setState({ loading: true });
        stores
            .deleteRenumbering(storeNumber)
            .then(() => {
                this._loadRenumberData();
            })
            .catch(() => {
                this.setState({ loading: false });
            });
    };

    _getFormattedDate = date => {
        const dateObject = new Date(date);
        return `${dateObject.toLocaleDateString()} ${dateObject.toLocaleTimeString()}`;
    };

    _handleShowAddRenumberModal = () => {
        this.setState({ showAddRenumberModal: true });
    };

    _handleCloseAddRenumberModal = () => {
        this.setState({ showAddRenumberModal: false });
    };

    _handleSaveRenumbering = (oldNumber, newNumber) => {
        stores.addRenumbering(oldNumber, newNumber).then(() => {
            this.setState({ showAddRenumberModal: false });
            this._loadRenumberData();
        });
    };

    render() {
        return (
            <Grid>
                <Row>
                    <h3>{strings.bodyTitle}</h3>
                </Row>
                <Row className={css(styles.addButton)}>
                    <Col>
                        {this.state.unrenumberedStores.length > 1 ? (
                            <Button bsStyle="primary" onClick={this._handleShowAddRenumberModal}>
                                {strings.addRenumber}
                            </Button>
                        ) : null}
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Table striped bordered hover>
                            <thead>
                                <tr>
                                    <th>{strings.oldNumber}</th>
                                    <th>{strings.newNumber}</th>
                                    <th>{strings.addedOn}</th>
                                    <th>{strings.delete}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.loading
                                    ? null
                                    : this.state.renumberData.map(r => (
                                          <tr key={r.oldNumber}>
                                              <td>{r.oldNumber}</td>
                                              <td>{r.newNumber}</td>
                                              <td>{this._getFormattedDate(r.createDate)}</td>
                                              <td>
                                                  <Button
                                                      bsStyle="danger"
                                                      // eslint-disable-next-line react/jsx-no-bind
                                                      onClick={() =>
                                                          this._handleDeleteRenumber(r.oldNumber)
                                                      }
                                                  >
                                                      {strings.delete}
                                                  </Button>
                                              </td>
                                          </tr>
                                      ))}
                            </tbody>
                        </Table>
                        {this.state.loading ? <Loader /> : null}
                    </Col>
                </Row>
                <AddRenumberModal
                    show={this.state.showAddRenumberModal}
                    oldNumbers={this.state.unrenumberedStores}
                    newNumbers={this.state.unrenumberedStores}
                    handleClose={this._handleCloseAddRenumberModal}
                    handleSave={this._handleSaveRenumbering}
                />
            </Grid>
        );
    }
}
