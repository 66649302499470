import instance from "./instance";
const responseData = response => response.data;

export function getGuests(start, end, filter) {
    let url = `/cms/guests?start=${start}&end=${end}`;
    if (filter) {
        url += `&filter=${filter}`;
    }
    return instance
        .request({
            url,
            method: "GET",
        })
        .then(responseData);
}
