import { Button } from "react-bootstrap";
import React, { PureComponent } from "react";
import * as menuStatus from "../enums/menuStatus";
import PropTypes from "prop-types";

const strings = {
    enable: "un-86",
    disable: "86",
    yes: "Yes",
    no: "No",
};

export default class StoreModifier extends PureComponent {
    static propTypes = {
        modifier: PropTypes.shape({
            globalStatus: PropTypes.string.isRequired,
            menuId: PropTypes.string.isRequired,
            modifierId: PropTypes.string.isRequired,
            name: PropTypes.string.isRequired,
            status: PropTypes.string.isRequired,
        }).isRequired,
        onClick: PropTypes.func.isRequired,
    };

    state = {
        filter: "",
    };

    _onClick = () => this.props.onClick(this.props.modifier);

    render() {
        const { modifier } = this.props;
        return (
            <tr key={`${modifier.menuId}-${modifier.modifierId}`}>
                <td>{modifier.name}</td>
                <td>
                    {modifier.globalStatus === menuStatus.available.value
                        ? strings.yes
                        : strings.no}
                </td>
                <td>{modifier.status === menuStatus.available.value ? strings.no : strings.yes}</td>
                <td>
                    <Button
                        bsStyle={
                            modifier.status === menuStatus.available.value ? "danger" : "primary"
                        }
                        onClick={this._onClick}
                    >
                        {modifier.status === menuStatus.available.value
                            ? strings.disable
                            : strings.enable}
                    </Button>
                </td>
            </tr>
        );
    }
}
