import { Grid, Row, Col, Table, FormGroup, ControlLabel, FormControl } from "react-bootstrap";
import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import StoreModifier from "./StoreModifier";
import { StyleSheet, css } from "aphrodite";

const styles = StyleSheet.create({
    marginTop: {
        marginTop: 20,
    },
});

const strings = {
    name: "Name",
    filterByName: "Filter By Name:",
    globalStatus: "Globally Active?",
    status: "86'd?",
    statusReason: "86'd Reason",
    edit: "Edit",
};

export default class StoreModifierTab extends PureComponent {
    static propTypes = {
        modifiers: PropTypes.array.isRequired,
        onToggleStatus: PropTypes.func.isRequired,
    };

    state = {
        filter: "",
    };

    _onFilterChange = e => {
        this.setState({ filter: e.target.value });
    };

    render() {
        const lowerFilter = this.state.filter.toLowerCase();
        const currentModifiers = this.props.modifiers.filter(
            m => m.name.toLowerCase().indexOf(lowerFilter) !== -1,
        );
        return (
            <Grid>
                <Row>
                    <Col className={css(styles.marginTop)} md={4}>
                        <FormGroup controlId="filter">
                            <ControlLabel>{strings.filterByName}</ControlLabel>
                            <FormControl
                                type="text"
                                value={this.state.filter}
                                placeholder={strings.enterText}
                                onChange={this._onFilterChange}
                            />
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col md={12}>
                        <Table striped bordered hover>
                            <thead>
                                <tr>
                                    <th>{strings.name}</th>
                                    <th>{strings.globalStatus}</th>
                                    <th>{strings.status}</th>
                                    <th>{strings.edit}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {currentModifiers.map(m => (
                                    <StoreModifier
                                        key={`${m.menuId}-${m.modifierId}`}
                                        modifier={m}
                                        onClick={this.props.onToggleStatus}
                                    />
                                ))}
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            </Grid>
        );
    }
}
