import React, { PureComponent } from "react";
import PropTypes from "prop-types";

export default class Unauthenticated extends PureComponent {
    static propTypes = {
        children: PropTypes.node.isRequired,
    };

    render() {
        return <div>{this.props.children}</div>;
    }
}
