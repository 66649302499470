import PropTypes from "prop-types";

export const image = PropTypes.shape({
    id: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    mimeType: PropTypes.string.isRequired,
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
    scale: PropTypes.number.isRequired,
});

export const item = PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    heroImages: PropTypes.arrayOf(image),
    isolatedImages: PropTypes.arrayOf(image),
});

export const combo = PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    heroImages: PropTypes.arrayOf(image),
    isolatedImages: PropTypes.arrayOf(image),
});

export const featuredProduct = PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    productId: PropTypes.string.isRequired,
    productName: PropTypes.string.isRequired,
    productType: PropTypes.string.isRequired,
    images: PropTypes.arrayOf(image),
});

export const featuredProductPartial = PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    productId: PropTypes.string.isRequired,
    productName: PropTypes.string.isRequired,
    productType: PropTypes.string.isRequired,
    storeCount: PropTypes.number.isRequired,
});
