export const available = {
    label: "Available",
    value: "available",
};
export const unavailable = {
    label: "Unavailable",
    value: "unavailable",
};
export const all = {
    label: "All",
    value: "all",
};
