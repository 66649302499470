export const Isolated = "isolated";
export const Hero = "hero";
export const Hero1080 = "hero1080";
export const Isolated424 = "isolated424";
export const Isolated328 = "isolated328";
export const Isolated1036 = "isolated1036";
export const Isolated128 = "isolated128";
export const Isolated352 = "isolated352";
export const Isolated1152 = "isolated1152";
export const InEnvironment720 = "inEnvironment720";
export const InEnvironment1104 = "inEnvironment1104";

