import PropTypes from "prop-types";
import React, { PureComponent } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { css, StyleSheet } from "aphrodite";

const styles = StyleSheet.create({
    crumbRow: {
        backgroundColor: "#EFEFEF",
        marginTop: "-20px",
        marginBottom: "24px",
        marginLeft: "calc(-50vw + 50%)",
        marginRight: "calc(-50vw + 50%)",
        paddingTop: "12px",
        paddingBottom: "12px",
        paddingLeft: "calc(50vw - 50% - 15px)",
        paddingRight: "calc(50vw - 50% - 15px)",
    },
    crumbText: {
        size: "16px",
    },
    duplicateButton: {
        width: "100%",
    },
    savePageButton: {
        width: "100%",
    },
});

const strings = {
    duplicate: "Duplicate",
    savePage: "Save Page",
};

class LandingPageCrumbEditHeader extends PureComponent {
    static propTypes = {
        landingPagesLinkText: PropTypes.string.isRequired,
        landingPagesLinkUrl: PropTypes.string.isRequired,
        pageTitle: PropTypes.string.isRequired,
        isDuplicateEnabled: PropTypes.bool.isRequired,
        onDuplicate: PropTypes.func.isRequired,
        isSavePageEnabled: PropTypes.bool.isRequired,
        onSavePage: PropTypes.func.isRequired,
    };

    render() {
        return (
            <Row className={css(styles.crumbRow)}>
                <Col md={6}>
                    <h4>
                        <a
                            href={this.props.landingPagesLinkUrl}
                        >{`${this.props.landingPagesLinkText}`}</a>
                        {` / ${this.props.pageTitle}`}
                    </h4>
                </Col>
                <Col md={2}>
                    <Button
                        bsStyle="default"
                        className={css(styles.duplicateButton)}
                        onClick={this.props.onDuplicate}
                        disabled={!this.props.isDuplicateEnabled}
                    >
                        {strings.duplicate}
                    </Button>
                </Col>
                <Col md={4}>
                    <Button
                        bsStyle="primary"
                        className={css(styles.savePageButton)}
                        onClick={this.props.onSavePage}
                        disabled={!this.props.isSavePageEnabled}
                    >
                        {strings.savePage}
                    </Button>
                </Col>
            </Row>
        );
    }
}

export default LandingPageCrumbEditHeader;
