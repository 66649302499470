import { Modal, Button } from "react-bootstrap";
import React, { PureComponent } from "react";
import PropTypes from "prop-types";

const strings = {
    ok: "Ok",
};

export default class AlertModal extends PureComponent {
    static propTypes = {
        show: PropTypes.bool.isRequired,
        alertMessage: PropTypes.string,
        handleClose: PropTypes.func.isRequired,
    };

    render() {
        return (
            <Modal show={this.props.show} onHide={this.props.handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{this.props.alertMessage}</Modal.Title>
                </Modal.Header>
                <Modal.Footer>
                    <Button onClick={this.props.handleClose} bsStyle="primary">
                        {strings.ok}
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }
}
