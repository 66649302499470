import {
    Button,
    Checkbox,
    ControlLabel,
    Grid,
    Row,
    Form,
    FormGroup,
    FormControl,
} from "react-bootstrap";
import React, { PureComponent } from "react";
import * as endpoints from "../../lib/api/stores";
import Loader from "../Loader";
import { StyleSheet, css } from "aphrodite";

const styles = StyleSheet.create({
    storeNumbersInput: {
        minHeight: "80px",
        width: "100%",
    },
    importMenusButton: {
        marginBottom: "100px",
    },
});

const strings = {
    title: "Menu Import",
    storeNumbers: "Store IDs",
    enterStoreNumbers: "Enter a list of comma or newline separated store numbers",
    emails: "Emails",
    enterEmails: "Enter a list of comma separated emails",
    bhwEmail: "pfrancis@thebhwgroup.com",
    loadingMessage: "Your import is processing. You will receive an email once the import is done.",
    importAllStores: "Import All Stores",
    invalidStoreNumbers: "The store numbers you have entered are not valid. Please try again.",
    importCompleted:
        "The import attempt was completed. More information has been sent to the emails provided.",
    importPending:
        "The import is in progress. More information will be sent to the emails provided on completion or failure.",
    importInProgressError:
        "The import encountered an error. There is already an import in progress.",
    importGeneralError:
        "The import encountered an error. More information has been sent to the emails provided.",
    importMenus: "Import Menus",
};

const ALWAYS_NOTIFIED_EMAILS = ["pfrancis@thebhwgroup.com"];

export default class StoreImportPage extends PureComponent {
    state = {
        storeNumbers: "",
        emails: "",
        loading: false,
        importAll: false,
    };

    componentDidMount() {
        this._isMounted = true;
        document.title = strings.title;
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    _onstoreNumbersChange = e => {
        this.setState({ storeNumbers: e.target.value });
    };

    _onEmailsChange = e => {
        this.setState({ emails: e.target.value });
    };

    _onImportAllCheckboxChange = () => {
        this.setState({ importAll: !this.state.importAll });
    };

    _onClickImport = () => {
        this.setState({ loading: true });
        let validStoreNumbers = true;

        let storeList = [];
        if (!this.state.importAll) {
            storeList = this.state.storeNumbers
                .split(/,|\n/)
                .map(storeNumStr => storeNumStr.trim())
                .filter(storeNumStr => !!storeNumStr)
                .map(storeNumber => {
                    const parsedStoreNumber = parseInt(storeNumber);
                    if (isNaN(parsedStoreNumber)) {
                        validStoreNumbers = false;
                    }
                    return parsedStoreNumber;
                });
        }

        const emailList = [
            ...this.state.emails.split(",").map(email => email.trim()),
            ...ALWAYS_NOTIFIED_EMAILS,
        ];

        if (!validStoreNumbers) {
            alert(strings.invalidStoreNumbers);
        } else {
            endpoints
                .importStoreMenus(storeList, emailList, this.state.importAll)
                .then(() => {
                    alert(strings.importCompleted);
                    this.setState({ storeNumbers: "" });
                })
                .catch(err => {
                    if (
                        err.code === "ECONNABORTED" &&
                        err.message &&
                        err.message.includes("timeout")
                    ) {
                        alert(strings.importPending);
                        return;
                    }
                    if (err.response && err.response.status === 429) {
                        alert(strings.importInProgressError);
                        return;
                    }
                    alert(strings.importGeneralError);
                })
                .finally(() => {
                    this.setState({ loading: false });
                });
        }
    };

    render() {
        if (this.state.loading) {
            return (
                <Grid>
                    <Row>
                        <h3>{strings.title}</h3>
                    </Row>
                    <Row>{strings.loadingMessage}</Row>
                    <Loader />
                </Grid>
            );
        }

        const isImportEnabled =
            !this.state.loading &&
            !!this.state.emails &&
            (this.state.importAll || !!this.state.storeNumbers);

        return (
            <Grid>
                <Row>
                    <h3>{strings.title}</h3>
                </Row>
                <Row>
                    <Form onSubmit={this._onClickImport}>
                        <FormGroup controlId="storeList">
                            <ControlLabel>{strings.storeNumbers}</ControlLabel>
                            <FormControl
                                className={css(styles.storeNumbersInput)}
                                componentClass="textarea"
                                value={this.state.storeNumbers}
                                placeholder={strings.enterStoreNumbers}
                                onChange={this._onstoreNumbersChange}
                                disabled={this.state.importAll}
                            />
                        </FormGroup>
                        <FormGroup>
                            <ControlLabel>{strings.emails}</ControlLabel>
                            <FormControl
                                type="text"
                                value={this.state.emails}
                                placeholder={strings.enterEmails}
                                onChange={this._onEmailsChange}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Checkbox
                                checked={this.state.importAll}
                                onChange={this._onImportAllCheckboxChange}
                                disabled={this.state.loading}
                            >
                                {strings.importAllStores}
                            </Checkbox>
                        </FormGroup>
                        <Button
                            className={css(styles.importMenusButton)}
                            variant="primary"
                            onClick={this._onClickImport}
                            disabled={!isImportEnabled}
                        >
                            {strings.importMenus}
                        </Button>
                    </Form>
                </Row>
            </Grid>
        );
    }
}
