import {
    Label,
    Grid,
    Row,
    Col,
    FormGroup,
    FormControl,
    ControlLabel,
    Button,
} from "react-bootstrap";
import React, { PureComponent } from "react";
import { connect } from "react-redux";
import * as sessionActions from "../../actions/sessionActions";
import PropTypes from "prop-types";
import Loader from "../Loader";
import * as account from "../../lib/api/account";
import logger from "../../lib/logger";

const strings = {
    login: "Habit Login",
    username: "Username",
    password: "Password",
    submit: "Submit",
    invalidLogin: "Invalid username or password",
};

class LoginPage extends PureComponent {
    static propTypes = {
        storeAndSignIn: PropTypes.func.isRequired,
    };

    state = {
        username: "",
        password: "",
    };

    _handleUsernameChange = e => {
        this.setState({
            username: e.target.value,
        });
    };

    _handlePasswordChange = e => {
        this.setState({
            password: e.target.value,
        });
    };

    _login = e => {
        if (e) {
            e.preventDefault();
        }

        if (this.state.loading) {
            return;
        }

        this.setState({ loading: true });
        account
            .login(this.state.username, this.state.password)
            .then(data => {
                this.setState({ loading: false });
                return this.props.storeAndSignIn(data);
            })
            .catch(err => {
                if (err.response && err.response.status === 400) {
                    this.setState({ loading: false, errorMessage: strings.invalidLogin });
                } else {
                    logger.warn(err);
                    this.setState({ loading: false });
                }
            });
    };

    render() {
        return (
            <Grid>
                <Row>
                    <Col>
                        <h2>{strings.login}</h2>
                    </Col>
                </Row>
                {this.state.errorMessage ? (
                    <Row>
                        <Col md={6}>
                            <Label bsStyle="danger">{this.state.errorMessage}</Label>
                        </Col>
                    </Row>
                ) : null}
                <Row>
                    <Col md={6}>
                        <FormGroup>
                            <ControlLabel>{strings.username}</ControlLabel>
                            <FormControl
                                type="text"
                                value={this.state.username}
                                onChange={this._handleUsernameChange}
                            />
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col md={6}>
                        <FormGroup>
                            <ControlLabel>{strings.password}</ControlLabel>
                            <FormControl
                                type="password"
                                value={this.state.password}
                                onChange={this._handlePasswordChange}
                            />
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col md={4}>
                        {this.state.loading ? (
                            <Loader />
                        ) : (
                            <Button bsStyle="primary" onClick={this._login}>
                                {strings.submit}
                            </Button>
                        )}
                    </Col>
                </Row>
            </Grid>
        );
    }
}

export default connect(null, sessionActions)(LoginPage);
