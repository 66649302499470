import { Modal, Button, Row, Col, FormGroup, ControlLabel, FormControl } from "react-bootstrap";
import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { StyleSheet, css } from "aphrodite";
import * as customers from "../lib/api/customers";

const strings = {
    firstName: "First Name",
    lastName: "Last Name",
    phoneNumber: "Phone Number",
    email: "Email",
    close: "Close",
};

const styles = StyleSheet.create({
    row: {
        marginBottom: 8,
    },
    subHeader: {
        margin: 0,
    },
    saveButton: {
        width: "50%",
        margin: "16px",
    },
});

export default class EditCustomerInfoModal extends PureComponent {
    static propTypes = {
        show: PropTypes.bool.isRequired,
        customer: PropTypes.object.isRequired,
        customerType: PropTypes.object.isRequired,
        onSaveCallback: PropTypes.object.isRequired,
        handleClose: PropTypes.func.isRequired,
    };

    state = {
        firstName: this.props.customer.firstName,
        lastName: this.props.customer.lastName,
        phoneNumber: this.props.customer.phoneNumber,
    };

    _onFirstNameChange = e => {
        this.setState({ firstName: e.target.value });
    };

    _onLastNameChange = e => {
        this.setState({ lastName: e.target.value });
    };

    _onPhoneNumberChange = e => {
        this.setState({ phoneNumber: e.target.value });
    };

    _updateInfo = () => {
        customers
            .updateCustomerInfo(
                this.state.firstName,
                this.state.lastName,
                this.state.phoneNumber,
                this.props.customer.id,
                this.props.customerType,
            )
            .then(() => {
                alert("customer info updated successfully");
            })
            .catch(error => {
                alert("An error occurred: " + error);
            })
            .finally(() => {
                this.props.onSaveCallback();
            });
    };

    render() {
        if (!this.props.show) {
            return null;
        }
        return (
            <Modal show={this.props.show} onHide={this.props.handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Edit User</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row className={css(styles.row)}>
                        <Col md={6}>
                            <FormGroup controlId="firstName">
                                <ControlLabel>{strings.firstName}</ControlLabel>
                                <FormControl
                                    type="text"
                                    value={this.state.firstName}
                                    onChange={this._onFirstNameChange}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row className={css(styles.row)}>
                        <Col md={6}>
                            <FormGroup controlId="lastName">
                                <ControlLabel>{strings.lastName}</ControlLabel>
                                <FormControl
                                    type="text"
                                    value={this.state.lastName}
                                    onChange={this._onLastNameChange}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row className={css(styles.row)}>
                        <Col md={6}>
                            <FormGroup controlId="phoneNumber">
                                <ControlLabel>{strings.phoneNumber}</ControlLabel>
                                <FormControl
                                    type="text"
                                    value={this.state.phoneNumber}
                                    onChange={this._onPhoneNumberChange}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row className={css(styles.row)}>
                        <Col md={6}>
                            <h5 className={css(styles.subHeader)}>{strings.email}</h5>
                            <div className="m-b">{this.props.customer.email}</div>
                        </Col>
                    </Row>
                    <Row className={css(styles.row)}>
                        <Button className={css(styles.saveButton)} onClick={this._updateInfo}>
                            Save
                        </Button>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={this.props.handleClose}>{strings.close}</Button>
                </Modal.Footer>
            </Modal>
        );
    }
}
