/* eslint-disable react/jsx-no-bind */

import PropTypes from "prop-types";
import React, { PureComponent } from "react";
import { css, StyleSheet } from "aphrodite";
import { Dropdown, FormControl, MenuItem } from "react-bootstrap";

const styles = StyleSheet.create({
    dropdown: {
        width: "100%",
    },
    dropdownToggle: {
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
    },
    dropdownMenu: {
        width: "100%",
        overflowY: "auto",
    },
    filterDropdownMenu: {
        paddingTop: 0,
    },
    filterInput: {
        position: "sticky",
        borderRadius: "4px",
        top: 0,
    },
});

const strings = {
    typeToSearch: "Type to search...",
};

class StyledDropdown extends PureComponent {
    static propTypes = {
        id: PropTypes.string.isRequired,
        style: PropTypes.object,
        toggleStyle: PropTypes.object,
        filterInputStyle: PropTypes.object,
        menuStyle: PropTypes.object,
        optionStyle: PropTypes.object,
        placeholder: PropTypes.string.isRequired,
        value: PropTypes.any.isRequired,
        onChange: PropTypes.func.isRequired,
        isFilterable: PropTypes.bool,
        options: PropTypes.arrayOf(
            PropTypes.shape({ value: PropTypes.any.isRequired, label: PropTypes.string }),
        ).isRequired,
    };

    state = {
        isOpen: false,
        filterText: "",
    };

    _onToggle = (isOpen, e, source) => {
        // Hacky way to get around blurring/closing dropdown on input select
        if (this.props.isFilterable && !isOpen && !e && source && source.source === "select") {
            this.setState({ isOpen: true });
            return;
        }

        this.setState({ isOpen, ...(this.props.isFilterable && !isOpen && { filterText: "" }) });
    };

    _onFilterTextChange = e => {
        this.setState({ filterText: e.target.value });
    };

    _setFilterInputRef = c => {
        this.filterInputRef = c;
    };

    render() {
        const buttonText = this.props.value ? this.props.value : this.props.placeholder;
        const isFilterable = this.props.isFilterable ? this.props.isFilterable : false;

        const loweredFilter = this.state.filterText.toLowerCase().trim();
        const visibleOptions = this.props.options.filter(
            option => !loweredFilter || option.value.toString().toLowerCase().indexOf(loweredFilter) !== -1,
        );

        return (
            <Dropdown
                id={this.props.id}
                className={css(styles.dropdown, this.props.style)}
                open={this.state.isOpen}
                onToggle={this._onToggle}
            >
                <Dropdown.Toggle className={css(styles.dropdownToggle, this.props.toggleStyle)}>
                    {buttonText}
                </Dropdown.Toggle>
                <Dropdown.Menu
                    className={css(
                        styles.dropdownMenu,
                        isFilterable && styles.filterDropdownMenu,
                        this.props.menuStyle,
                    )}
                >
                    {isFilterable && this.state.isOpen ? (
                        <FormControl
                            ref={this._setFilterInputRef}
                            className={css(styles.filterInput, this.props.filterInputStyle)}
                            type="text"
                            placeholder={strings.typeToSearch}
                            onChange={this._onFilterTextChange}
                            value={this.state.filterText}
                            autoFocus
                        />
                    ) : null}
                    {visibleOptions.map((option, i) => (
                        <MenuItem
                            key={`${this.props.id}-option-${i}`}
                            className={css(this.props.optionStyle)}
                            onSelect={() => this.props.onChange(option.value)}
                        >
                            {option.label ? option.label : option.value}
                        </MenuItem>
                    ))}
                </Dropdown.Menu>
            </Dropdown>
        );
    }
}

export default StyledDropdown;
