import {
    Grid,
    Row,
    Col,
    Tabs,
    Tab,
    Button,
    FormGroup,
    FormControl,
    ControlLabel,
    Table,
} from "react-bootstrap";
import EditModifierSelectionModal from "../EditModifierSelectionModal";
import FailedToLoadAlert from "../FailedToLoadAlert";
import Loader from "../Loader";
import SuccessAlert from "../SuccessAlert";
import React, { PureComponent } from "react";
import { StyleSheet, css } from "aphrodite";
import * as modifiers from "../../lib/api/modifiers";
import * as menuStatus from "../../enums/menuStatus";

import PropTypes from "prop-types";
import logger from "../../lib/logger";

const styles = StyleSheet.create({
    tabWrapper: {
        marginTop: 20,
    },
    container: {
        marginTop: 20,
    },
    saveButton: {
        marginBottom: 20,
    },
});

const strings = {
    loading: "Loading",
    titleSuffix: "| Habit CMS",
    modifier: "Modifier",
    general: "General",
    selections: "Selections",
    edit: "Edit",
    save: "Save",
    displayName: "Display Name",
    available: "Available",
    yes: "Yes",
    no: "No",
    name: "Name",
    active: "Active",
    saveSuccessful: "Save Successful",
    ingredientNoChange: "Ingredient Default Label",
    optionalNoChange: "Optional Default Label",
};

export default class ModifierPage extends PureComponent {
    static propTypes = {
        match: PropTypes.object.isRequired,
    };

    state = {
        modifier: null,
        loading: true,
        status: null,
        name: null,
        currentSelection: null,
        saveSuccessful: false,
        ingredientNoChange: null,
        optionalNoChange: null,
    };

    componentDidMount() {
        this._isMounted = true;
        document.title = `${strings.loading} ${strings.titleSuffix}`;
        modifiers
            .getModifier(this.props.match.params.modifierId)
            .then(resp => {
                if (this._isMounted) {
                    this.setState({
                        modifier: resp,
                        loading: false,
                        name: resp.name,
                        status: resp.status,
                        ingredientNoChange: resp.ingredientNoChangeSelectionName,
                        optionalNoChange: resp.optionalNoChangeSelectionName,
                    });
                    document.title = `${resp.name} ${strings.titleSuffix}`;
                }
            })
            .catch(err => {
                logger.warn(err);
                if (this._isMounted) {
                    this.setState({ loading: false });
                }
            });
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    _handleNameChange = e => {
        this.setState({ name: e.target.value });
    };

    _handleStatusChange = e => {
        this.setState({ status: e.target.value });
    };

    _handleIngredientNoChangeChange = e => {
        this.setState({ ingredientNoChange: e.target.value });
    };

    _handleOptionalNoChangeChange = e => {
        this.setState({ optionalNoChange: e.target.value });
    };

    _onSave = () => {
        modifiers
            .updateModifier(
                this.state.modifier.id,
                this.state.name,
                this.state.status,
                this.state.ingredientNoChange,
                this.state.optionalNoChange,
            )
            .then(resp => {
                if (this._isMounted) {
                    this.setState({
                        modifier: resp,
                        name: resp.name,
                        status: resp.status,
                        saveSuccessful: true,
                    });
                    if (this._hideSaveSuccessful) {
                        clearTimeout(this._hideSaveSuccessful);
                    }
                    this._hideSaveSuccessful = setTimeout(() => {
                        if (this._isMounted) {
                            this.setState({ saveSuccessful: false });
                        }
                        this._hideSaveSuccessful = null;
                    }, 2000);
                }
            })
            .catch(err => {
                logger.warn(err);
            });
    };

    _editSelection = selection => {
        this.setState({
            currentSelection: selection,
        });
    };

    _saveCurrentSelection = (name, status) => {
        const currentSelectionId = this.state.currentSelection.id;
        modifiers
            .updateSelection(this.state.modifier.id, currentSelectionId, name, status)
            .then(() => {
                if (this._isMounted) {
                    this.setState({
                        modifier: {
                            ...this.state.modifier,
                            selections: this.state.modifier.selections.map(s => {
                                if (s.id === currentSelectionId) {
                                    return {
                                        ...s,
                                        name,
                                        status,
                                    };
                                }
                                return s;
                            }),
                        },
                        currentSelection: null,
                    });
                }
            })
            .catch(err => {
                logger.warn(err);
            });
    };

    _cancelSeletionEdit = () => {
        this.setState({ currentSelection: null });
    };

    render() {
        if (this.state.loading) {
            return <Loader />;
        } else if (!this.state.modifier) {
            return <FailedToLoadAlert type={strings.modifier} />;
        }
        const modifier = this.state.modifier;
        return (
            <Grid>
                <Row bsClass="m-t">
                    <Col md={6}>
                        <h4>{modifier.name}</h4>
                    </Col>
                </Row>
                <Row bsClass="m-t">
                    <Col md={12}>
                        <div className={css(styles.tabWrapper)}>
                            <Tabs defaultActiveKey={1} id="uncontrolled-tab-example">
                                <Tab eventKey={1} title={strings.general}>
                                    <Grid className={css(styles.container)}>
                                        <Row>
                                            <Col md={2}>
                                                <Button
                                                    className={css(styles.saveButton)}
                                                    bsStyle="primary"
                                                    onClick={this._onSave}
                                                >
                                                    {strings.save}
                                                </Button>
                                            </Col>
                                            {this.state.saveSuccessful ? (
                                                <Col md={2}>
                                                    <SuccessAlert label={strings.saveSuccessful} />
                                                </Col>
                                            ) : null}
                                        </Row>
                                        <Row>
                                            <Col md={6}>
                                                <FormGroup controlId="name">
                                                    <ControlLabel>
                                                        {strings.displayName}
                                                    </ControlLabel>
                                                    <FormControl
                                                        type="text"
                                                        value={this.state.name}
                                                        placeholder={strings.enterText}
                                                        onChange={this._handleNameChange}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col md={6}>
                                                <FormGroup controlId="formControlsSelect">
                                                    <ControlLabel>{strings.available}</ControlLabel>
                                                    <FormControl
                                                        componentClass="select"
                                                        value={this.state.status}
                                                        onChange={this._handleStatusChange}
                                                    >
                                                        <option value={menuStatus.available.value}>
                                                            {strings.yes}
                                                        </option>
                                                        <option
                                                            value={menuStatus.unavailable.value}
                                                        >
                                                            {strings.no}
                                                        </option>
                                                    </FormControl>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={6}>
                                                <FormGroup>
                                                    <ControlLabel>
                                                        {strings.ingredientNoChange}
                                                    </ControlLabel>
                                                    <FormControl
                                                        type="text"
                                                        value={this.state.ingredientNoChange}
                                                        placeholder={strings.enterText}
                                                        onChange={
                                                            this._handleIngredientNoChangeChange
                                                        }
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col md={6}>
                                                <FormGroup>
                                                    <ControlLabel>
                                                        {strings.optionalNoChange}
                                                    </ControlLabel>
                                                    <FormControl
                                                        type="text"
                                                        value={this.state.optionalNoChange}
                                                        placeholder={strings.enterText}
                                                        onChange={
                                                            this._handleOptionalNoChangeChange
                                                        }
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </Grid>
                                </Tab>
                                <Tab eventKey={2} title={strings.selections}>
                                    <div className={css(styles.tabWrapper)}>
                                        <Table striped bordered hover>
                                            <thead>
                                                <tr>
                                                    <th>{strings.name}</th>
                                                    <th>{strings.active}</th>
                                                    <th>{strings.edit}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {modifier.selections.map(s => {
                                                    return (
                                                        <tr key={s.id}>
                                                            <td>{s.name}</td>
                                                            <td>
                                                                {s.status ===
                                                                menuStatus.available.value
                                                                    ? strings.yes
                                                                    : strings.no}
                                                            </td>
                                                            <td>
                                                                <Button
                                                                    bsStyle="primary"
                                                                    onClick={() =>
                                                                        this._editSelection(s)
                                                                    }
                                                                >
                                                                    {strings.edit}
                                                                </Button>
                                                            </td>
                                                        </tr>
                                                    );
                                                })}
                                            </tbody>
                                        </Table>
                                    </div>
                                    {this.state.currentSelection ? (
                                        <EditModifierSelectionModal
                                            show
                                            initialName={this.state.currentSelection.name}
                                            initialStatus={this.state.currentSelection.status}
                                            handleClose={this._cancelSeletionEdit}
                                            handleSave={this._saveCurrentSelection}
                                        />
                                    ) : null}
                                </Tab>
                            </Tabs>
                        </div>
                    </Col>
                </Row>
            </Grid>
        );
    }
}
