import instance from "./instance";
const responseData = response => response.data;

export function getTiles() {
    return instance
        .request({
            url: `/cms/tiles`,
            method: "GET",
        })
        .then(responseData);
}

export function updateTile(
    id,
    type,
    image,
    crop,
    productId,
    url,
    description,
    prompt,
    isActive,
    nearbyMiles,
) {
    const formData = new FormData();
    formData.append("id", id);
    formData.append("type", type);
    formData.append("isActive", isActive);
    if (image) {
        formData.append("image", image);
        formData.append("centerX", crop.x);
        formData.append("centerY", crop.y);
        formData.append("width", crop.width);
        formData.append("height", crop.height);
    }
    if (productId) {
        formData.append("productId", productId);
    }
    if (url) {
        formData.append("url", url);
    }
    if (prompt) {
        formData.append("prompt", prompt);
    }
    if (nearbyMiles) {
        formData.append("nearbyMiles", nearbyMiles);
    }
    formData.append("description", description);
    return instance
        .request({
            url: `/cms/tiles/${type}/${id}`,
            method: "POST",
            data: formData,
        })
        .then(responseData);
}

export function newTile(
    type,
    image,
    crop,
    productId,
    url,
    description,
    prompt,
    isActive,
    nearbyMiles,
) {
    const formData = new FormData();
    formData.append("type", type);
    formData.append("isActive", isActive);
    if (image) {
        formData.append("image", image);
        formData.append("centerX", crop.x);
        formData.append("centerY", crop.y);
        formData.append("width", crop.width);
        formData.append("height", crop.height);
    }
    if (productId) {
        formData.append("productId", productId);
    }
    if (url) {
        formData.append("url", url);
    }
    if (prompt) {
        formData.append("prompt", prompt);
    }
    if (nearbyMiles) {
        formData.append("nearbyMiles", nearbyMiles);
    }
    formData.append("description", description);
    return instance
        .request({
            url: `/cms/tiles/${type}`,
            method: "POST",
            data: formData,
        })
        .then(responseData);
}

export function reorder(id, position) {
    return instance
        .request({
            url: `/cms/tiles/${id}/reorder`,
            method: "POST",
            data: {
                position,
            },
        })
        .then(responseData);
}

export function deleteTile(id) {
    return instance
        .request({
            url: `/cms/tiles/${id}`,
            method: "DELETE",
        })
        .then(responseData);
}
