import * as actionTypes from "../enums/actionTypes";
import createReducer from "../lib/createReducer";

const defaultState = {
    accessToken: null,
    expireDate: null,
    refreshToken: null,
};

function SESSION_SIGN_IN(state, action) {
    const { accessToken, expireDate, refreshToken, scopes } = action;

    return {
        ...state,
        accessToken,
        expireDate,
        refreshToken,
        scopes,
    };
}

function SESSION_SIGN_OUT() {
    return defaultState;
}

const handlers = {};
handlers[actionTypes.SESSION_SIGN_IN] = SESSION_SIGN_IN;
handlers[actionTypes.SESSION_SIGN_OUT] = SESSION_SIGN_OUT;
export default createReducer(defaultState, handlers);
