import { Grid, Row, Col } from "react-bootstrap";
import Loader from "../Loader";
import PageButtons from "../PageButtons";
import OrderTable from "../OrderTable";
import React, { PureComponent } from "react";
import * as orders from "../../lib/api/orders";
import logger from "../../lib/logger";
import { css, StyleSheet } from "aphrodite";
import PropTypes from "prop-types";

const styles = StyleSheet.create({
    center: {
        textAlign: "center",
    },
});

const strings = {
    title: "Orders | Habit CMS",
    orders: "Orders",
    of: "of",
};

const PAGE_SIZE = 20;

export default class GuestOrdersPage extends PureComponent {
    static propTypes = {
        match: PropTypes.object.isRequired,
    };

    state = {
        currentPage: 0,
        filter: "",
        loading: true,
        orders: [],
        total: 0,
    };

    componentDidMount() {
        this._isMounted = true;
        document.title = strings.title;
        this._loadOrders();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    _onFilterChange = e => {
        this.setState({ filter: e.target.value }, this._loadOrders);
    };

    _loadOrders = page => {
        if (!page) {
            page = 0;
        }
        if (!this.state.loading) {
            this.setState({ loading: true });
        }
        const currentReqTerms = {
            page: page,
            filter: this.state.filter,
        };
        this._currentReqTerms = currentReqTerms;
        orders
            .getGuestOrders(
                this.props.match.params.accountId,
                page * PAGE_SIZE,
                page * PAGE_SIZE + PAGE_SIZE,
                this.state.filter,
            )
            .then(resp => {
                if (this._isMounted && this._currentReqTerms === currentReqTerms) {
                    this.setState({
                        loading: false,
                        orders: resp.orders,
                        total: resp.total,
                        currentPage: page,
                    });
                }
            })
            .catch(err => {
                logger.warn(err);
                if (this._isMounted) {
                    this.setState({ loading: false });
                }
            });
    };

    _handlePageChange = page => {
        this._loadOrders(page - 1);
    };

    render() {
        return (
            <Grid>
                <Row>
                    <h3>{strings.orders}</h3>
                </Row>
                <Row>
                    <Col md={12}>
                        <OrderTable orders={this.state.orders} />
                        {this.state.loading ? <Loader /> : null}
                    </Col>
                </Row>
                <Row>
                    <Col md={2}>
                        <div>
                            {Math.min((this.state.currentPage + 1) * PAGE_SIZE, this.state.total)}{" "}
                            {`${strings.of} `}
                            {this.state.total}
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col md={12} className={css(styles.center)}>
                        <PageButtons
                            numPages={this.state.total / PAGE_SIZE}
                            currentPage={this.state.currentPage + 1}
                            handlePageChange={this._handlePageChange}
                        />
                    </Col>
                </Row>
            </Grid>
        );
    }
}
