import * as feedbackApi from "../../lib/api/feedback";
import FeedbackTable from "../FeedbackTable";
import FeedbackModal from "../FeedbackModal";
import Loader from "../Loader";
import logger from "../../lib/logger";
import PageButtons from "../PageButtons";
import React, { PureComponent } from "react";
import { css, StyleSheet } from "aphrodite";
import { Button, Grid, Row, Col, FormGroup, FormControl, ControlLabel } from "react-bootstrap";
import debounce from "lodash.debounce";

const styles = StyleSheet.create({
    center: {
        textAlign: "center",
    },
    downloadButton: {
        marginLeft: "auto",
    },
});

const strings = {
    endDate: "End Date",
    error: "An error occured while getting report",
    feedback: "Feedback",
    search: "Search",
    searchPlaceholder: "Name, Email, Phone",
    startDate: "Start Date",
    title: "Feedback | Habit CMS",
    of: "of",
    download: "Download .CSV",
    downloading: "Downloading...",
};

const PAGE_SIZE = 20;

export default class FeedbackPage extends PureComponent {
    state = {
        currentPage: 0,
        downloading: false,
        feedbackModal: undefined,
        search: "",
        loading: true,
        feedback: [],
        total: 0,
        startDate: undefined,
        endDate: undefined,
    };

    _mounted = true;

    componentDidMount() {
        document.title = strings.title;
        this._loadFeedback();
    }

    componentWillUnmount() {
        this._mounted = false;
    }

    _onSearchChange = e => {
        this.setState({ search: e.target.value }, this._loadFeedback);
    };

    _onStartDateChange = e => {
        this.setState({ startDate: e.target.value }, this._loadFeedback);
    };

    _onEndDateChange = e => {
        this.setState({ endDate: e.target.value }, this._loadFeedback);
    };

    _loadFeedback = debounce(page => {
        if (!page) {
            page = 0;
        }
        if (!this.state.loading) {
            this.setState({ loading: true });
        }
        const currentParams = {
            page: page,
            search: this.state.search,
            startDate: this.state.startDate,
            endDate: this.state.endDate,
        };
        this._currentParams = currentParams;
        feedbackApi
            .get(
                page * PAGE_SIZE,
                page * PAGE_SIZE + PAGE_SIZE,
                this.state.startDate,
                this.state.endDate,
                this.state.search,
            )
            .then(resp => {
                if (this._mounted && this._currentParams === currentParams) {
                    this.setState({
                        loading: false,
                        feedback: resp.feedback,
                        total: resp.total,
                        currentPage: page,
                    });
                }
            })
            .catch(err => {
                logger.warn(err);
                if (this._mounted) {
                    window.alert(strings.error);
                    this.setState({ loading: false });
                }
            });
    }, 250);

    _downloadFeedback = () => {
        if (this.state.downloading === true) {
            return;
        }
        this.setState({ downloading: true });
        feedbackApi
            .download(this.state.startDate, this.state.endDate, this.state.search)
            .then(res => {
                const { data, headers } = res;
                let filename = "Feedback.csv";
                const contentDisposition = headers["content-disposition"];
                if (contentDisposition) {
                    filename = contentDisposition.match(
                        /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/,
                    )[1];
                }
                const a = document.createElement("a");
                a.href = window.URL.createObjectURL(data);
                a.download = filename;
                a.dispatchEvent(new MouseEvent("click"));
            })
            .finally(() => this.setState({ downloading: false }));
    };

    _handlePageChange = page => {
        this._loadFeedback(page - 1);
    };

    _setFeedbackModal = feedbackModal => {
        this.setState({ feedbackModal });
    };

    _closeFeedbackModal = () => {
        this.setState({ feedbackModal: undefined });
    };

    render() {
        return (
            <Grid>
                <Row>
                    <h3>{strings.feedback}</h3>
                </Row>
                <Row>
                    <Col md={4}>
                        <FormGroup controlId="search">
                            <ControlLabel>{strings.search}</ControlLabel>
                            <FormControl
                                type="text"
                                value={this.state.search}
                                placeholder={strings.searchPlaceholder}
                                onChange={this._onSearchChange}
                            />
                        </FormGroup>
                    </Col>
                    <Col md={2}>
                        <FormGroup controlId="startDate">
                            <ControlLabel>{strings.startDate}</ControlLabel>
                            <FormControl
                                type="date"
                                value={this.state.startDate}
                                onChange={this._onStartDateChange}
                            />
                        </FormGroup>
                    </Col>
                    <Col md={2}>
                        <FormGroup controlId="endDate">
                            <ControlLabel>{strings.endDate}</ControlLabel>
                            <FormControl
                                type="date"
                                value={this.state.endDate}
                                onChange={this._onEndDateChange}
                            />
                        </FormGroup>
                    </Col>
                    <Col md={2} mdOffset={2}>
                        <Button active={this.state.downloading} onClick={this._downloadFeedback}>
                            {this.state.downloading === true
                                ? strings.downloading
                                : strings.download}
                        </Button>
                    </Col>
                </Row>
                <Row>
                    <Col md={12}>
                        <FeedbackTable
                            feedback={this.state.feedback}
                            setFeedbackModal={this._setFeedbackModal}
                        />
                        {this.state.loading ? <Loader /> : null}
                    </Col>
                </Row>
                <Row>
                    <Col md={2}>
                        <div>
                            {Math.min((this.state.currentPage + 1) * PAGE_SIZE, this.state.total)}{" "}
                            {`${strings.of} `}
                            {this.state.total}
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col md={12} className={css(styles.center)}>
                        <PageButtons
                            numPages={this.state.total / PAGE_SIZE}
                            currentPage={this.state.currentPage + 1}
                            handlePageChange={this._handlePageChange}
                        />
                    </Col>
                </Row>
                {this.state.feedbackModal ? (
                    <FeedbackModal
                        feedback={this.state.feedbackModal}
                        show={!!this.state.feedbackModal}
                        handleClose={this._closeFeedbackModal}
                    />
                ) : null}
            </Grid>
        );
    }
}
