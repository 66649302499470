import { Button, Grid, Row, Col, Table } from "react-bootstrap";
import Loader from "../Loader";
import React, { PureComponent } from "react";
import ImagePreview from "../ImagePreview";
import * as categories from "../../lib/api/categories";
import tableStyles from "../../styles/tableStyles";
import { css } from "aphrodite";
import PropTypes from "prop-types";
import logger from "../../lib/logger";

const strings = {
    title: "Categories | Habit CMS",
    categories: "Categories",
    name: "Name",
    itemCount: "Item Count",
    comboCount: "Combo Count",
    sort: "Sort",
    edit: "Edit",
    add: "Add",
};

export default class CategoriesPage extends PureComponent {
    static propTypes = {
        history: PropTypes.object.isRequired,
    };

    state = {
        loading: true,
        categories: [],
    };

    componentDidMount() {
        this._isMounted = true;
        document.title = strings.title;
        categories
            .getCategories()
            .then(c => {
                if (this._isMounted) {
                    this.setState({ categories: c.categories, loading: false });
                }
            })
            .catch(e => {
                logger.warn(e);
                if (this._isMounted) {
                    this.setState({ loading: false });
                }
            });
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    _reorderCateogry = (category, newIndex) => {
        categories
            .reorder(category.id, newIndex)
            .then(() => {
                if (this._isMounted) {
                    const newCategories = this.state.categories.filter(c => c.id !== category.id);
                    newCategories.splice(newIndex, 0, category);
                    this.setState({ categories: newCategories });
                }
            })
            .catch(e => {
                logger.warn(e);
            });
    };

    render() {
        if (this.state.loading) {
            return <Loader />;
        }
        return (
            <Grid>
                <Row>
                    <h3>{strings.categories}</h3>
                </Row>
                <Row>
                    <Col md={12}>
                        <Table striped bordered hover>
                            <thead>
                                <tr>
                                    <th />
                                    <th>{strings.name}</th>
                                    <th>{strings.itemCount}</th>
                                    <th>{strings.comboCount}</th>
                                    <th>{strings.sort}</th>
                                    <th>{strings.edit}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.categories.map((c, i) => (
                                    <tr key={c.id}>
                                        <td
                                            className={css(
                                                tableStyles.imagePreview,
                                                c.isolatedImages && c.isolatedImages.length
                                                    ? tableStyles.danger
                                                    : null,
                                            )}
                                        >
                                            <ImagePreview height={70} images={c.isolatedImages} />
                                        </td>
                                        <td>{c.name}</td>
                                        <td>{c.itemCount}</td>
                                        <td>{c.comboCount}</td>
                                        <td>
                                            {
                                                <div>
                                                    {i === 0 ? null : (
                                                        <Button
                                                            onClick={() =>
                                                                this._reorderCateogry(c, i - 1)
                                                            }
                                                        >
                                                            &#9650;
                                                        </Button>
                                                    )}
                                                    {i ===
                                                    this.state.categories.length - 1 ? null : (
                                                        <Button
                                                            onClick={() =>
                                                                this._reorderCateogry(c, i + 1)
                                                            }
                                                        >
                                                            &#9660;
                                                        </Button>
                                                    )}
                                                </div>
                                            }
                                        </td>
                                        <td>
                                            <Button
                                                bsStyle="primary"
                                                onClick={() =>
                                                    this.props.history.push(`/category/${c.id}`)
                                                }
                                            >
                                                {strings.edit}
                                            </Button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            </Grid>
        );
    }
}
