import { Badge, Button, Grid, Row, Col, Table, Tabs, Tab } from "react-bootstrap";
import FailedToLoadAlert from "../FailedToLoadAlert";
import FeaturedProductTab from "../FeaturedProductTab";
import ImageSetUploader from "../ImageSetUploader";
import LinkButton from "../LinkButton";
import Loader from "../Loader";
import React, { PureComponent } from "react";
import { StyleSheet, css } from "aphrodite";
import * as categories from "../../lib/api/categories";
import * as imageTypes from "../../enums/imageType";
import * as menuStatus from "../../enums/menuStatus";
import PropTypes from "prop-types";
import logger from "../../lib/logger";

const styles = StyleSheet.create({
    alertFlag: {
        display: "inline-block",
        marginRight: 10,
    },
    tabWrapper: {
        marginTop: 20,
    },
});

const strings = {
    loading: "Loading",
    titleSuffix: "| Habit CMS",
    category: "Category",
    images: "Images",
    isolatedImage: "Isolated Image",
    isolatedImage128: "Rebrand Isolated Image 128",
    items: "Items",
    combos: "Combos",
    featuredProducts: "Featured Products",
    edit: "Edit",
    name: "Name",
    active: "Active?",
    sort: "Sort",
    yes: "Yes",
    no: "No",
};

export default class CategoryPage extends PureComponent {
    static propTypes = {
        match: PropTypes.object.isRequired,
    };

    state = {
        category: null,
        loading: true,
    };

    componentDidMount() {
        this._isMounted = true;
        document.title = `${strings.loading} ${strings.titleSuffix}`;
        categories
            .getCategory(this.props.match.params.categoryId)
            .then(resp => {
                if (this._isMounted) {
                    this.setState({ category: resp, loading: false });
                    document.title = `${resp.name} ${strings.titleSuffix}`;
                }
            })
            .catch(err => {
                logger.warn(err);
                if (this._isMounted) {
                    this.setState({ loading: false });
                }
            });
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    _handleUploadImage = (imageType, image, crop) => {
        return categories
            .addImage(this.state.category.id, image, crop, imageType)
            .then(images => {
                if (this._isMounted) {
                    this.setState({
                        category: {
                            ...this.state.category,
                            isolatedImages:
                                imageType === imageTypes.Isolated
                                    ? images
                                    : this.state.category.isolatedImages,
                            isolatedImages128:
                                imageType === imageTypes.Isolated128
                                    ? images
                                    : this.state.category.isolatedImages128,
                        },
                    });
                }
            })
            .catch(err => logger.warn(err));
    };

    _onAddFeaturedProduct = featuredProductId => {
        return categories
            .featureProduct(this.state.category.id, featuredProductId)
            .then(resp => {
                if (this._isMounted) {
                    this.setState({
                        category: {
                            ...this.state.category,
                            featuredProducts: [...this.state.category.featuredProducts, resp],
                        },
                    });
                }
                return resp;
            })
            .catch(err => logger.warn(err));
    };

    _onRemoveFeaturedProduct = featuredProductId => {
        return categories
            .deleteFeaturedProduct(this.state.category.id, featuredProductId)
            .then(resp => {
                if (this._isMounted) {
                    this.setState({
                        category: {
                            ...this.state.category,
                            featuredProducts: this.state.category.featuredProducts.filter(
                                fp => fp.id !== featuredProductId,
                            ),
                        },
                    });
                }
                return resp;
            })
            .catch(err => logger.warn(err));
    };

    _reorderItem = (item, newIndex) => {
        categories
            .reorderItem(this.state.category.id, item.id, newIndex)
            .then(() => {
                if (this._isMounted) {
                    const newItems = this.state.category.items.filter(i => i.id !== item.id);
                    newItems.splice(newIndex, 0, item);
                    this.setState({
                        category: {
                            ...this.state.category,
                            items: newItems,
                        },
                    });
                }
            })
            .catch(e => {
                logger.warn(e);
            });
    };

    _reorderCombo = (combo, newIndex) => {
        categories
            .reorderCombo(this.state.category.id, combo.id, newIndex)
            .then(() => {
                if (this._isMounted) {
                    const newCombos = this.state.category.combos.filter(c => c.id !== combo.id);
                    newCombos.splice(newIndex, 0, combo);
                    this.setState({
                        category: {
                            ...this.state.category,
                            combos: newCombos,
                        },
                    });
                }
            })
            .catch(e => {
                logger.warn(e);
            });
    };

    _reorderFeaturedProduct = (featuredProduct, newIndex) => {
        categories
            .reorderFeaturedProduct(this.state.category.id, featuredProduct.id, newIndex)
            .then(() => {
                if (this._isMounted) {
                    const newFeatured = this.state.category.featuredProducts.filter(
                        f => f.id !== featuredProduct.id,
                    );
                    newFeatured.splice(newIndex, 0, featuredProduct);
                    this.setState({
                        category: {
                            ...this.state.category,
                            featuredProducts: newFeatured,
                        },
                    });
                }
            })
            .catch(e => {
                logger.warn(e);
            });
    };

    render() {
        if (this.state.loading) {
            return <Loader />;
        } else if (!this.state.category) {
            return <FailedToLoadAlert type={strings.category} />;
        }

        const category = this.state.category;
        return (
            <Grid>
                <Row bsClass="m-t">
                    <Col md={12}>
                        <h4>{category.name}</h4>
                    </Col>
                </Row>
                <Row bsClass="m-t">
                    <Col md={12}>
                        <div className={css(styles.tabWrapper)}>
                            <Tabs defaultActiveKey={1} id="uncontrolled-tab-example">
                                <Tab eventKey={1} title={strings.images}>
                                    <Col md={4}>
                                        <ImageSetUploader
                                            label={strings.isolatedImage}
                                            images={category ? category.isolatedImages : null}
                                            onSubmit={this._handleUploadImage.bind(
                                                this,
                                                imageTypes.Isolated,
                                            )}
                                            minWidth={80}
                                            minHeight={80}
                                        />
                                    </Col>
                                    <Col md={4}>
                                        <ImageSetUploader
                                            label={strings.isolatedImage128}
                                            images={category ? category.isolatedImages128 : null}
                                            onSubmit={this._handleUploadImage.bind(
                                                this,
                                                imageTypes.Isolated128,
                                            )}
                                            minWidth={128}
                                            minHeight={92}
                                        />
                                    </Col>
                                </Tab>
                                <Tab
                                    eventKey={2}
                                    title={
                                        <div>
                                            {strings.items} <Badge>{category.items.length}</Badge>
                                        </div>
                                    }
                                >
                                    <div className={css(styles.tabWrapper)}>
                                        <Table striped bordered hover>
                                            <thead>
                                                <tr>
                                                    <th>{strings.name}</th>
                                                    <th>{strings.active}</th>
                                                    <th>{strings.sort}</th>
                                                    <th>{strings.edit}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {category.items.map((item, i) => {
                                                    return (
                                                        <tr key={item.id}>
                                                            <td>{item.name}</td>
                                                            <td>
                                                                {item.status ===
                                                                menuStatus.available.value
                                                                    ? strings.yes
                                                                    : strings.no}
                                                            </td>
                                                            <td>
                                                                {
                                                                    <div>
                                                                        {i === 0 ? null : (
                                                                            <Button
                                                                                onClick={() =>
                                                                                    this._reorderItem(
                                                                                        item,
                                                                                        i - 1,
                                                                                    )
                                                                                }
                                                                            >
                                                                                &#9650;
                                                                            </Button>
                                                                        )}
                                                                        {i ===
                                                                        category.items.length -
                                                                            1 ? null : (
                                                                            <Button
                                                                                onClick={() =>
                                                                                    this._reorderItem(
                                                                                        item,
                                                                                        i + 1,
                                                                                    )
                                                                                }
                                                                            >
                                                                                &#9660;
                                                                            </Button>
                                                                        )}
                                                                    </div>
                                                                }
                                                            </td>
                                                            <td>
                                                                <LinkButton
                                                                    bsStyle="primary"
                                                                    to={`/item/${item.id}`}
                                                                >
                                                                    {strings.edit}
                                                                </LinkButton>
                                                            </td>
                                                        </tr>
                                                    );
                                                })}
                                            </tbody>
                                        </Table>
                                    </div>
                                </Tab>
                                <Tab
                                    eventKey={3}
                                    title={
                                        <div>
                                            {strings.combos} <Badge>{category.combos.length}</Badge>
                                        </div>
                                    }
                                >
                                    <div className={css(styles.tabWrapper)}>
                                        <Table striped bordered hover>
                                            <thead>
                                                <tr>
                                                    <th>{strings.name}</th>
                                                    <th>{strings.active}</th>
                                                    <th>{strings.sort}</th>
                                                    <th>{strings.edit}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {category.combos.map((c, i) => {
                                                    return (
                                                        <tr key={c.id}>
                                                            <td>{c.name}</td>
                                                            <td>
                                                                {c.status ===
                                                                menuStatus.available.value
                                                                    ? strings.yes
                                                                    : strings.no}
                                                            </td>
                                                            <td>
                                                                {
                                                                    <div>
                                                                        {i === 0 ? null : (
                                                                            <Button
                                                                                onClick={() =>
                                                                                    this._reorderCombo(
                                                                                        c,
                                                                                        i - 1,
                                                                                    )
                                                                                }
                                                                            >
                                                                                &#9650;
                                                                            </Button>
                                                                        )}
                                                                        {i ===
                                                                        category.combos.length -
                                                                            1 ? null : (
                                                                            <Button
                                                                                onClick={() =>
                                                                                    this._reorderCombo(
                                                                                        c,
                                                                                        i + 1,
                                                                                    )
                                                                                }
                                                                            >
                                                                                &#9660;
                                                                            </Button>
                                                                        )}
                                                                    </div>
                                                                }
                                                            </td>
                                                            <td>
                                                                <LinkButton
                                                                    bsStyle="primary"
                                                                    to={`/combo/${c.id}`}
                                                                >
                                                                    {strings.edit}
                                                                </LinkButton>
                                                            </td>
                                                        </tr>
                                                    );
                                                })}
                                            </tbody>
                                        </Table>
                                    </div>
                                </Tab>
                                <Tab eventKey={4} title={strings.featuredProducts}>
                                    <FeaturedProductTab
                                        onAdd={this._onAddFeaturedProduct}
                                        onRemove={this._onRemoveFeaturedProduct}
                                        onReorder={this._reorderFeaturedProduct}
                                        categoryId={category.id}
                                        featuredProducts={category.featuredProducts}
                                    />
                                </Tab>
                            </Tabs>
                        </div>
                    </Col>
                </Row>
            </Grid>
        );
    }
}
