import * as actionTypes from "../enums/actionTypes";
import * as storageKeys from "../enums/storageKeys";
import logger from "../lib/logger";
import * as account from "../lib/api/account";

export function signIn(accessToken, expireDate, refreshToken, scopes) {
    return {
        type: actionTypes.SESSION_SIGN_IN,
        accessToken,
        expireDate,
        refreshToken,
        scopes,
    };
}

export function signOut() {
    return {
        type: actionTypes.SESSION_SIGN_OUT,
    };
}

export function storeAndSignIn(authData) {
    return dispatch => {
        authData.expireDate = new Date(Date.now() + authData.expiresIn * 1000); // expires_in is in seconds
        localStorage.setItem(storageKeys.auth, JSON.stringify(authData));
        dispatch(
            signIn(
                authData.accessToken,
                authData.expireDate,
                authData.refreshToken,
                authData.scope,
            ),
        );
    };
}

export function loadSavedAuth() {
    return dispatch => {
        const data = localStorage.getItem(storageKeys.auth);
        if (!data) {
            dispatch(signOut());
            return Promise.resolve();
        }

        const authData = JSON.parse(data);
        dispatch(
            signIn(
                authData.accessToken,
                new Date(authData.expireDate),
                authData.refreshToken,
                authData.scope,
            ),
        );
        return Promise.resolve();
    };
}

export function makeSignOutRequest() {
    return (dispatch, getState) => {
        const state = getState();
        return account
            .logout(state.session.refreshToken)
            .then(() => {
                localStorage.clear();
                return dispatch(signOut());
            })
            .catch(err => {
                logger.warn(err);
                localStorage.clear();
                return dispatch(signOut());
            });
    };
}
