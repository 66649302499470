import instance from "./instance";
const responseData = response => response.data;

export function getCategories() {
    return instance
        .request({
            url: `/cms/categories`,
            method: "GET",
        })
        .then(responseData);
}

export function getCategory(categoryId) {
    return instance
        .request({
            url: `/cms/categories/${categoryId}`,
            method: "GET",
        })
        .then(responseData);
}

export function addImage(categoryId, image, crop, imageType) {
    const formData = new FormData();
    formData.append("image", image);
    formData.append("centerX", crop.x);
    formData.append("centerY", crop.y);
    formData.append("width", crop.width);
    formData.append("height", crop.height);
    return instance
        .request({
            url: `/cms/categories/${categoryId}/image/${imageType}`,
            method: "POST",
            data: formData,
        })
        .then(responseData);
}

export function reorder(categoryId, position) {
    return instance
        .request({
            url: `/cms/categories/${categoryId}/reorder`,
            method: "POST",
            data: {
                position,
            },
        })
        .then(responseData);
}

export function reorderItem(categoryId, itemId, position) {
    return instance
        .request({
            url: `/cms/categories/${categoryId}/item/${itemId}/reorder`,
            method: "POST",
            data: {
                position,
            },
        })
        .then(responseData);
}

export function reorderCombo(categoryId, comboId, position) {
    return instance
        .request({
            url: `/cms/categories/${categoryId}/combo/${comboId}/reorder`,
            method: "POST",
            data: {
                position,
            },
        })
        .then(responseData);
}

export function featureProduct(categoryId, featuredProductId) {
    return instance
        .request({
            url: `/cms/categories/${categoryId}/featured`,
            method: "POST",
            data: {
                featuredProductId,
            },
        })
        .then(responseData);
}

export function deleteFeaturedProduct(categoryId, featuredProductId) {
    return instance
        .request({
            url: `/cms/categories/${categoryId}/featured/${featuredProductId}`,
            method: "DELETE",
        })
        .then(responseData);
}

export function reorderFeaturedProduct(categoryId, featuredProductId, position) {
    return instance
        .request({
            url: `/cms/categories/${categoryId}/featured/${featuredProductId}/reorder`,
            method: "POST",
            data: {
                position,
            },
        })
        .then(responseData);
}
