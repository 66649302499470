import { Pagination } from "react-bootstrap";
import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { css, StyleSheet } from "aphrodite";

const styles = StyleSheet.create({
    pagination: {
        margin: 0,
    },
});

export default class PageButtons extends PureComponent {
    static propTypes = {
        numPages: PropTypes.number.isRequired,
        currentPage: PropTypes.number.isRequired,
        handlePageChange: PropTypes.func.isRequired,
    };

    _renderPageButtons = () => {
        const startPage = Math.max(
            1,
            Math.min(this.props.currentPage - 2, Math.ceil(this.props.numPages) - 4),
        );
        const buttons = [];
        for (let i = startPage; i <= Math.ceil(this.props.numPages) && i < startPage + 5; i++) {
            buttons.push(
                <Pagination.Item
                    key={i}
                    active={this.props.currentPage === i ? true : false}
                    onClick={() => this.props.handlePageChange(i)}
                >
                    {i}
                </Pagination.Item>,
            );
        }
        return buttons;
    };

    _onFirst = () => this.props.handlePageChange(1);
    _onPrev = () => this.props.handlePageChange(this.props.currentPage - 1);
    _onLast = () => this.props.handlePageChange(Math.ceil(this.props.numPages));
    _onNext = () => this.props.handlePageChange(this.props.currentPage + 1);

    render() {
        if (this.props.numPages <= 1) {
            return null;
        }
        return (
            <Pagination className={css(styles.pagination)}>
                {this.props.currentPage !== 1 ? <Pagination.First onClick={this._onFirst} /> : null}
                {this.props.currentPage !== 1 ? <Pagination.Prev onClick={this._onPrev} /> : null}
                {this.props.currentPage > 3 ? (
                    <Pagination.Item onClick={this._onFirst}>{1}</Pagination.Item>
                ) : null}
                {this.props.currentPage > 4 ? <Pagination.Ellipsis /> : null}

                {this._renderPageButtons()}

                {this.props.currentPage < this.props.numPages - 3 ? <Pagination.Ellipsis /> : null}
                {this.props.currentPage < this.props.numPages - 2 ? (
                    <Pagination.Item onClick={this._onLast}>
                        {Math.ceil(this.props.numPages)}
                    </Pagination.Item>
                ) : null}
                {this.props.currentPage !== this.props.numPages ? (
                    <Pagination.Next onClick={this._onNext} />
                ) : null}
                {this.props.currentPage !== this.props.numPages ? (
                    <Pagination.Last onClick={this._onLast} />
                ) : null}
            </Pagination>
        );
    }
}
