import {
    Button,
    Grid,
    Row,
    Col,
    Tabs,
    Tab,
    Table,
    FormGroup,
    FormControl,
    ControlLabel,
} from "react-bootstrap";
import AlertFlag from "../AlertFlag";
import FailedToLoadAlert from "../FailedToLoadAlert";
import ImageSetUploader from "../ImageSetUploader";
import ItemInfoTab from "../ItemInfoTab";
import LinkButton from "../LinkButton";
import Loader from "../Loader";
import React, { PureComponent } from "react";
import { StyleSheet, css } from "aphrodite";
import * as items from "../../lib/api/items";
import * as imageTypes from "../../enums/imageType";
import * as modifierTypes from "../../enums/modifierTypes";
import * as menuStatus from "../../enums/menuStatus";
import PropTypes from "prop-types";
import logger from "../../lib/logger";

const styles = StyleSheet.create({
    tabWrapper: {
        marginTop: 20,
    },
});

const strings = {
    loading: "Loading",
    titleSuffix: "| Habit CMS",
    item: "Item",
    info: "Info",
    images: "Images",
    heroImage: "Hero Image",
    heroImage1080: "Kiosk Hero Image",
    isolatedImage: "Isolated Image",
    isolatedImage328: "Kiosk Small Isolated Image",
    isolatedImage424: "Kiosk Large Isolated Image",
    isolatedImage128: "Rebrand Isolated Image 128",
    isolatedImage352: "Rebrand Isolated Image 352",
    isolatedImage1152: "Rebrand Isolated Image 1152",
    isolatedImage1036: "Web Extra Large Isolated Image",
    modifiers: "Modifiers",
    edit: "Edit",
    missingIsolatedImages: "Missing Isolated Image",
    missingHeroImages: "Missing Hero Image",
    name: "Name",
    available: "Available",
    sort: "Sort",
    manageSelections: "Manage Selections",
    type: "Type",
    disable: "Disable",
    enable: "Enable",
    yes: "Yes",
    no: "No",
};

export default class ItemPage extends PureComponent {
    static propTypes = {
        match: PropTypes.object.isRequired,
    };

    state = {
        item: null,
        loading: true,
        currentModifierType: modifierTypes.ingredient.value,
    };

    componentDidMount() {
        this._isMounted = true;
        document.title = `${strings.loading} ${strings.titleSuffix}`;
        items
            .getItem(this.props.match.params.itemId)
            .then(resp => {
                if (this._isMounted) {
                    this.setState({ item: resp, loading: false });
                    document.title = `${resp.name} ${strings.titleSuffix}`;
                }
            })
            .catch(err => {
                logger.warn(err);
                if (this._isMounted) {
                    this.setState({ loading: false });
                }
            });
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    _handleUploadImage = (imageType, image, crop) => {
        return items
            .addImage(this.state.item.id, imageType, image, crop)
            .then(images => {
                if (this._isMounted) {
                    this.setState({
                        item: {
                            ...this.state.item,
                            isolatedImages:
                                imageType === imageTypes.Isolated
                                    ? images
                                    : this.state.item.isolatedImages,
                            isolatedImages328:
                                imageType === imageTypes.Isolated328
                                    ? images
                                    : this.state.item.isolatedImages328,
                            isolatedImages424:
                                imageType === imageTypes.Isolated424
                                    ? images
                                    : this.state.item.isolatedImages424,
                            isolatedImages1036:
                                imageType === imageTypes.Isolated1036
                                    ? images
                                    : this.state.item.isolatedImages1036,
                            isolatedImages128:
                                imageType === imageTypes.Isolated128 
                                    ? images 
                                    : this.state.item.isolatedImages128,
                            isolatedImages352:
                                imageType === imageTypes.Isolated352
                                    ? images 
                                    : this.state.item.isolatedImages352,
                            isolatedImages1152:
                                imageType === imageTypes.Isolated1152 
                                        ? images 
                                        : this.state.item.isolatedImages1152,
                            heroImages:
                                imageType === imageTypes.Hero ? images : this.state.item.heroImages,
                            heroImages1080:
                                imageType === imageTypes.Hero1080
                                    ? images
                                    : this.state.item.heroImages1080,
                        },
                    });
                }
                return images;
            })
            .catch(err => logger.warn(err));
    };

    _handleModifierTypeChange = e => {
        this.setState({
            currentModifierType: e.target.value,
        });
    };

    _handleSaveInfo = item => {
        if (this._isMounted) {
            this.setState({ item });
        }
    };

    _toggleModifierStatus = modifier => {
        const newStatus =
            modifier.status === menuStatus.available.value
                ? menuStatus.unavailable.value
                : menuStatus.available.value;
        items
            .updateModifier(this.state.item.id, modifier.modifierId, newStatus)
            .then(() => {
                if (this._isMounted) {
                    this.setState({
                        item: {
                            ...this.state.item,
                            modifiers: this.state.item.modifiers.map(m => {
                                if (m.modifierId === modifier.modifierId) {
                                    return {
                                        ...m,
                                        status: newStatus,
                                    };
                                }
                                return m;
                            }),
                        },
                    });
                }
            })
            .catch(err => logger.warn(err));
    };

    _reorderModifier = (modifier, newIndex) => {
        items
            .reorderModifier(this.state.item.id, modifier.modifierId, newIndex)
            .then(() => {
                if (this._isMounted) {
                    const currentModifiers = this.state.item.modifiers.filter(
                        m => m.type === modifier.type && m.modifierId !== modifier.modifierId,
                    );
                    let newModifiers = this.state.item.modifiers.filter(
                        m => m.type !== modifier.type,
                    );
                    currentModifiers.splice(newIndex, 0, modifier);
                    newModifiers = newModifiers.concat(currentModifiers);
                    this.setState({
                        item: {
                            ...this.state.item,
                            modifiers: newModifiers,
                        },
                    });
                }
            })
            .catch(e => {
                logger.warn(e);
            });
    };

    render() {
        if (this.state.loading) {
            return <Loader />;
        } else if (!this.state.item) {
            return <FailedToLoadAlert type={strings.item} />;
        }
        const item = this.state.item;
        const currentModifiers = item.modifiers.filter(
            m => m.type === this.state.currentModifierType,
        );
        return (
            <Grid>
                <Row bsClass="m-t">
                    <Col md={6}>
                        <h4>{item.name}</h4>
                    </Col>
                    <Col md={6}>
                        {!this.state.item.isolatedImages ||
                        !this.state.item.isolatedImages.length ? (
                            <AlertFlag label={strings.missingIsolatedImages} />
                        ) : null}
                        {!this.state.item.heroImages || !this.state.item.heroImages.length ? (
                            <AlertFlag label={strings.missingHeroImages} />
                        ) : null}
                    </Col>
                </Row>
                <Row bsClass="m-t">
                    <Col md={12}>
                        <div className={css(styles.tabWrapper)}>
                            <Tabs defaultActiveKey={1} id="uncontrolled-tab-example">
                                <Tab eventKey={1} title={strings.info}>
                                    <ItemInfoTab
                                        key={item.id}
                                        initialItem={item}
                                        onSave={this._handleSaveInfo}
                                    />
                                </Tab>
                                <Tab eventKey={2} title={strings.images}>
                                    <Row>
                                        <Col md={4}>
                                            <ImageSetUploader
                                                label={strings.isolatedImage}
                                                images={item ? item.isolatedImages : null}
                                                onSubmit={this._handleUploadImage.bind(
                                                    this,
                                                    imageTypes.Isolated,
                                                )}
                                                minWidth={80}
                                                minHeight={80}
                                            />
                                        </Col>
                                        <Col md={4}>
                                            <ImageSetUploader
                                                label={strings.isolatedImage328}
                                                images={item ? item.isolatedImages328 : null}
                                                onSubmit={this._handleUploadImage.bind(
                                                    this,
                                                    imageTypes.Isolated328,
                                                )}
                                                minWidth={328}
                                                minHeight={265}
                                            />
                                        </Col>
                                        <Col md={4}>
                                            <ImageSetUploader
                                                label={strings.isolatedImage424}
                                                images={item ? item.isolatedImages424 : null}
                                                onSubmit={this._handleUploadImage.bind(
                                                    this,
                                                    imageTypes.Isolated424,
                                                )}
                                                minWidth={424}
                                                minHeight={392}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={4}>
                                            <ImageSetUploader
                                                label={strings.heroImage}
                                                images={item ? item.heroImages : null}
                                                onSubmit={this._handleUploadImage.bind(
                                                    this,
                                                    imageTypes.Hero,
                                                )}
                                                minWidth={414}
                                                minHeight={350}
                                            />
                                        </Col>
                                        <Col md={4}>
                                            <ImageSetUploader
                                                label={strings.heroImage1080}
                                                images={item ? item.heroImages1080 : null}
                                                onSubmit={this._handleUploadImage.bind(
                                                    this,
                                                    imageTypes.Hero1080,
                                                )}
                                                minWidth={1080}
                                                minHeight={913}
                                            />
                                        </Col>
                                        <Col md={4}>
                                            <ImageSetUploader
                                                label={strings.isolatedImage1036}
                                                images={item ? item.isolatedImages1036 : null}
                                                onSubmit={this._handleUploadImage.bind(
                                                    this,
                                                    imageTypes.Isolated1036,
                                                )}
                                                minWidth={1036}
                                                minHeight={958}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={4}>
                                            <ImageSetUploader
                                                label={strings.isolatedImage128}
                                                images={item ? item.isolatedImages128 : null}
                                                onSubmit={this._handleUploadImage.bind(
                                                    this,
                                                    imageTypes.Isolated128,
                                                )}
                                                minWidth={128}
                                                minHeight={92}
                                            />
                                        </Col>
                                        <Col md={4}>
                                            <ImageSetUploader
                                                label={strings.isolatedImage352}
                                                images={item ? item.isolatedImages352 : null}
                                                onSubmit={this._handleUploadImage.bind(
                                                    this,
                                                    imageTypes.Isolated352,
                                                )}
                                                minWidth={352}
                                                minHeight={253}
                                            />
                                        </Col>
                                        <Col md={4}>
                                            <ImageSetUploader
                                                label={strings.isolatedImage1152}
                                                images={item ? item.isolatedImages1152 : null}
                                                onSubmit={this._handleUploadImage.bind(
                                                    this,
                                                    imageTypes.Isolated1152,
                                                )}
                                                minWidth={1152}
                                                minHeight={828}
                                            />
                                        </Col>
                                    </Row>
                                </Tab>
                                <Tab eventKey={3} title={strings.modifiers}>
                                    <Col md={3}>
                                        <FormGroup controlId="formControlsSelect">
                                            <ControlLabel>{strings.type}</ControlLabel>
                                            <FormControl
                                                componentClass="select"
                                                value={this.state.currentModifierType}
                                                onChange={this._handleModifierTypeChange}
                                            >
                                                <option value={modifierTypes.required.value}>
                                                    {modifierTypes.required.label}
                                                </option>
                                                <option value={modifierTypes.ingredient.value}>
                                                    {modifierTypes.ingredient.label}
                                                </option>
                                                <option value={modifierTypes.addon.value}>
                                                    {modifierTypes.addon.label}
                                                </option>
                                            </FormControl>
                                        </FormGroup>
                                    </Col>
                                    <Table striped bordered hover>
                                        <thead>
                                            <tr>
                                                <th>{strings.name}</th>
                                                <th>{strings.available}</th>
                                                <th />
                                                <th>{strings.sort}</th>
                                                <th>{strings.manageSelections}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {currentModifiers.map((m, i) => (
                                                <tr key={m.modifierId}>
                                                    <td>{m.name}</td>
                                                    <td>
                                                        {`${
                                                            m.status === menuStatus.available.value
                                                                ? strings.yes
                                                                : strings.no
                                                        } `}
                                                    </td>
                                                    <td>
                                                        <Button
                                                            bsStyle={
                                                                m.status ===
                                                                menuStatus.available.value
                                                                    ? "danger"
                                                                    : "primary"
                                                            }
                                                            onClick={() =>
                                                                this._toggleModifierStatus(m)
                                                            }
                                                        >
                                                            {m.status === menuStatus.available.value
                                                                ? strings.disable
                                                                : strings.enable}
                                                        </Button>
                                                    </td>
                                                    <td>
                                                        {
                                                            <div>
                                                                {i === 0 ? null : (
                                                                    <Button
                                                                        onClick={() =>
                                                                            this._reorderModifier(
                                                                                m,
                                                                                i - 1,
                                                                            )
                                                                        }
                                                                    >
                                                                        &#9650;
                                                                    </Button>
                                                                )}
                                                                {i ===
                                                                currentModifiers.length -
                                                                    1 ? null : (
                                                                    <Button
                                                                        onClick={() =>
                                                                            this._reorderModifier(
                                                                                m,
                                                                                i + 1,
                                                                            )
                                                                        }
                                                                    >
                                                                        &#9660;
                                                                    </Button>
                                                                )}
                                                            </div>
                                                        }
                                                    </td>
                                                    <td>
                                                        <LinkButton
                                                            bsStyle="primary"
                                                            to={`/item/${item.id}/modifier/${m.modifierId}`}
                                                        >
                                                            {strings.edit}
                                                        </LinkButton>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                </Tab>
                            </Tabs>
                        </div>
                    </Col>
                </Row>
            </Grid>
        );
    }
}
