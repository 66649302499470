export const mobile = {
    label: "Mobile",
    value: "mobile",
};
export const mobileIos = {
    label: "Mobile - IOS",
    value: "mobile_ios",
};
export const mobileAndroid = {
    label: "Mobile - Android",
    value: "mobile_android",
};
export const kiosk = {
    label: "Kiosk",
    value: "kiosk",
};
export const web = {
    label: "Web - Desktop",
    value: "web",
};
export const webMobile = {
    label: "Web - Mobile",
    value: "web_mobile",
};
export const webAll = {
    label: "Web - All",
    value: "web_all",
};
export const all = {
    label: "All",
    value: "all",
};
