import { Button, Grid, Row, Col, Tabs, Tab, Table } from "react-bootstrap";
import FailedToLoadAlert from "../FailedToLoadAlert";
import Loader from "../Loader";
import React, { PureComponent } from "react";
import { StyleSheet, css } from "aphrodite";
import * as items from "../../lib/api/items";
import * as menuStatus from "../../enums/menuStatus";
import PropTypes from "prop-types";
import logger from "../../lib/logger";

const styles = StyleSheet.create({
    tabWrapper: {
        marginTop: 20,
    },
});

const strings = {
    loading: "Loading",
    titleSuffix: "| Habit CMS",
    selections: "Selections",
    name: "Name",
    default: "Default?",
    active: "Active",
    sort: "Sort",
    modfiier: "Modifier",
    disable: "Disable",
    enable: "Enable",
    yes: "Yes",
    no: "No",
};

export default class ItemModifierPage extends PureComponent {
    static propTypes = {
        match: PropTypes.object.isRequired,
    };

    state = {
        modifier: null,
        loading: true,
    };

    componentDidMount() {
        this._isMounted = true;
        document.title = `${strings.loading} ${strings.titleSuffix}`;
        items
            .getItemModifier(this.props.match.params.itemId, this.props.match.params.modifierId)
            .then(resp => {
                if (this._isMounted) {
                    this.setState({ modifier: resp, loading: false });
                    document.title = `${resp.name} ${strings.titleSuffix}`;
                }
            })
            .catch(err => {
                logger.warn(err);
                if (this._isMounted) {
                    this.setState({ loading: false });
                }
            });
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    _toggleSelectionStatus = selection => {
        const newStatus =
            menuStatus.available.value === selection.status
                ? menuStatus.unavailable.value
                : menuStatus.available.value;
        items
            .updateSelection(this.state.modifier.itemId, selection.modifierSelectionId, newStatus)
            .then(() => {
                if (this._isMounted) {
                    this.setState({
                        modifier: {
                            ...this.state.modifier,
                            selections: this.state.modifier.selections.map(s => {
                                if (s.modifierSelectionId === selection.modifierSelectionId) {
                                    return {
                                        ...s,
                                        status: newStatus,
                                    };
                                }
                                return s;
                            }),
                        },
                    });
                }
            })
            .catch(err => logger.warn(err));
    };

    _reorderSelection = (selection, newIndex) => {
        items
            .reorderSelection(this.state.modifier.itemId, selection.modifierSelectionId, newIndex)
            .then(() => {
                if (this._isMounted) {
                    const newSelections = this.state.modifier.selections.filter(
                        s => s.modifierSelectionId !== selection.modifierSelectionId,
                    );
                    newSelections.splice(newIndex, 0, selection);
                    this.setState({
                        modifier: {
                            ...this.state.modifier,
                            selections: newSelections,
                        },
                    });
                }
            })
            .catch(e => {
                logger.warn(e);
            });
    };

    render() {
        if (this.state.loading) {
            return <Loader />;
        } else if (!this.state.modifier) {
            return <FailedToLoadAlert type={strings.modifier} />;
        }
        const modifier = this.state.modifier;
        return (
            <Grid>
                <Row bsClass="m-t">
                    <Col md={6}>
                        <h4>{`${modifier.itemName} - ${modifier.name}`}</h4>
                    </Col>
                </Row>
                <Row bsClass="m-t">
                    <Col md={12}>
                        <div className={css(styles.tabWrapper)}>
                            <Tabs defaultActiveKey={1} id="uncontrolled-tab-example">
                                <Tab eventKey={1} title={strings.selections}>
                                    <Table striped bordered hover>
                                        <thead>
                                            <tr>
                                                <th>{strings.name}</th>
                                                <th>{strings.active}</th>
                                                <th>{strings.sort}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {modifier.selections.map((s, i) => (
                                                <tr key={s.modifierId}>
                                                    <td>{s.name}</td>
                                                    <td>
                                                        {`${
                                                            s.status === menuStatus.available.value
                                                                ? strings.yes
                                                                : strings.no
                                                        } `}
                                                        <Button
                                                            bsStyle="primary"
                                                            onClick={() =>
                                                                this._toggleSelectionStatus(s)
                                                            }
                                                        >
                                                            {s.status === menuStatus.available.value
                                                                ? strings.disable
                                                                : strings.enable}
                                                        </Button>
                                                    </td>
                                                    <td>
                                                        {
                                                            <div>
                                                                {i === 0 ? null : (
                                                                    <Button
                                                                        onClick={() =>
                                                                            this._reorderSelection(
                                                                                s,
                                                                                i - 1,
                                                                            )
                                                                        }
                                                                    >
                                                                        &#9650;
                                                                    </Button>
                                                                )}
                                                                {i ===
                                                                modifier.selections.length -
                                                                    1 ? null : (
                                                                    <Button
                                                                        onClick={() =>
                                                                            this._reorderSelection(
                                                                                s,
                                                                                i + 1,
                                                                            )
                                                                        }
                                                                    >
                                                                        &#9660;
                                                                    </Button>
                                                                )}
                                                            </div>
                                                        }
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                </Tab>
                            </Tabs>
                        </div>
                    </Col>
                </Row>
            </Grid>
        );
    }
}
