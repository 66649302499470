import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import React, { PureComponent } from "react";
import PropTypes from "prop-types";

export default class LinkButton extends PureComponent {
    static propTypes = {
        to: PropTypes.string.isRequired,
        children: PropTypes.node,
    };

    render() {
        const { to, children, ...otherProps } = this.props;
        return (
            <Link to={to}>
                <Button {...otherProps}>{children}</Button>
            </Link>
        );
    }
}
