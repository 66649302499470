/* eslint-disable react/jsx-no-bind */

import * as registrationPrompts from "../../lib/api/registrationPrompts";
import FailedToLoadAlert from "../FailedToLoadAlert";
import logger from "../../lib/logger";
import Loader from "../Loader";
import PropTypes from "prop-types";
import LandingPageTable from "../LandingPageTable";
import React, { PureComponent } from "react";
import { Grid, Row, Col } from "react-bootstrap";
import LandingPageHeader from "../LandingPageHeader";

const strings = {
    documentTitle: "Registration Prompt Pages | Habit CMS",
    pageTitle: "Registration Prompt Pages",
    filterByName: "Filter by Name:",
    title: "Title",
    pageUrl: "registration-prompt",
    editPageUrl: "/registration-prompt",
    newSlug: "new",
    registrationPromptsTable: "Registration Prompts Table",
};

class RegistrationPromptsPage extends PureComponent {
    static propTypes = {
        history: PropTypes.object.isRequired,
    };

    state = {
        pages: [],
        showInactivePages: false,
        filter: "",
        isLoading: true,
        isError: false,
        inUseNames: new Set(),
        inUseSlugs: new Set(),
    };

    componentDidMount() {
        this._isMounted = true;
        document.title = strings.documentTitle;
        registrationPrompts
            .getRegistrationPrompts()
            .then(resp => {
                if (this._isMounted) {
                    this.setState({
                        pages: resp.registrationPromptPages,
                        inUseNames: new Set(resp.registrationPromptPages.map(c => c.name)),
                        inUseSlugs: new Set(resp.registrationPromptPages.map(c => c.promoSlug)),
                    });
                }
            })
            .catch(e => {
                logger.warn(e);
                if (this._isMounted) {
                    this.setState({ isError: true });
                }
            })
            .finally(() => {
                if (this._isMounted) {
                    this.setState({ isLoading: false });
                }
            });
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    render() {
        const loweredFilter = this.state.filter.toLowerCase().trim();
        const visiblePages = this.state.pages.filter(c => {
            if (!c.isActive && !this.state.showInactivePages) {
                return false;
            }

            return c.name.toLowerCase().indexOf(loweredFilter) !== -1;
        });

        return (
            <Grid>
                <LandingPageHeader
                    title={strings.pageTitle}
                    showInactivePages={this.state.showInactivePages}
                    onChangeShowInactivePages={() =>
                        this.setState({ showInactivePages: !this.state.showInactivePages })
                    }
                    filterLabel={strings.filterByName}
                    filter={this.state.filter}
                    onChangeFilter={e => this.setState({ filter: e.target.value })}
                    onAddPage={() =>
                        this.props.history.push(`${strings.editPageUrl}/${strings.newSlug}`, {
                            inUseNames: this.state.inUseNames,
                            inUseSlugs: this.state.inUseSlugs,
                        })
                    }
                />
                <Row>
                    <Col md={12}>
                        <LandingPageTable
                            history={this.props.history}
                            items={visiblePages}
                            itemToSlug={pageData => pageData.promoSlug}
                            extraColumns={[
                                {
                                    headerText: strings.title,
                                    mapItemToCell: pageData => (
                                        <td key={`${strings.title}-${pageData.id}`}>
                                            {pageData.name}
                                        </td>
                                    ),
                                },
                            ]}
                            landingPageUrl={strings.pageUrl}
                            editBaseUrl={strings.editPageUrl}
                            mapItemToEditStatePayload={pageData => ({
                                pageData,
                                inUseNames: this.state.inUseNames,
                                inUseSlugs: this.state.inUseSlugs,
                            })}
                        />
                        {this.state.isLoading ? <Loader /> : null}
                    </Col>
                </Row>
                {this.state.isError ? (
                    <Row>
                        <FailedToLoadAlert type={strings.registrationPromptsTable} />
                    </Row>
                ) : null}
            </Grid>
        );
    }
}

export default RegistrationPromptsPage;
