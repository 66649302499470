import {
    Modal,
    Grid,
    Row,
    Col,
    Button,
    FormGroup,
    ControlLabel,
    FormControl,
    HelpBlock,
} from "react-bootstrap";
import ReactCrop, { makeAspectCrop } from "react-image-crop"; // eslint-disable-line import/default,import/named
import "react-image-crop/dist/ReactCrop.css";
import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import Loader from "./Loader";
import { StyleSheet, css } from "aphrodite/no-important";
import * as habitPropTypes from "../habitPropTypes";
const styles = StyleSheet.create({
    container: {
        marginTop: 20,
    },
    image: {
        maxHeight: 200,
    },
    fileInput: {
        paddingTop: 10,
        paddingBottom: 10,
        cursor: "pointer",
    },
});

const strings = {
    cropTitle: "Crop the Image",
    image: "Image",
    submit: "Submit",
    cancel: "Cancel",
    minSize: "Upload an image that is at least ",
    minSizeError: "Image must be at least ",
};

export default class ImageSetUploader extends PureComponent {
    static propTypes = {
        label: PropTypes.string,
        onSubmit: PropTypes.func.isRequired,
        images: PropTypes.arrayOf(habitPropTypes.image),
        previewImage: PropTypes.string,
        minWidth: PropTypes.number.isRequired,
        minHeight: PropTypes.number.isRequired,
        overrideMinAspectRatio: PropTypes.bool, // without this, the image is forced to be cropped to respect the aspect ratio determined by the minWidth and minHeight props.
    };

    state = {
        loading: false,
        error: "",
        showCropperModal: false,
        image: null,
        imageData: null,
        crop: null,
        pixelCrop: null,
        percentageMinWidth: null,
        percentageMinHeight: null,
    };

    componentDidMount = () => {
        this._isMounted = true;
    };

    componentWillUnmount = () => {
        this._isMounted = false;
    };

    componentDidUpdate = prevProps => {
        if (this.props.previewImage && this.props.previewImage !== prevProps.previewImage) {
            const ctx = this._previewCanvas.getContext("2d");
            const image = new Image();
            this._previewCanvas.width = (200 * this.props.minWidth) / this.props.minHeight;
            this._previewCanvas.height = 200;
            image.onload = () => {
                ctx.drawImage(
                    image,
                    this.state.pixelCrop.x,
                    this.state.pixelCrop.y,
                    this.state.pixelCrop.width,
                    this.state.pixelCrop.height,
                    0,
                    0,
                    (200 * this.props.minWidth) / this.props.minHeight,
                    200,
                );
            };
            image.src = this.props.previewImage;
        }
    };

    _onUpload = e => {
        const image = e.target.files[0];
        e.target.value = null;
        const reader = new FileReader();
        reader.onload = event => {
            if (this._isMounted) {
                this.setState({
                    image: image,
                    imageData: event.target.result,
                });
            }
        };
        if (image) {
            reader.readAsDataURL(image);
        }
    };

    _onChangeCrop = (crop, pixelCrop) => {
        if (crop.width === 0 || crop.height === 0) {
            return;
        }
        this.setState({ crop, pixelCrop });
    };

    _onSubmit = () => {
        this.setState({
            loading: true,
        });
        const promise = this.props.onSubmit(
            this.state.image,
            this.state.pixelCrop,
            this.state.imageData,
        );
        if (promise) {
            promise.then(() => {
                if (this._isMounted) {
                    this.setState({
                        loading: false,
                        image: null,
                        imageData: null,
                    });
                }
            });
        } else {
            if (this._isMounted) {
                this.setState({
                    loading: false,
                    image: null,
                    imageData: null,
                });
            }
        }
    };

    _onCropperLoadedImage = image => {
        const percentageMinWidth = (this.props.minWidth / image.naturalWidth) * 100;
        const percentageMinHeight = (this.props.minHeight / image.naturalHeight) * 100;
        if (percentageMinWidth > 100 || percentageMinHeight > 100) {
            this.setState({
                error: `${strings.minSizeError} ${this.props.minWidth}x${this.props.minHeight}`,
                image: null,
                imageData: null,
            });
            return;
        }
        let aspectRatio = 0;
        let pixelWidth = 0;
        let pixelHeight = 0;
        if (this.props.overrideMinAspectRatio) {
            aspectRatio = image.naturalWidth / image.naturalHeight;
            pixelWidth = image.naturalWidth;
            pixelHeight = image.naturalHeight;
        } else {
            aspectRatio = this.props.minWidth / this.props.minHeight;
            const imageAspectRatio = image.naturalWidth / image.naturalHeight;
            if (aspectRatio < imageAspectRatio) {
                pixelHeight = image.naturalHeight;
                pixelWidth = image.naturalHeight * aspectRatio;
            } else if (aspectRatio > imageAspectRatio) {
                pixelWidth = image.naturalWidth;
                pixelHeight = image.naturalWidth / aspectRatio;
            } else {
                pixelWidth = image.naturalWidth;
                pixelHeight = image.naturalHeight;
            }
        }

        this.setState({
            error: "",
            crop: makeAspectCrop(
                {
                    x: 0,
                    y: 0,
                    aspect: aspectRatio,
                    width: pixelWidth,
                },
                image.naturalWidth / image.naturalHeight,
            ),
            pixelCrop: {
                x: 0,
                y: 0,
                width: Math.round(pixelWidth),
                height: Math.round(pixelHeight),
            },
            percentageMinWidth,
            percentageMinHeight,
        });
    };

    _onCancel = () => {
        this.setState({
            image: null,
            imageData: null,
        });
    };

    _setCanvasRef = c => {
        this._previewCanvas = c;
    };

    render() {
        return (
            <div className={css(styles.container)}>
                <Grid>
                    <Row>
                        <Col md={12}>
                            <h4>{this.props.label ? this.props.label : strings.image}</h4>
                            <FormGroup validationState={this.state.error ? "error" : undefined}>
                                {this.props.images &&
                                this.props.images.length &&
                                !this.state.image ? (
                                    <div>
                                        <img
                                            className={css(styles.image)}
                                            style={{ height: this.props.minHeight }}
                                            src={this.props.images[0].url}
                                            srcSet={this.props.images
                                                .slice(1)
                                                .map(i => `${i.url} ${i.scale}x`)}
                                            alt="current"
                                        />
                                    </div>
                                ) : null}
                                {this.props.previewImage ? (
                                    <div>
                                        <canvas ref={this._setCanvasRef} />
                                    </div>
                                ) : null}
                                {this.state.error ? (
                                    <ControlLabel>{this.state.error}</ControlLabel>
                                ) : null}
                                <FormControl
                                    className={css(styles.fileInput)}
                                    type="file"
                                    onChange={this._onUpload}
                                />
                                <HelpBlock>{`${strings.minSize} ${this.props.minWidth}x${this.props.minHeight}`}</HelpBlock>
                            </FormGroup>
                            {this.state.image ? (
                                <Modal
                                    show={!!this.state.image}
                                    onHide={this._onCancel}
                                    backdrop="static"
                                >
                                    <Modal.Header closeButton>
                                        <Modal.Title>{strings.cropTitle}</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        {this.state.loading ? (
                                            <Loader />
                                        ) : (
                                            <ReactCrop
                                                src={this.state.imageData}
                                                crop={this.state.crop}
                                                onChange={this._onChangeCrop}
                                                onImageLoaded={this._onCropperLoadedImage}
                                                minWidth={this.state.percentageMinWidth}
                                                minHeight={this.state.percentageMinHeight}
                                            />
                                        )}
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <Button onClick={this._onCancel}>{strings.cancel}</Button>
                                        <Button onClick={this._onSubmit} bsStyle="primary">
                                            {strings.submit}
                                        </Button>
                                    </Modal.Footer>
                                </Modal>
                            ) : null}
                        </Col>
                    </Row>
                </Grid>
            </div>
        );
    }
}
