import instance from "./instance";
const responseData = response => response.data;

export function sendGlobal(title, message) {
    return instance
        .request({
            url: `/cms/notifications/global`,
            method: "POST",
            data: {
                title,
                message,
            },
        })
        .then(responseData);
}
