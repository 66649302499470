import { Button } from "react-bootstrap";
import React, { PureComponent } from "react";
import * as menuStatus from "../enums/menuStatus";
import PropTypes from "prop-types";

const strings = {
    edit: "Edit",
    yes: "Yes",
    no: "No",
};

export default class StoreProduct extends PureComponent {
    static propTypes = {
        product: PropTypes.shape({
            globalStatus: PropTypes.string.isRequired,
            id: PropTypes.string.isRequired,
            name: PropTypes.string.isRequired,
            status: PropTypes.string.isRequired,
            statusReason: PropTypes.string,
        }).isRequired,
        onEdit: PropTypes.func.isRequired,
    };

    state = {
        filter: "",
    };

    _onEdit = () => this.props.onEdit(this.props.product);

    render() {
        const { product } = this.props;
        return (
            <tr>
                <td>{product.name}</td>
                <td>
                    {product.globalStatus === menuStatus.available.value ? strings.yes : strings.no}
                </td>
                <td>{product.status === menuStatus.available.value ? strings.no : strings.yes}</td>
                <td>{product.status === menuStatus.available.value ? "" : product.statusReason}</td>
                <td>
                    <Button bsStyle="primary" onClick={this._onEdit}>
                        {strings.edit}
                    </Button>
                </td>
            </tr>
        );
    }
}
