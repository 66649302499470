import React, { PureComponent } from "react";
import { StyleSheet, css } from "aphrodite/no-important";

const translateKeyframes = {
    "0%": {
        transform: "rotate(0deg)",
    },
    "100%": {
        transform: "rotate(360deg)",
    },
};

const styles = StyleSheet.create({
    loader: {
        borderRadius: "50%",
        width: "3.5em",
        height: "3.5em",
        margin: "60px auto",
        fontSize: 10,
        position: "relative",
        textIndent: "-9999em",
        borderTop: "0.5em solid rgba(113, 102, 105, 0.2)",
        borderRight: "0.5em solid rgba(113, 102, 105, 0.2)",
        borderBottom: "0.5em solid rgba(113, 102, 105, 0.2)",
        borderLeft: "0.5em solid #716669",
        transform: "translateZ(0)",
        animationName: [translateKeyframes],
        animationDuration: "1.1s",
        animationIterationCount: "infinite",
        animationTimingFunction: "linear",
        ":after": {
            borderRadius: "50%",
            width: "3.5em",
            height: "3.5em",
        },
    },
});

export default class Loader extends PureComponent {
    render() {
        return <div className={css(styles.loader)} />;
    }
}
