import { StyleSheet } from "aphrodite";
import * as colors from "./colors";

export default StyleSheet.create({
    imagePreview: {
        textAlign: "center",
        width: 90,
    },
    danger: {
        color: colors.red,
    },
});
