import { Button, Grid, Row, Col, FormGroup, FormControl, ControlLabel } from "react-bootstrap";
import React, { PureComponent } from "react";
import * as notification from "../../lib/api/notification";
import logger from "../../lib/logger";

const strings = {
    pageTitle: "Notifications | Habit CMS",
    notifications: "Notifications",
    title: "Title",
    message: "Message",
    send: "Send",
};

export default class NotificationPage extends PureComponent {
    state = {
        title: "",
        message: "",
    };

    componentDidMount() {
        document.title = strings.pageTitle;
        this._isMounted = true;
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    _handleTitleChange = e => {
        this.setState({
            title: e.target.value,
        });
    };

    _handleMessageChange = e => {
        this.setState({
            message: e.target.value,
        });
    };

    _onSend = () => {
        notification
            .sendGlobal(this.state.title, this.state.message)
            .then(() => {
                if (this._isMounted) {
                    this.setState({
                        title: "",
                        message: "",
                    });
                }
            })
            .catch(e => logger.warn(e));
    };

    render() {
        return (
            <Grid>
                <Row bsClass="m-t">
                    <Col md={12}>
                        <h4>{strings.notifications}</h4>
                    </Col>
                </Row>
                <Row>
                    <Col md={6}>
                        <FormGroup>
                            <ControlLabel>{strings.title}</ControlLabel>
                            <FormControl
                                type="text"
                                value={this.state.title}
                                placeholder={strings.enterText}
                                onChange={this._handleTitleChange}
                            />
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col md={6}>
                        <FormGroup>
                            <ControlLabel>{strings.message}</ControlLabel>
                            <FormControl
                                componentClass="textarea"
                                value={this.state.message}
                                placeholder={strings.enterText}
                                onChange={this._handleMessageChange}
                            />
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col md={12}>
                        <Button bsStyle="primary" onClick={this._onSend}>
                            {strings.send}
                        </Button>
                    </Col>
                </Row>
            </Grid>
        );
    }
}
