import instance from "./instance";
const responseData = response => response.data;

export function getItems() {
    return instance
        .request({
            url: `/cms/items`,
            method: "GET",
        })
        .then(responseData);
}

export function getItem(itemId) {
    return instance
        .request({
            url: `/cms/items/${itemId}`,
            method: "GET",
        })
        .then(responseData);
}

export function getItemModifier(itemId, modifierId) {
    return instance
        .request({
            url: `/cms/items/${itemId}/modifier/${modifierId}`,
            method: "GET",
        })
        .then(responseData);
}

export function updateItem(itemId, name, status, categoryIds) {
    return instance
        .request({
            url: `/cms/items/${itemId}`,
            method: "POST",
            data: {
                name,
                status,
                categoryIds,
            },
        })
        .then(responseData);
}

export function addImage(itemId, imageType, image, crop) {
    const formData = new FormData();
    formData.append("image", image);
    formData.append("centerX", crop.x);
    formData.append("centerY", crop.y);
    formData.append("width", crop.width);
    formData.append("height", crop.height);
    return instance
        .request({
            url: `/cms/items/${itemId}/image/${imageType}`,
            method: "POST",
            data: formData,
        })
        .then(responseData);
}

export function updateModifier(itemId, modifierId, status) {
    return instance
        .request({
            url: `/cms/items/${itemId}/modifier/${modifierId}`,
            method: "POST",
            data: {
                status,
            },
        })
        .then(responseData);
}

export function updateSelection(itemId, selectionId, status) {
    return instance
        .request({
            url: `/cms/items/${itemId}/selection/${selectionId}`,
            method: "POST",
            data: {
                status,
            },
        })
        .then(responseData);
}

export function reorderModifier(itemId, modifierId, position) {
    return instance
        .request({
            url: `/cms/items/${itemId}/modifier/${modifierId}/reorder`,
            method: "POST",
            data: {
                position,
            },
        })
        .then(responseData);
}

export function reorderSelection(itemId, selectionId, position) {
    return instance
        .request({
            url: `/cms/items/${itemId}/selection/${selectionId}/reorder`,
            method: "POST",
            data: {
                position,
            },
        })
        .then(responseData);
}

export function getDropdown() {
    return instance
        .request({
            url: `/cms/items/dropdown`,
            method: "GET",
        })
        .then(responseData);
}
