import dateFns from "date-fns";
import PropTypes from "prop-types";
import React, { PureComponent } from "react";
import { Button } from "react-bootstrap";

const strings = {
    view: "VIEW",
};

export default class FeedbackTable extends PureComponent {
    static propTypes = {
        feedback: PropTypes.object.isRequired,
        setFeedbackModal: PropTypes.func.isRequired,
    };

    _formatDate = dateString => {
        const date = new Date(dateString);
        return dateFns.format(date, "MM-DD-YYYY hh:mma");
    };

    _setFeedbackModal = () => this.props.setFeedbackModal(this.props.feedback);

    render() {
        const { feedback } = this.props;
        return (
            <tr>
                <td>{feedback.name}</td>
                <td>{feedback.email}</td>
                <td>{feedback.phone}</td>
                <td>{this._formatDate(feedback.submitted)}</td>
                <td>{feedback.storeNumber ? feedback.storeNumber : "N/A"}</td>
                <td>
                    <Button onClick={this._setFeedbackModal}>{strings.view}</Button>
                </td>
            </tr>
        );
    }
}
