import {
    Button,
    Grid,
    Row,
    Col,
    FormGroup,
    FormControl,
    ControlLabel,
    Table,
} from "react-bootstrap";
import DeleteConfirmModal from "../DeleteConfirmModal";
import LinkButton from "../LinkButton";
import Loader from "../Loader";
import React, { PureComponent } from "react";
import * as featured from "../../lib/api/featured";
import logger from "../../lib/logger";

const strings = {
    title: "Featured Products | Habit CMS",
    featuredItems: "Featured Items",
    add: "Add",
    filterByName: "Filter by Name:",
    enterText: "Enter text",
    name: "Name",
    product: "Product",
    numberOfStores: "# of stores used in",
    numberOfCategories: "# of categories used in",
    edit: "Edit",
    useInNav: "Use in Nav Drawer",
    inUse: "In Use",
    useInDrawer: "Use in Drawer",
    delete: "Delete",
    confirmDelete: "Are you sure you want to delete that featured product?",
};

export default class FeaturedProductsPage extends PureComponent {
    state = {
        loading: true,
        featuredProducts: [],
        nameFilter: "",
        deleteFeaturedProduct: null,
    };

    componentDidMount() {
        this._isMounted = true;
        document.title = strings.title;
        featured
            .getProducts()
            .then(resp => {
                if (this._isMounted) {
                    this.setState({ featuredProducts: resp.products, loading: false });
                }
            })
            .catch(err => {
                logger.warn(err);
                if (this._isMounted) {
                    this.setState({ loading: false });
                }
            });
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    _useInNavDrawer = product => {
        featured
            .useInNav(product.id)
            .then(() => {
                if (this._isMounted) {
                    this.setState({
                        featuredProducts: this.state.featuredProducts.map(fp => ({
                            ...fp,
                            navFeatured: fp.id === product.id,
                        })),
                    });
                }
            })
            .catch(err => logger.warn(err));
    };

    _handleNameChange = e => {
        this.setState({ nameFilter: e.target.value });
    };

    _openDeleteConfirmModal = featuredProduct => {
        this.setState({ deleteFeaturedProduct: featuredProduct });
    };

    _closeDeleteConfirmModal = () => {
        this.setState({ deleteFeaturedProduct: null });
    };

    _handleDelete = () => {
        featured.deleteProduct(this.state.deleteFeaturedProduct.id);
        this.setState({
            featuredProducts: this.state.featuredProducts.filter(
                p => p.id !== this.state.deleteFeaturedProduct.id,
            ),
            deleteFeaturedProduct: null,
        });
    };

    render() {
        if (this.state.loading) {
            return <Loader />;
        }
        const lowerFilter = this.state.nameFilter.toLowerCase();
        const filteredProducts = this.state.featuredProducts.filter(
            fp => fp.name.toLowerCase().indexOf(lowerFilter) !== -1,
        );
        return (
            <Grid>
                <Row>
                    <h3>{strings.featuredItems}</h3>
                </Row>
                <Row>
                    <Col md={4}>
                        <FormGroup controlId="name">
                            <ControlLabel>{strings.filterByName}</ControlLabel>
                            <FormControl
                                type="text"
                                value={this.state.nameFilter}
                                placeholder={strings.enterText}
                                onChange={this._handleNameChange}
                            />
                        </FormGroup>
                    </Col>
                    <Col mdOffset={3} md={3}>
                        <LinkButton bsStyle="primary" to="/featured/new">
                            {strings.add}
                        </LinkButton>
                    </Col>
                </Row>
                <Row>
                    <Col md={12}>
                        <Table striped bordered hover>
                            <thead>
                                <tr>
                                    <th>{strings.name}</th>
                                    <th>{strings.product}</th>
                                    <th>{strings.useInNav}</th>
                                    <th>{strings.edit}</th>
                                    <th>{strings.delete}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {filteredProducts.map(fp => (
                                    <tr key={fp.id}>
                                        <td>{fp.name}</td>
                                        <td>{fp.productName}</td>
                                        <td>
                                            {fp.navFeatured ? (
                                                strings.inUse
                                            ) : (
                                                <Button
                                                    bsStyle="primary"
                                                    onClick={() => this._useInNavDrawer(fp)}
                                                >
                                                    {strings.useInDrawer}
                                                </Button>
                                            )}
                                        </td>
                                        <td>
                                            <LinkButton bsStyle="primary" to={`/featured/${fp.id}`}>
                                                {strings.edit}
                                            </LinkButton>
                                        </td>
                                        <td>
                                            <Button
                                                bsStyle="danger"
                                                onClick={() => this._openDeleteConfirmModal(fp)}
                                            >
                                                {strings.delete}
                                            </Button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </Col>
                </Row>
                <DeleteConfirmModal
                    show={!!this.state.deleteFeaturedProduct}
                    title={strings.confirmDelete}
                    handleClose={this._closeDeleteConfirmModal}
                    handleConfirm={this._handleDelete}
                />
            </Grid>
        );
    }
}
