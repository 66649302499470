import instance from "./instance";
const responseData = response => response.data;

export function login(username, password) {
    return instance
        .request({
            url: "/cms/account/login",
            method: "POST",
            data: `grant_type=password&username=${username}&password=${password}`,
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            },
        })
        .then(responseData);
}

export function logout(refreshToken) {
    return instance
        .request({
            url: "/cms/account/logout",
            method: "POST",
            data: {
                refreshToken: refreshToken,
            },
        })
        .then(responseData);
}

export function ccpaDelete(email) {
    return instance
        .request({
            url: "/cms/account/ccpa-delete",
            method: "POST",
            data: {
                email,
            },
        })
        .then(responseData);
}

export function ccpaExport(email) {
    return instance
        .request({
            url: "/cms/account/ccpa-export",
            method: "POST",
            data: {
                email,
            },
        })
        .then(responseData);
}

export function ccpaAudit(email) {
    return instance
        .request({
            url: "/cms/account/ccpa-audit",
            method: "POST",
            data: {
                email,
            },
        })
        .then(responseData);
}
