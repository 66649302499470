import instance from "./instance";
import qs from "query-string";

export function get(start, end, startDate, endDate, search) {
    const params = {
        start,
        end,
        startDate,
        endDate,
        search,
    };
    return instance
        .request({
            url: `/cms/feedback/report?${qs.stringify(params)}`,
            method: "GET",
        })
        .then(res => res.data);
}

export function download(startDate, endDate, search) {
    const params = {
        startDate,
        endDate,
        search,
    };
    return instance.request({
        responseType: "blob",
        url: `/cms/feedback/download-report?${qs.stringify(params)}`,
        method: "GET",
    });
}
