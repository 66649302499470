import { Glyphicon } from "react-bootstrap";
import React, { PureComponent } from "react";
import * as habitPropTypes from "../habitPropTypes";
import PropTypes from "prop-types";

export default class ImagePreview extends PureComponent {
    static propTypes = {
        images: PropTypes.arrayOf(habitPropTypes.image),
        height: PropTypes.number.isRequired,
    };

    render() {
        if (this.props.images && this.props.images.length) {
            return (
                <img
                    style={{ height: this.props.height }}
                    src={
                        this.props.images[0].url && this.props.images[0].mimeType !== "test"
                            ? this.props.images[0].url
                            : "http://via.placeholder.com/150x150"
                    }
                    alt="preview"
                />
            );
        } else {
            return <Glyphicon glyph="exclamation-sign" style={{ fontSize: this.props.height }} />;
        }
    }
}
