import instance from "./instance";
const responseData = response => response.data;

export function getCustomers(email) {
    const url = `/cms/customer-accounts?email=${email}`;
    return instance
        .request({
            url,
            method: "GET",
        })
        .then(responseData);
}

export function updateCustomerInfo(firstName, lastName, phoneNumber, accountId, accountType) {
    const url = `/cms/customer-accounts/${accountId}`;
    const requestBody = {
        firstName: firstName,
        lastName: lastName,
        phoneNumber: phoneNumber,
        accountType: accountType,
    };

    return instance.request({
        url: url,
        data: requestBody,
        method: "put",
        headers: {
            "Content-Type": "application/json",
        },
    });
}
