/* eslint-disable react/jsx-no-bind */

import * as landingPages from "../../lib/landingPages";
import * as util from "../../lib/util";
import React, { PureComponent } from "react";
import {
    Grid,
    Row,
    Col,
    FormGroup,
    ControlLabel,
    FormControl,
    HelpBlock,
    Checkbox,
    Button,
} from "react-bootstrap";
import ImageSetUploader from "../ImageSetUploader";
import * as registrationPrompts from "../../lib/api/registrationPrompts";
import { StyleSheet, css } from "aphrodite";
import PropTypes from "prop-types";
import DuplicateLandingPageModal from "../DuplicateLandingPageModal";
import logger from "../../lib/logger";
import Loader from "../Loader";
import FailedToLoadAlert from "../FailedToLoadAlert";
import AlertModal from "../AlertModal";
import LandingPageCrumbEditHeader from "../LandingPageCrumbEditHeader";

const styles = StyleSheet.create({
    loadingContainer: {
        position: "absolute",
        top: "50px",
        right: 0,
        bottom: 0,
        left: 0,
        backgroundColor: "rgba(255, 255, 255, 0.3)",
        display: "flex",
        alignItems: "center",
    },
    textArea: {
        minHeight: "80px",
        width: "100%",
    },
    imageUploadContainer: {
        border: "1px solid #DADADA",
        borderRadius: "4px",
        marginBottom: "16px",
        overflow: "hidden",
    },
    slugFormGroup: {
        marginBottom: "4px",
    },
    slugHelpText: {
        marginBottom: 0,
    },
    copySlugContainer: {
        height: "fit-content",
        display: "flex",
        alignItems: "center",
        flexWrap: "wrap",
    },
    fullLandingPageUrl: {
        marginRight: "12px",
    },
    copyUrlButton: {
        padding: 0,
    },
    isActiveCheckbox: {
        marginBottom: "100px",
    },
});

const strings = {
    newPageSlug: "new",
    loading: "Loading",
    titleSuffix: "| Habit CMS",
    registrationPromptPages: "Registration Prompt Pages",
    newRegistrationPrompt: "New Registration Prompt",
    registrationPrompt: "Registration Prompt",
    registraionPromptUrl: "registration-prompt",
    registrationPromptsUrl: "/registration-prompts",
    available: "Available",
    generalErrorMsg: "There was an error with your request.",
    name: "Name",
    namePlaceholder: "Enter name",
    headerText: "Header Text",
    headerTextPlaceholder: "Enter header text",
    completionHeaderText: "Completion Header Text",
    completionHeaderTextPlaceholder: "Enter completion header text",
    paragraphText: "Paragraph Text",
    paragraphTextPlaceholder: "Enter paragraph text",
    completionText: "Completion Text",
    completionTextPlaceholder: "Enter completion text",
    alreadyCompletedText: "Already Completed Text",
    alreadyCompletedTextPlaceholder: "Enter already completed text",
    slug: "URL Slug",
    slugPlaceholder: "Enter URL slug",
    copyUrl: "Copy URL",
    activeCheckboxLabel: "Active",
    desktopImageLabel: "Hero Image Desktop",
    tabletImageLabel: "Hero Image Tablet",
    mobileImageLabel: "Hero Image Mobile",
    logoImageLabel: "Logo Image (Optional)",
};

/*

image state fields: {image, centerX, centerY, width, height, imageData} - the first 5 fields need to be submitted

*/

class RegistrationPromptPage extends PureComponent {
    static propTypes = {
        match: PropTypes.object.isRequired,
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
    };

    state = {
        isLoading: true,
        showAlertModal: false,
        pageData: null,
        showDuplicateModal: false,
        inUseNames: new Set(),
        inUseSlugs: new Set(),
        name: "",
        headerText: "",
        completionHeaderText: "",
        paragraphText: "",
        completionText: "",
        alreadyCompletedText: "",
        promoSlug: "",
        isActive: false,
        desktopImage: null,
        tabletImage: null,
        mobileImage: null,
        logoImage: null,
        duplicateName: "",
        duplicateSlug: "",
        errorMsg: "",
    };

    _isNewRegistrationPrompt = () => {
        return this.props.match.params.registrationPromptId === strings.newPageSlug;
    };

    componentDidMount() {
        this._isMounted = true;
        document.title = `${strings.loading} ${strings.titleSuffix}`;

        if (!this._isMounted) {
            return;
        }

        const locationState = this.props.location.state;
        const pageData = locationState ? locationState.pageData : undefined;
        const inUseNames = locationState ? locationState.inUseNames : undefined; // If this field is missing, then the inUseNames state will remain an empty set
        const inUseSlugs = locationState ? locationState.inUseSlugs : undefined; // If this field is missing, then the inUseSlugs state will remain an empty set

        if (!pageData && !this._isNewRegistrationPrompt()) {
            this.setState({ isLoading: false });
            return;
        }

        if (pageData) {
            this.setState({
                name: pageData.name,
                headerText: pageData.headerText,
                completionHeaderText: pageData.completionHeaderText,
                paragraphText: pageData.paragraphText,
                completionText: pageData.completionText,
                alreadyCompletedText: pageData.alreadyCompletedText,
                promoSlug: pageData.promoSlug,
                isActive: pageData.isActive,
            });
        }

        this.setState({
            ...(!!inUseNames && { inUseNames }),
            ...(!!inUseSlugs && { inUseSlugs }),
        });

        if (this._isNewRegistrationPrompt()) {
            document.title = `${strings.newRegistrationPrompt} ${strings.titleSuffix}`;
            this.setState({
                isLoading: false,
            });
            return;
        }

        document.title = `${pageData.name} ${strings.titleSuffix}`;
        this.setState({
            isLoading: false,
            pageData,
        });
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    _handleImageOnSubmit = (imageCategory, image, crop, imageData) => {
        this.setState({
            [imageCategory]: {
                image,
                centerX: crop.x,
                centerY: crop.y,
                width: crop.width,
                height: crop.height,
                imageData: imageData,
            },
        });
    };

    _handleSavePage = () => {
        this.setState({ isLoading: true });
        if (this.state.pageData && !this._isNewRegistrationPrompt()) {
            const editedPageData = {
                ...(this.state.pageData.name !== this.state.name && {
                    name: this.state.name.trim(),
                }),
                ...(this.state.pageData.headerText !== this.state.headerText && {
                    headerText: this.state.headerText.trim(),
                }),
                ...(this.state.pageData.completionHeaderText !==
                    this.state.completionHeaderText && {
                    completionHeaderText: this.state.completionHeaderText.trim(),
                }),
                ...(this.state.pageData.paragraphText !== this.state.paragraphText && {
                    paragraphText: this.state.paragraphText.trim(),
                }),
                ...(this.state.pageData.completionText !== this.state.completionText && {
                    completionText: this.state.completionText.trim(),
                }),
                ...(this.state.pageData.alreadyCompletedText !==
                    this.state.alreadyCompletedText && {
                    alreadyCompletedText: this.state.alreadyCompletedText.trim(),
                }),
                ...(this.state.pageData.promoSlug !== this.state.promoSlug && {
                    promoSlug: this.state.promoSlug.trim(),
                }),
                ...(this.state.pageData.isActive !== this.state.isActive && {
                    isActive: this.state.isActive,
                }),
                ...(!!this.state.desktopImage && {
                    desktopImage: this.state.desktopImage,
                }),
                ...(!!this.state.tabletImage && { tabletImage: this.state.tabletImage }),
                ...(!!this.state.mobileImage && { mobileImage: this.state.mobileImage }),
                ...(!!this.state.logoImage && { logoImage: this.state.logoImage }),
            };
            return registrationPrompts
                .editRegistrationPrompt(this.state.pageData.id, editedPageData)
                .then(resp => {
                    if (this._isMounted) {
                        this.setState({ isLoading: false });
                        this.props.history.push(strings.registrationPromptsUrl);
                    }
                    return resp;
                })
                .catch(e => {
                    logger.warn(e);
                    let errorMsg = strings.generalErrorMsg;
                    if (
                        e &&
                        e.response &&
                        e.response.status === 400 &&
                        e.response.data &&
                        typeof e.response.data === "string"
                    ) {
                        errorMsg = e.response.data;
                    }
                    this.setState({ isLoading: false, showAlertModal: true, errorMsg });
                });
        }

        return registrationPrompts
            .createRegistrationPrompt({
                name: this.state.name.trim(),
                headerText: this.state.headerText.trim(),
                completionHeaderText: this.state.completionHeaderText.trim(),
                paragraphText: this.state.paragraphText.trim(),
                completionText: this.state.completionText.trim(),
                alreadyCompletedText: this.state.alreadyCompletedText.trim(),
                promoSlug: this.state.promoSlug.trim(),
                isActive: this.state.isActive,
                desktopImage: this.state.desktopImage,
                tabletImage: this.state.tabletImage,
                mobileImage: this.state.mobileImage,
                logoImage: this.state.logoImage,
            })
            .then(resp => {
                if (this._isMounted) {
                    this.setState({ isLoading: false });
                    this.props.history.push(strings.registrationPromptsUrl);
                }
                return resp;
            })
            .catch(e => {
                logger.warn(e);
                let errorMsg = strings.generalErrorMsg;
                if (
                    e &&
                    e.response &&
                    e.response.status === 400 &&
                    e.response.data &&
                    typeof e.response.data === "string"
                ) {
                    errorMsg = e.response.data;
                }
                this.setState({ isLoading: false, showAlertModal: true, errorMsg });
            });
    };

    _showDuplicateModal = () => this.setState({ showDuplicateModal: true });

    _handleCloseDuplicateModal = () =>
        this.setState({ showDuplicateModal: false, duplicateName: "", duplicateSlug: "" });

    _handleDuplicate = () => {
        if (this._isMounted) {
            this.props.history.push("/registration-prompt/new", {
                pageData: {
                    name: this.state.duplicateName.trim(),
                    headerText: this.state.headerText.trim(),
                    completionHeaderText: this.state.completionHeaderText.trim(),
                    paragraphText: this.state.paragraphText.trim(),
                    completionText: this.state.completionText.trim(),
                    alreadyCompletedText: this.state.alreadyCompletedText.trim(),
                    isActive: this.state.isActive,
                    promoSlug: this.state.duplicateSlug.trim(),
                },
                inUseNames: this.state.inUseNames,
                inUseSlugs: this.state.inUseSlugs,
            });
        }
    };

    _getIsValidName = value =>
        !!value &&
        (!this.state.inUseNames.has(value) ||
            (!!this.state.pageData && this.state.pageData.name === value));
    _getIsValidSlug = value =>
        !!value &&
        (!this.state.inUseSlugs.has(value) ||
            (!!this.state.pageData && this.state.pageData.promoSlug === value));
    _refineSlug = value => value.replace(/\s/g, "").toLowerCase();
    _getAvailableMsg = value => `${value} ${strings.available}`;

    _getIsValidDuplicateName = value => !!value && !this.state.inUseNames.has(value);
    _getIsValidDuplicateSlug = value => !!value && !this.state.inUseSlugs.has(value);

    render() {
        if (!this.state.pageData && !this._isNewRegistrationPrompt()) {
            return <FailedToLoadAlert type={strings.registrationPrompt} />;
        }

        const pageData = this.state.pageData;

        const isInputValid =
            this._getIsValidName(this.state.name.trim()) &&
            !!this.state.headerText.trim() &&
            !!this.state.completionHeaderText.trim() &&
            !!this.state.paragraphText.trim() &&
            !!this.state.completionText.trim() &&
            !!this.state.alreadyCompletedText.trim() &&
            this._getIsValidSlug(this.state.promoSlug.trim()) &&
            !!(this.state.desktopImage || (pageData && pageData.desktopImages)) &&
            !!(this.state.tabletImage || (pageData && pageData.tabletImages)) &&
            !!(this.state.mobileImage || (pageData && pageData.mobileImages));

        // isChanged is not useful if creating a new page, can potentially be both true and false depending on if duplicate
        const isChanged =
            !!pageData &&
            !!(
                pageData.name !== this.state.name.trim() ||
                pageData.headerText !== this.state.headerText.trim() ||
                pageData.completionHeaderText !== this.state.completionHeaderText.trim() ||
                pageData.paragraphText !== this.state.paragraphText.trim() ||
                pageData.completionText !== this.state.completionText.trim() ||
                pageData.alreadyCompletedText !== this.state.alreadyCompletedText.trim() ||
                pageData.promoSlug !== this.state.promoSlug.trim() ||
                pageData.isActive !== this.state.isActive ||
                !!this.state.desktopImage ||
                !!this.state.tabletImage ||
                !!this.state.mobileImage ||
                !!this.state.logoImage
            );

        const isDuplicateModalEnabled =
            !this.state.isLoading && !this._isNewRegistrationPrompt() && !isChanged;
        const isSavePageEnabled =
            !this.state.isLoading &&
            isInputValid &&
            (isChanged || this._isNewRegistrationPrompt());

        const isDuplicateEnabled =
            this._getIsValidDuplicateName(this.state.duplicateName.trim()) &&
            this._getIsValidDuplicateSlug(this.state.duplicateSlug.trim());

        const fullLandingPageUrl = landingPages.getFullLandingPageUrl(
            strings.registraionPromptUrl,
            this.state.promoSlug.trim(),
        );

        return (
            <Grid>
                <LandingPageCrumbEditHeader
                    landingPagesLinkText={strings.registrationPromptPages}
                    landingPagesLinkUrl={strings.registrationPromptsUrl}
                    pageTitle={pageData ? pageData.name : strings.newRegistrationPrompt}
                    isDuplicateEnabled={isDuplicateModalEnabled}
                    onDuplicate={this._showDuplicateModal}
                    isSavePageEnabled={isSavePageEnabled}
                    onSavePage={this._handleSavePage}
                />
                <form>
                    <Row>
                        <Col md={5}>
                            <FormGroup controlId="name">
                                <ControlLabel>{strings.name}</ControlLabel>
                                <FormControl
                                    required
                                    type="text"
                                    value={this.state.name}
                                    placeholder={strings.namePlaceholder}
                                    onChange={e => this.setState({ name: e.target.value })}
                                />
                                <HelpBlock>
                                    {this._getIsValidName(this.state.name)
                                        ? this._getAvailableMsg(strings.name)
                                        : ""}
                                </HelpBlock>
                            </FormGroup>
                            <FormGroup controlId="header-text">
                                <ControlLabel>{strings.headerText}</ControlLabel>
                                <FormControl
                                    type="text"
                                    value={this.state.headerText}
                                    placeholder={strings.headerTextPlaceholder}
                                    onChange={e => this.setState({ headerText: e.target.value })}
                                />
                            </FormGroup>
                            <FormGroup controlId="completion-header-text">
                                <ControlLabel>{strings.completionHeaderText}</ControlLabel>
                                <FormControl
                                    className={css(styles.textArea)}
                                    componentClass="textarea"
                                    value={this.state.completionHeaderText}
                                    placeholder={strings.completionHeaderTextPlaceholder}
                                    onChange={e =>
                                        this.setState({ completionHeaderText: e.target.value })
                                    }
                                />
                            </FormGroup>
                            <FormGroup controlId="paragraph-text">
                                <ControlLabel>{strings.paragraphText}</ControlLabel>
                                <FormControl
                                    className={css(styles.textArea)}
                                    componentClass="textarea"
                                    value={this.state.paragraphText}
                                    placeholder={strings.paragraphTextPlaceholder}
                                    onChange={e => this.setState({ paragraphText: e.target.value })}
                                />
                            </FormGroup>
                            <FormGroup controlId="completion-text">
                                <ControlLabel>{strings.completionText}</ControlLabel>
                                <FormControl
                                    className={css(styles.textArea)}
                                    componentClass="textarea"
                                    value={this.state.completionText}
                                    placeholder={strings.completionTextPlaceholder}
                                    onChange={e =>
                                        this.setState({ completionText: e.target.value })
                                    }
                                />
                            </FormGroup>
                            <FormGroup controlId="already-completed-text">
                                <ControlLabel>{strings.alreadyCompletedText}</ControlLabel>
                                <FormControl
                                    className={css(styles.textArea)}
                                    componentClass="textarea"
                                    value={this.state.alreadyCompletedText}
                                    placeholder={strings.alreadyCompletedTextPlaceholder}
                                    onChange={e =>
                                        this.setState({ alreadyCompletedText: e.target.value })
                                    }
                                />
                            </FormGroup>
                            <FormGroup controlId="slug" className={css(styles.slugFormGroup)}>
                                <ControlLabel>{strings.slug}</ControlLabel>
                                <FormControl
                                    required
                                    type="text"
                                    value={this.state.promoSlug}
                                    placeholder={strings.slugPlaceholder}
                                    onChange={e =>
                                        this.setState({
                                            promoSlug: this._refineSlug(e.target.value),
                                        })
                                    }
                                />
                                <HelpBlock className={css(styles.slugHelpText)}>
                                    {this._getIsValidSlug(this.state.promoSlug)
                                        ? this._getAvailableMsg(strings.slug)
                                        : ""}
                                </HelpBlock>
                            </FormGroup>
                            <div className={css(styles.copySlugContainer)}>
                                <span className={css(styles.fullLandingPageUrl)}>
                                    {fullLandingPageUrl}
                                </span>
                                <Button
                                    bsStyle={"link"}
                                    className={css(styles.copyUrlButton)}
                                    onClick={() => util.copyToClipboard(fullLandingPageUrl)}
                                >
                                    {strings.copyUrl}
                                </Button>
                            </div>
                            <FormGroup>
                                <Checkbox
                                    className={css(styles.isActiveCheckbox)}
                                    checked={this.state.isActive}
                                    onChange={() =>
                                        this.setState({
                                            isActive: !this.state.isActive,
                                        })
                                    }
                                    disabled={this.state.isLoading}
                                >
                                    {strings.activeCheckboxLabel}
                                </Checkbox>
                            </FormGroup>
                        </Col>
                        <Col md={6} mdPush={1}>
                            <div className={css(styles.imageUploadContainer)}>
                                <ImageSetUploader
                                    label={strings.desktopImageLabel}
                                    minWidth={709}
                                    minHeight={1080}
                                    onSubmit={(image, crop, imageData) =>
                                        this._handleImageOnSubmit(
                                            "desktopImage",
                                            image,
                                            crop,
                                            imageData,
                                        )
                                    }
                                    images={
                                        this.state.pageData && !this.state.desktopImage
                                            ? this.state.pageData.desktopImages
                                            : null
                                    }
                                    previewImage={
                                        this.state.desktopImage
                                            ? this.state.desktopImage.imageData
                                            : null
                                    }
                                />
                            </div>
                            <div className={css(styles.imageUploadContainer)}>
                                <ImageSetUploader
                                    label={strings.tabletImageLabel}
                                    minWidth={834}
                                    minHeight={378}
                                    onSubmit={(image, crop, imageData) =>
                                        this._handleImageOnSubmit(
                                            "tabletImage",
                                            image,
                                            crop,
                                            imageData,
                                        )
                                    }
                                    images={
                                        this.state.pageData && !this.state.tabletImage
                                            ? this.state.pageData.tabletImages
                                            : null
                                    }
                                    previewImage={
                                        this.state.tabletImage
                                            ? this.state.tabletImage.imageData
                                            : null
                                    }
                                />
                            </div>
                            <div className={css(styles.imageUploadContainer)}>
                                <ImageSetUploader
                                    label={strings.mobileImageLabel}
                                    minWidth={393}
                                    minHeight={346}
                                    onSubmit={(image, crop, imageData) =>
                                        this._handleImageOnSubmit(
                                            "mobileImage",
                                            image,
                                            crop,
                                            imageData,
                                        )
                                    }
                                    images={
                                        this.state.pageData && !this.state.mobileImage
                                            ? this.state.pageData.mobileImages
                                            : null
                                    }
                                    previewImage={
                                        this.state.mobileImage
                                            ? this.state.mobileImage.imageData
                                            : null
                                    }
                                />
                            </div>
                            <div className={css(styles.imageUploadContainer)}>
                                <ImageSetUploader
                                    label={strings.logoImageLabel}
                                    minWidth={40}
                                    minHeight={40}
                                    onSubmit={(image, crop, imageData) =>
                                        this._handleImageOnSubmit(
                                            "logoImage",
                                            image,
                                            crop,
                                            imageData,
                                        )
                                    }
                                    images={
                                        this.state.pageData && !this.state.logoImage
                                            ? this.state.pageData.logoImages
                                            : null
                                    }
                                    previewImage={
                                        this.state.logoImage ? this.state.logoImage.imageData : null
                                    }
                                    overrideMinAspectRatio
                                />
                            </div>
                        </Col>
                    </Row>
                </form>
                <DuplicateLandingPageModal
                    show={this.state.showDuplicateModal}
                    itemName={strings.registrationPrompt}
                    handleClose={this._handleCloseDuplicateModal}
                    handleDuplicate={this._handleDuplicate}
                    duplicateDisabled={!isDuplicateEnabled}
                >
                    <FormGroup controlId="duplicate-name">
                        <ControlLabel>{strings.name}</ControlLabel>
                        <FormControl
                            type="text"
                            required
                            value={this.state.duplicateName}
                            placeholder={strings.namePlaceholder}
                            onChange={e => this.setState({ duplicateName: e.target.value })}
                        />
                        <HelpBlock>
                            {this._getIsValidDuplicateName(this.state.duplicateName.trim())
                                ? this._getAvailableMsg(strings.name)
                                : ""}
                        </HelpBlock>
                    </FormGroup>
                    <FormGroup controlId="duplicate-slug">
                        <ControlLabel>{strings.slug}</ControlLabel>
                        <FormControl
                            type="text"
                            required
                            value={this.state.duplicateSlug}
                            placeholder={strings.slugPlaceholder}
                            onChange={e =>
                                this.setState({ duplicateSlug: this._refineSlug(e.target.value) })
                            }
                        />
                        <HelpBlock>
                            {this._getIsValidDuplicateSlug(this.state.duplicateSlug.trim())
                                ? this._getAvailableMsg(strings.slug)
                                : ""}
                        </HelpBlock>
                    </FormGroup>
                </DuplicateLandingPageModal>
                <AlertModal
                    show={this.state.showAlertModal}
                    alertMessage={this.state.errorMsg}
                    handleClose={() => this.setState({ showAlertModal: false, errorMsg: "" })}
                />
                {this.state.isLoading ? (
                    <div className={css(styles.loadingContainer)}>
                        <Loader />
                    </div>
                ) : null}
            </Grid>
        );
    }
}

export default RegistrationPromptPage;
