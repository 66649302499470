import {
    Button,
    Grid,
    Row,
    Col,
    FormGroup,
    FormControl,
    ControlLabel,
    Table,
} from "react-bootstrap";
import LinkButton from "../LinkButton";
import Loader from "../Loader";
import GuestDetailsModal from "../GuestDetailsModal";
import PageButtons from "../PageButtons";
import React, { PureComponent } from "react";
import * as guests from "../../lib/api/guests";
import logger from "../../lib/logger";
import { css, StyleSheet } from "aphrodite";
import dateFns from "date-fns";

const styles = StyleSheet.create({
    center: {
        textAlign: "center",
    },
});

const strings = {
    title: "Guests | Habit CMS",
    search: "Search",
    name: "Name",
    email: "Email",
    phone: "Phone #",
    details: "Details",
    createDate: "Create Date",
    orderCount: "Order Count",
    of: "of",
    guests: "Guests",
    enterText: "Name, Email, or Phone",
};

const PAGE_SIZE = 20;

export default class GuestsPage extends PureComponent {
    state = {
        currentPage: 0,
        filter: "",
        loading: true,
        guests: [],
        total: 0,
        detailsGuest: undefined,
    };

    componentDidMount() {
        this._isMounted = true;
        document.title = strings.title;
        this._loadOrders();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    _onFilterChange = e => {
        this.setState({ filter: e.target.value }, this._loadOrders);
    };

    _loadOrders = page => {
        if (!page) {
            page = 0;
        }
        if (!this.state.loading) {
            this.setState({ loading: true });
        }
        const currentReqTerms = {
            page: page,
            filter: this.state.filter,
        };
        this._currentReqTerms = currentReqTerms;
        guests
            .getGuests(page * PAGE_SIZE, page * PAGE_SIZE + PAGE_SIZE, this.state.filter)
            .then(resp => {
                if (this._isMounted && this._currentReqTerms === currentReqTerms) {
                    this.setState({
                        loading: false,
                        guests: resp.guests,
                        total: resp.total,
                        currentPage: page,
                    });
                }
            })
            .catch(err => {
                logger.warn(err);
                if (this._isMounted) {
                    this.setState({ loading: false });
                }
            });
    };

    _handlePageChange = page => {
        this._loadOrders(page - 1);
    };

    _openGuestDetails = g => {
        this.setState({ detailsGuest: g });
    };

    _handleCloseDetailsModal = () => {
        this.setState({ detailsGuest: undefined });
    };

    _formatCreateDate = dateString => {
        const date = new Date(dateString);
        return dateFns.format(date, "MM-DD-YYYY hh:mma");
    };

    render() {
        return (
            <Grid>
                <Row>
                    <h3>{strings.guests}</h3>
                </Row>
                <Row>
                    <Col md={4}>
                        <FormGroup controlId="filter">
                            <ControlLabel>{strings.search}</ControlLabel>
                            <FormControl
                                type="text"
                                value={this.state.filter}
                                placeholder={strings.enterText}
                                onChange={this._onFilterChange}
                            />
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col md={12}>
                        <Table striped bordered hover>
                            <thead>
                                <tr>
                                    <th>{strings.name}</th>
                                    <th>{strings.email}</th>
                                    <th>{strings.phone}</th>
                                    <th>{strings.createDate}</th>
                                    <th>{strings.details}</th>
                                    <th>{strings.orderCount}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.loading
                                    ? null
                                    : this.state.guests.map(g => (
                                          <tr key={g.id}>
                                              <td>{`${g.firstName} ${g.lastName}`}</td>
                                              <td>{g.email}</td>
                                              <td>{g.phoneNumber}</td>
                                              <td>{this._formatCreateDate(g.createDate)}</td>
                                              <td>
                                                  <Button onClick={() => this._openGuestDetails(g)}>
                                                      {strings.details}
                                                  </Button>
                                              </td>
                                              <td>
                                                  <LinkButton to={`/orders/${g.id}`}>
                                                      {g.orderCount}
                                                  </LinkButton>
                                              </td>
                                          </tr>
                                      ))}
                            </tbody>
                        </Table>
                        {this.state.loading ? <Loader /> : null}
                    </Col>
                </Row>
                <Row>
                    <Col md={2}>
                        <div>
                            {Math.min((this.state.currentPage + 1) * PAGE_SIZE, this.state.total)}{" "}
                            {`${strings.of} `}
                            {this.state.total}
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col md={12} className={css(styles.center)}>
                        <PageButtons
                            numPages={this.state.total / PAGE_SIZE}
                            currentPage={this.state.currentPage + 1}
                            handlePageChange={this._handlePageChange}
                        />
                    </Col>
                </Row>
                {this.state.detailsGuest ? (
                    <GuestDetailsModal
                        guest={this.state.detailsGuest}
                        show={!!this.state.detailsGuest}
                        handleClose={this._handleCloseDetailsModal}
                    />
                ) : null}
            </Grid>
        );
    }
}
