import * as orders from "../../lib/api/orders";
import * as purchaseSource from "../../enums/purchaseSource";
import Loader from "../Loader";
import logger from "../../lib/logger";
import OrderTable from "../OrderTable";
import PageButtons from "../PageButtons";
import React, { PureComponent } from "react";
import { css, StyleSheet } from "aphrodite";
import { Grid, Row, Col, FormGroup, FormControl, ControlLabel } from "react-bootstrap";

const styles = StyleSheet.create({
    center: {
        textAlign: "center",
    },
});

const strings = {
    title: "Orders | Habit CMS",
    orders: "Orders",
    store: "Store",
    storePlaceholder: "Store name or number",
    before: "End Date",
    after: "Start Date",
    of: "of",
    source: "Source",
};

const PAGE_SIZE = 20;

export default class OrdersPage extends PureComponent {
    state = {
        currentPage: 0,
        filter: "",
        loading: true,
        orders: [],
        total: 0,
        before: undefined,
        after: undefined,
        purchaseSource: purchaseSource.all.value,
    };

    componentDidMount() {
        this._isMounted = true;
        document.title = strings.title;
        this._loadOrders();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    _onFilterChange = e => {
        this.setState({ filter: e.target.value }, this._loadOrders);
    };

    _onBeforeChange = e => {
        this.setState({ before: e.target.value }, this._loadOrders);
    };

    _onAfterChange = e => {
        this.setState({ after: e.target.value }, this._loadOrders);
    };

    _onSourceChange = e => {
        this.setState({ source: e.target.value }, this._loadOrders);
    };

    _loadOrders = page => {
        if (!page) {
            page = 0;
        }
        if (!this.state.loading) {
            this.setState({ loading: true });
        }
        const currentReqTerms = {
            page: page,
            filter: this.state.filter,
        };
        this._currentReqTerms = currentReqTerms;
        orders
            .getOrders(
                page * PAGE_SIZE,
                page * PAGE_SIZE + PAGE_SIZE,
                this.state.filter,
                this.state.source,
                this.state.before,
                this.state.after,
            )
            .then(resp => {
                if (this._isMounted && this._currentReqTerms === currentReqTerms) {
                    this.setState({
                        loading: false,
                        orders: resp.orders,
                        total: resp.total,
                        currentPage: page,
                    });
                }
            })
            .catch(err => {
                logger.warn(err);
                if (this._isMounted) {
                    this.setState({ loading: false });
                }
            });
    };

    _handlePageChange = page => {
        this._loadOrders(page - 1);
    };

    render() {
        return (
            <Grid>
                <Row>
                    <h3>{strings.orders}</h3>
                </Row>
                <Row>
                    <Col md={4}>
                        <FormGroup controlId="filter">
                            <ControlLabel>{strings.store}</ControlLabel>
                            <FormControl
                                type="text"
                                value={this.state.filter}
                                placeholder={strings.storePlaceholder}
                                onChange={this._onFilterChange}
                            />
                        </FormGroup>
                    </Col>
                    <Col md={2}>
                        <FormGroup>
                            <ControlLabel>{strings.source}</ControlLabel>
                            <FormControl
                                componentClass="select"
                                value={this.state.status}
                                onChange={this._onSourceChange}
                            >
                                <option value={purchaseSource.all.value}>
                                    {purchaseSource.all.label}
                                </option>
                                <option value={purchaseSource.mobile.value}>
                                    {purchaseSource.mobile.label}
                                </option>
                                <option value={purchaseSource.kiosk.value}>
                                    {purchaseSource.kiosk.label}
                                </option>
                                <option value={purchaseSource.web.value}>
                                    {purchaseSource.web.label}
                                </option>
                                <option value={purchaseSource.webMobile.value}>
                                    {purchaseSource.webMobile.label}
                                </option>
                                <option value={purchaseSource.webAll.value}>
                                    {purchaseSource.webAll.label}
                                </option>
                            </FormControl>
                        </FormGroup>
                    </Col>
                    <Col md={2}>
                        <FormGroup controlId="after">
                            <ControlLabel>{strings.after}</ControlLabel>
                            <FormControl
                                type="date"
                                value={this.state.after}
                                onChange={this._onAfterChange}
                            />
                        </FormGroup>
                    </Col>
                    <Col md={2}>
                        <FormGroup controlId="before">
                            <ControlLabel>{strings.before}</ControlLabel>
                            <FormControl
                                type="date"
                                value={this.state.before}
                                onChange={this._onBeforeChange}
                            />
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col md={12}>
                        <OrderTable orders={this.state.orders} />
                        {this.state.loading ? <Loader /> : null}
                    </Col>
                </Row>
                <Row>
                    <Col md={2}>
                        <div>
                            {Math.min((this.state.currentPage + 1) * PAGE_SIZE, this.state.total)}{" "}
                            {`${strings.of} `}
                            {this.state.total}
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col md={12} className={css(styles.center)}>
                        <PageButtons
                            numPages={this.state.total / PAGE_SIZE}
                            currentPage={this.state.currentPage + 1}
                            handlePageChange={this._handlePageChange}
                        />
                    </Col>
                </Row>
            </Grid>
        );
    }
}
