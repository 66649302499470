import * as reducers from "./reducers";
import thunk from "redux-thunk";
import { createStore, applyMiddleware, combineReducers } from "redux";

const reducer = combineReducers(reducers);
let store;
if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
    const createLogger = require("redux-logger").createLogger;
    const logger = createLogger({
        collapsed: true,
        duration: true,
    });

    store = createStore(reducer, {}, applyMiddleware(thunk, logger));
} else {
    store = createStore(reducer, {}, applyMiddleware(thunk));
}

export default store;
