import { createInterceptor } from "axios-oauth";
import config from "../../config.json";
import axios from "axios";
import store from "../../store";
import * as sessionActions from "../../actions/sessionActions";

const instance = axios.create({
    baseURL: config.apiRoot,
});

const refreshInstance = axios.create({
    baseURL: config.apiRoot,
});

const oauthInterceptor = createInterceptor({
    axiosInstance: refreshInstance,
    getAccessFromAuthPayload: response => response.data.access_token,
    getAuthData: () => {
        const state = store.getState();
        return {
            accessToken: state.session.accessToken,
            expireDate: state.session.expireDate,
            refreshToken: state.session.refreshToken,
        };
    },
    getRefreshConfig: refreshToken => ({
        method: "POST",
        url: "/cms/account/refresh",
        headers: {
            // Authorization: `Basic ${config.basicAuth}`,
            "Content-Type": "application/x-www-form-urlencoded",
        },
        data: `grant_type=refresh_token&refresh_token=${refreshToken}`,
    }),
    onSignIn: response => {
        store.dispatch(sessionActions.storeAndSignIn(response.data));
    },
    onSignOut: () => {
        store.dispatch(sessionActions.makeSignOutRequest());
    },
    getAccessFromRefreshResponse: response => {
        return response.data.accessToken;
    },
    verbose: !process.env.NODE_ENV || process.env.NODE_ENV === "development",
});

instance.interceptors.request.use(oauthInterceptor.request);
instance.interceptors.response.use(null, oauthInterceptor.failure);

export default instance;
