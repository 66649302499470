import { Grid, Row, Col, FormGroup, FormControl, ControlLabel, Table } from "react-bootstrap";
import ImagePreview from "../ImagePreview";
import LinkButton from "../LinkButton";
import Loader from "../Loader";
import React, { PureComponent } from "react";
import * as menuStatus from "../../enums/menuStatus";
import * as items from "../../lib/api/items";
import tableStyles from "../../styles/tableStyles";
import { css } from "aphrodite";
import logger from "../../lib/logger";

const strings = {
    title: "Items | Habit CMS",
    items: "Items",
    filterByName: "Filter by Name:",
    filterByStatus: "Filter by Status:",
    enterText: "Enter text",
    name: "Name",
    category: "Categories",
    status: "Status",
    available: "Available",
    unavailable: "Unavailable",
    edit: "Edit",
};

export default class ItemsPage extends PureComponent {
    state = {
        filter: "",
        loading: true,
        items: [],
        status: menuStatus.all.value,
    };

    componentDidMount() {
        this._isMounted = true;
        document.title = strings.title;
        items
            .getItems()
            .then(resp => {
                if (this._isMounted) {
                    this.setState({ items: resp.items, loading: false });
                }
            })
            .catch(err => {
                logger.warn(err);
                if (this._isMounted) {
                    this.setState({ loading: false });
                }
            });
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    _onFilterChange = e => {
        e.preventDefault();
        this.setState({ filter: e.target.value });
    };

    _onStatusChange = e => {
        this.setState({ status: e.target.value });
    };

    render() {
        if (this.state.loading) {
            return <Loader />;
        }
        const lowerFilter = this.state.filter.toLowerCase();
        const currentItems = this.state.items.filter(i => {
            if (this.state.status === menuStatus.available.value) {
                if (i.status !== menuStatus.available.value) {
                    return false;
                }
            } else if (this.state.status === menuStatus.unavailable.value) {
                if (i.status !== menuStatus.unavailable.value) {
                    return false;
                }
            }
            return (
                (i.name && i.name.toLowerCase().indexOf(lowerFilter) !== -1) ||
                (i.categoryName && i.categoryName.toLowerCase().indexOf(lowerFilter) !== -1)
            );
        });
        return (
            <Grid>
                <Row>
                    <Col md={6}>
                        <h3>{strings.items}</h3>
                    </Col>
                </Row>
                <Row>
                    <Col md={4}>
                        <FormGroup>
                            <ControlLabel>{strings.filterByName}</ControlLabel>
                            <FormControl
                                type="text"
                                value={this.state.filter}
                                placeholder={strings.enterText}
                                onChange={this._onFilterChange}
                            />
                        </FormGroup>
                    </Col>
                    <Col md={4}>
                        <FormGroup>
                            <ControlLabel>{strings.filterByStatus}</ControlLabel>
                            <FormControl
                                componentClass="select"
                                value={this.state.status}
                                onChange={this._onStatusChange}
                            >
                                <option value={menuStatus.all.value}>{menuStatus.all.label}</option>
                                <option value={menuStatus.available.value}>
                                    {menuStatus.available.label}
                                </option>
                                <option value={menuStatus.unavailable.value}>
                                    {menuStatus.unavailable.label}
                                </option>
                            </FormControl>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col md={12}>
                        <Table striped bordered hover>
                            <thead>
                                <tr>
                                    <th />
                                    <th>{strings.name}</th>
                                    <th>{strings.category}</th>
                                    <th>{strings.status}</th>
                                    <th>{strings.edit}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {currentItems.map(item => (
                                    <tr key={item.id}>
                                        <td
                                            className={css(
                                                tableStyles.imagePreview,
                                                item.isolatedImages && item.isolatedImages.length
                                                    ? tableStyles.danger
                                                    : null,
                                            )}
                                        >
                                            <ImagePreview
                                                height={70}
                                                images={item.isolatedImages}
                                            />
                                        </td>
                                        <td>{item.name}</td>
                                        <td>{item.categoryName}</td>
                                        <td>
                                            {item.status === menuStatus.available.value
                                                ? menuStatus.available.label
                                                : menuStatus.unavailable.label}
                                        </td>
                                        <td>
                                            <LinkButton bsStyle="primary" to={`/item/${item.id}`}>
                                                {strings.edit}
                                            </LinkButton>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            </Grid>
        );
    }
}
