export const url = {
    label: "Url",
    value: "url_feature",
};
export const item = {
    label: "Item",
    value: "item_feature",
};
export const combo = {
    label: "Combo",
    value: "combo_feature",
};
