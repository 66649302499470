import { Button, Grid, Row, Col, FormGroup, FormControl, ControlLabel } from "react-bootstrap";
import React, { PureComponent } from "react";
import { StyleSheet, css } from "aphrodite";
import SuccessAlert from "./SuccessAlert";
import PropTypes from "prop-types";
import * as habitPropTypes from "../habitPropTypes";
import * as menuStatus from "../enums/menuStatus";
import * as categories from "../lib/api/categories";
import * as items from "../lib/api/items";
import Select from "react-select";

const styles = StyleSheet.create({
    container: {
        marginTop: 20,
    },
    saveButton: {
        width: "100%",
        marginBottom: 20,
    },
});

const strings = {
    categories: "Categories",
    displayName: "Display Name",
    available: "Available",
    allergens: "Allergens",
    unavailableReason: "Unavailable Reason",
    save: "Save",
    yes: "Yes",
    no: "No",
    saveSuccessful: "Save Successful",
};

export default class ItemPage extends PureComponent {
    static propTypes = {
        initialItem: habitPropTypes.item.isRequired,
        onSave: PropTypes.func.isRequired,
    };

    state = {
        name: this.props.initialItem.name,
        status: this.props.initialItem.status,
        allergens: this.props.initialItem.allergens,
        unavailableReason: this.props.initialItem.unavailableReason,
        allCategories: [],
        categoryIds: [],
    };

    componentDidMount = () => {
        this._isMounted = true;
        this._getCategoryOptions();
    };

    componentWillUnmount = () => {
        this._isMounted = false;
    };

    _onSave = () => {
        items
            .updateItem(
                this.props.initialItem.id,
                this.state.name,
                this.state.status,
                this.state.categoryIds.map(c => c.value),
            )
            .then(item => {
                this.props.onSave(item);
                if (this._isMounted) {
                    this.setState({ saveSuccessful: true });
                    if (this._hideSaveSuccessful) {
                        clearTimeout(this._hideSaveSuccessful);
                    }
                    this._hideSaveSuccessful = setTimeout(() => {
                        if (this._isMounted) {
                            this.setState({ saveSuccessful: false });
                        }
                        this._hideSaveSuccessful = null;
                    }, 2000);
                }
            });
    };

    _handleNameChange = e => {
        this.setState({ name: e.target.value });
    };

    _handleStatusChange = e => {
        this.setState({ status: e.target.value });
    };

    _handleUnavailableReasonChange = e => {
        this.setState({ unavailableReason: e.target.value });
    };

    _handleCategoryChange = categoryIds => {
        this.setState({ categoryIds });
    };

    _getCategoryOptions = () => {
        categories.getCategories().then(resp => {
            this.setState({
                allCategories: resp.categories.map(c => ({
                    value: c.id,
                    label: c.name,
                })),
                categoryIds: this.props.initialItem.categoryIds.map(id => {
                    const result = resp.categories.reduce(
                        (prev, curr) => (curr.id === id ? curr : prev),
                        null,
                    );
                    return {
                        value: result.id,
                        label: result.name,
                    };
                }),
            });
        });
    };

    render() {
        return (
            <Grid className={css(styles.container)}>
                <Row>
                    <Col md={2}>
                        <Button
                            className={css(styles.saveButton)}
                            bsStyle="primary"
                            onClick={this._onSave}
                        >
                            {strings.save}
                        </Button>
                    </Col>
                    {this.state.saveSuccessful ? (
                        <Col md={2}>
                            <SuccessAlert label={strings.saveSuccessful} />
                        </Col>
                    ) : null}
                </Row>
                <Row>
                    <Col md={6}>
                        <FormGroup controlId="name">
                            <ControlLabel>{strings.displayName}</ControlLabel>
                            <FormControl
                                type="text"
                                value={this.state.name}
                                placeholder={strings.enterText}
                                onChange={this._handleNameChange}
                            />
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col md={6}>
                        <FormGroup controlId="formControlsSelect">
                            <ControlLabel>{strings.available}</ControlLabel>
                            <FormControl
                                componentClass="select"
                                value={this.state.status}
                                onChange={this._handleStatusChange}
                            >
                                <option value={menuStatus.available.value}>{strings.yes}</option>
                                <option value={menuStatus.unavailable.value}>{strings.no}</option>
                            </FormControl>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col md={6}>
                        <FormGroup controlId="formControlsSelect">
                            <ControlLabel>{strings.categories}</ControlLabel>
                            <Select
                                closeMenuOnSelect={false}
                                isMulti
                                value={this.state.categoryIds}
                                options={this.state.allCategories}
                                onChange={this._handleCategoryChange}
                            />
                        </FormGroup>
                    </Col>
                </Row>
            </Grid>
        );
    }
}
