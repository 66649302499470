import { Modal, Button, FormGroup, FormControl, ControlLabel } from "react-bootstrap";
import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import * as menuStatus from "../enums/menuStatus";

const strings = {
    selection: "Modifier Selection",
    cancel: "Cancel",
    delete: "Delete",
    save: "Save",
    yes: "Yes",
    no: "No",
    name: "Name",
    available: "Available",
};

export default class EditModifierSelectionModal extends PureComponent {
    static propTypes = {
        show: PropTypes.bool.isRequired,
        initialName: PropTypes.string.isRequired,
        initialStatus: PropTypes.string.isRequired,
        handleClose: PropTypes.func.isRequired,
        handleSave: PropTypes.func.isRequired,
    };

    state = {
        showDeleteConfirmModal: false,
        name: this.props.initialName,
        status: this.props.initialStatus,
    };

    _handleNameChange = e => {
        this.setState({ name: e.target.value });
    };

    _handleStatusChange = e => {
        this.setState({ status: e.target.value });
    };

    _handleSave = () => {
        this.props.handleSave(this.state.name, this.state.status);
    };

    render() {
        return (
            <Modal show={this.props.show} onHide={this.props.handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{strings.selection}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <FormGroup controlId="name">
                        <ControlLabel>{strings.name}</ControlLabel>
                        <FormControl
                            type="text"
                            value={this.state.name}
                            placeholder={strings.enterText}
                            onChange={this._handleNameChange}
                        />
                    </FormGroup>
                    <FormGroup controlId="formControlsSelect">
                        <ControlLabel>{strings.available}</ControlLabel>
                        <FormControl
                            componentClass="select"
                            value={this.state.status}
                            onChange={this._handleStatusChange}
                        >
                            <option value={menuStatus.available.value}>{strings.yes}</option>
                            <option value={menuStatus.unavailable.value}>{strings.no}</option>
                        </FormControl>
                    </FormGroup>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={this.props.handleClose}>{strings.cancel}</Button>
                    <Button onClick={this._handleSave} bsStyle="primary">
                        {strings.save}
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }
}
