import { Grid, Row, Col, Tabs, Tab } from "react-bootstrap";
import AlertFlag from "../AlertFlag";
import FailedToLoadAlert from "../FailedToLoadAlert";
import ImageSetUploader from "../ImageSetUploader";
import ComboInfoTab from "../ComboInfoTab";
import Loader from "../Loader";
import React, { PureComponent } from "react";
import { StyleSheet, css } from "aphrodite";
import * as combos from "../../lib/api/combos";
import * as imageTypes from "../../enums/imageType";
import PropTypes from "prop-types";
import logger from "../../lib/logger";

const styles = StyleSheet.create({
    tabWrapper: {
        marginTop: 20,
    },
});

const strings = {
    loading: "Loading",
    titleSuffix: "| Habit CMS",
    combo: "Combo",
    info: "Info",
    images: "Images",
    heroImage: "Hero Image",
    heroImage1080: "Kiosk Hero Image",
    isolatedImage: "Isolated Image",
    isolatedImage328: "Kiosk Small Isolated Image",
    isolatedImage424: "Kiosk Large Isolated Image",
    isolatedImage128: "Rebrand Isolated Image 128",
    isolatedImage352: "Rebrand Isolated Image 352",
    isolatedImage1152: "Rebrand Isolated Image 1152",
    modifiers: "Modifiers",
    edit: "Edit",
    missingIsolatedImages: "Missing Isolated Image",
    missingHeroImages: "Missing Hero Image",
};

export default class ComboPage extends PureComponent {
    static propTypes = {
        match: PropTypes.object.isRequired,
    };

    state = {
        combo: null,
        loading: true,
    };

    componentDidMount() {
        this._isMounted = true;
        document.title = `${strings.loading} ${strings.titleSuffix}`;
        combos
            .getCombo(this.props.match.params.comboId)
            .then(resp => {
                if (this._isMounted) {
                    this.setState({ combo: resp, loading: false });
                    document.title = `${resp.name} ${strings.titleSuffix}`;
                }
            })
            .catch(err => {
                logger.warn(err);
                if (this._isMounted) {
                    this.setState({ loading: false });
                }
            });
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    _handleUploadImage = (imageType, image, crop) => {
        return combos
            .addImage(this.state.combo.id, imageType, image, crop)
            .then(images => {
                if (this._isMounted) {
                    this.setState({
                        combo: {
                            ...this.state.combo,
                            isolatedImages:
                                imageType === imageTypes.Isolated
                                    ? images
                                    : this.state.combo.isolatedImages,
                            isolatedImages328:
                                imageType === imageTypes.Isolated328
                                    ? images
                                    : this.state.combo.isolatedImages328,
                            isolatedImages424:
                                imageType === imageTypes.Isolated424
                                    ? images
                                    : this.state.combo.isolatedImages424,
                            isolatedImages128:
                                imageType === imageTypes.Isolated128 
                                    ? images 
                                    : this.state.combo.isolatedImages128,
                            isolatedImages352:
                                imageType === imageTypes.Isolated352
                                    ? images 
                                    : this.state.combo.isolatedImages352,
                            isolatedImages1152:
                                imageType === imageTypes.Isolated1152 
                                    ? images 
                                    : this.state.combo.isolatedImages1152,
                            heroImages:
                                imageType === imageTypes.Hero
                                    ? images
                                    : this.state.combo.heroImages,
                            heroImages1080:
                                imageType === imageTypes.Hero1080
                                    ? images
                                    : this.state.combo.heroImages1080,
                        },
                    });
                }
                return images;
            })
            .catch(err => logger.warn(err));
    };

    _handleSaveInfo = combo => {
        if (this._isMounted) {
            this.setState({ combo });
        }
    };

    render() {
        if (this.state.loading) {
            return <Loader />;
        } else if (!this.state.combo) {
            return <FailedToLoadAlert type={strings.combo} />;
        }
        const combo = this.state.combo;
        return (
            <Grid>
                <Row bsClass="m-t">
                    <Col md={6}>
                        <h4>{combo.name}</h4>
                    </Col>
                    <Col md={6}>
                        {!this.state.combo.isolatedImages ||
                        !this.state.combo.isolatedImages.length ? (
                            <AlertFlag label={strings.missingIsolatedImages} />
                        ) : null}
                        {!this.state.combo.heroImages || !this.state.combo.heroImages.length ? (
                            <AlertFlag label={strings.missingHeroImages} />
                        ) : null}
                    </Col>
                </Row>
                <Row bsClass="m-t">
                    <Col md={12}>
                        <div className={css(styles.tabWrapper)}>
                            <Tabs defaultActiveKey={1} id="uncontrolled-tab-example">
                                <Tab eventKey={1} title={strings.info}>
                                    <ComboInfoTab
                                        key={combo.id}
                                        initialCombo={combo}
                                        onSave={this._handleSaveInfo}
                                    />
                                </Tab>
                                <Tab eventKey={2} title={strings.images}>
                                    <Row>
                                        <Col md={4}>
                                            <ImageSetUploader
                                                label={strings.isolatedImage}
                                                images={combo ? combo.isolatedImages : null}
                                                onSubmit={this._handleUploadImage.bind(
                                                    this,
                                                    imageTypes.Isolated,
                                                )}
                                                minWidth={80}
                                                minHeight={80}
                                            />
                                        </Col>
                                        <Col md={4}>
                                            <ImageSetUploader
                                                label={strings.isolatedImage328}
                                                images={combo ? combo.isolatedImages328 : null}
                                                onSubmit={this._handleUploadImage.bind(
                                                    this,
                                                    imageTypes.Isolated328,
                                                )}
                                                minWidth={328}
                                                minHeight={265}
                                            />
                                        </Col>
                                        <Col md={4}>
                                            <ImageSetUploader
                                                label={strings.isolatedImage424}
                                                images={combo ? combo.isolatedImages424 : null}
                                                onSubmit={this._handleUploadImage.bind(
                                                    this,
                                                    imageTypes.Isolated424,
                                                )}
                                                minWidth={424}
                                                minHeight={392}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={4}>
                                            <ImageSetUploader
                                                label={strings.heroImage}
                                                images={combo ? combo.heroImages : null}
                                                onSubmit={this._handleUploadImage.bind(
                                                    this,
                                                    imageTypes.Hero,
                                                )}
                                                minWidth={414}
                                                minHeight={350}
                                            />
                                        </Col>
                                        <Col md={4}>
                                            <ImageSetUploader
                                                label={strings.heroImage1080}
                                                images={combo ? combo.heroImages1080 : null}
                                                onSubmit={this._handleUploadImage.bind(
                                                    this,
                                                    imageTypes.Hero1080,
                                                )}
                                                minWidth={1080}
                                                minHeight={913}
                                            />
                                        </Col>
                                        
                                    </Row>
                                    <Row>
                                        <Col md={4}>
                                            <ImageSetUploader
                                                label={strings.isolatedImage128}
                                                images={combo ? combo.isolatedImages128 : null}
                                                onSubmit={this._handleUploadImage.bind(
                                                    this,
                                                    imageTypes.Isolated128,
                                                )}
                                                minWidth={128}
                                                minHeight={92}
                                            />
                                        </Col>
                                        <Col md={4}>
                                            <ImageSetUploader
                                                label={strings.isolatedImage352}
                                                images={combo ? combo.isolatedImages352 : null}
                                                onSubmit={this._handleUploadImage.bind(
                                                    this,
                                                    imageTypes.Isolated352,
                                                )}
                                                minWidth={352}
                                                minHeight={253}
                                            />
                                        </Col>
                                        <Col md={4}>
                                            <ImageSetUploader
                                                label={strings.isolatedImage1152}
                                                images={combo ? combo.isolatedImages1152 : null}
                                                onSubmit={this._handleUploadImage.bind(
                                                    this,
                                                    imageTypes.Isolated1152,
                                                )}
                                                minWidth={1152}
                                                minHeight={828}
                                            />
                                        </Col>
                                    </Row>
                                    
                                </Tab>
                            </Tabs>
                        </div>
                    </Col>
                </Row>
            </Grid>
        );
    }
}
