import instance from "./instance";
const responseData = response => response.data;

export function getCombos() {
    return instance
        .request({
            url: `/cms/combos`,
            method: "GET",
        })
        .then(responseData);
}

export function getCombo(comboId) {
    return instance
        .request({
            url: `/cms/combos/${comboId}`,
            method: "GET",
        })
        .then(responseData);
}

export function updateCombo(comboId, name, status, categoryIds) {
    return instance
        .request({
            url: `/cms/combos/${comboId}`,
            method: "POST",
            data: {
                name,
                status,
                categoryIds,
            },
        })
        .then(responseData);
}

export function addImage(comboId, imageType, image, crop) {
    const formData = new FormData();
    formData.append("image", image);
    formData.append("centerX", crop.x);
    formData.append("centerY", crop.y);
    formData.append("width", crop.width);
    formData.append("height", crop.height);
    return instance
        .request({
            url: `/cms/combos/${comboId}/image/${imageType}`,
            method: "POST",
            data: formData,
        })
        .then(responseData);
}

export function getDropdown() {
    return instance
        .request({
            url: `/cms/combos/dropdown`,
            method: "GET",
        })
        .then(responseData);
}
