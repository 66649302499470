import dateFns from "date-fns";
import { Modal, Button, Row, Col } from "react-bootstrap";
import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { StyleSheet, css } from "aphrodite";

const strings = {
    close: "Close",
    email: "Email",
    phone: "Phone Number",
    store: "Store Number",
    submission: "Submission Time",
    feedback: "Guest Feedback",
};

const styles = StyleSheet.create({
    row: {
        marginBottom: 8,
    },
    subHeader: {
        margin: 0,
    },
});

export default class FeedbackModal extends PureComponent {
    static propTypes = {
        show: PropTypes.bool.isRequired,
        feedback: PropTypes.object.isRequired,
        handleClose: PropTypes.func.isRequired,
    };

    _formatDate = dateString => {
        const date = new Date(dateString);
        return dateFns.format(date, "MM-DD-YYYY hh:mma");
    };

    render() {
        if (!this.props.show) {
            return null;
        }
        const { feedback } = this.props;
        return (
            <Modal show={this.props.show} onHide={this.props.handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{feedback.name}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row className={css(styles.row)}>
                        <Col md={6}>
                            <h5 className={css(styles.subHeader)}>{strings.email}</h5>
                            <div className="m-b">{feedback.email}</div>
                        </Col>
                        <Col md={6}>
                            <h5 className={css(styles.subHeader)}>{strings.phone}</h5>
                            <div className="m-b">{feedback.phone}</div>
                        </Col>
                    </Row>
                    <Row className={css(styles.row)}>
                        <Col md={6}>
                            <h5 className={css(styles.subHeader)}>{strings.submission}</h5>
                            <div className="m-b">{this._formatDate(feedback.submitted)}</div>
                        </Col>
                        <Col md={6}>
                            <h5 className={css(styles.subHeader)}>{strings.store}</h5>
                            <div className="m-b">{feedback.storeNumber}</div>
                        </Col>
                    </Row>
                    <Row className={css(styles.row)}>
                        <Col md={12}>
                            <h5 className={css(styles.subHeader)}>{strings.feedback}</h5>
                            <div className="m-b">{feedback.feedback}</div>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={this.props.handleClose}>{strings.close}</Button>
                </Modal.Footer>
            </Modal>
        );
    }
}
