import instance from "./instance";
const responseData = response => response.data;

export function getRegistrationPrompts() {
    return instance
        .request({
            url: `/cms/marketing/registration-prompt`,
            method: "GET",
        })
        .then(responseData);
}

/* registration prompt page format 

For create, all fields are required, for edit, all fields are optional

{
    name: string,
    headerText: string
    completionHeaderText: string,
    paragraphText: string,
    completionText: string,
    alreadyCompletedText: string,
    promoSlug: string,
    isActive: boolean,
    desktopImage: ImageModel,
    tabletImage: ImageModel,
    mobileImage: ImageModel,
}

ImageModel:
{
    image: File,
    centerX: number,
    centerY: number,
    width: number,
    height: number,
}
*/

export function createRegistrationPrompt(page) {
    const formData = new FormData();
    for (const property in page) {
        if (
            property === "desktopImage" ||
            property === "tabletImage" ||
            property === "mobileImage" ||
            property === "logoImage"
        ) {
            continue;
        }
        formData.append(property, page[property]);
    }

    /* desktop image */
    formData.append("desktopImage.image", page.desktopImage.image);
    formData.append("desktopImage[centerX]", page.desktopImage.centerX);
    formData.append("desktopImage[centerY]", page.desktopImage.centerY);
    formData.append("desktopImage[width]", page.desktopImage.width);
    formData.append("desktopImage[height]", page.desktopImage.height);

    /* tablet image */
    formData.append("tabletImage.image", page.tabletImage.image);
    formData.append("tabletImage[centerX]", page.tabletImage.centerX);
    formData.append("tabletImage[centerY]", page.tabletImage.centerY);
    formData.append("tabletImage[width]", page.tabletImage.width);
    formData.append("tabletImage[height]", page.tabletImage.height);

    /* mobile image */
    formData.append("mobileImage.image", page.mobileImage.image);
    formData.append("mobileImage[centerX]", page.mobileImage.centerX);
    formData.append("mobileImage[centerY]", page.mobileImage.centerY);
    formData.append("mobileImage[width]", page.mobileImage.width);
    formData.append("mobileImage[height]", page.mobileImage.height);

    /* logo image */
    if (page.logoImage) {
        formData.append("logoImage.image", page.logoImage.image);
        formData.append("logoImage[centerX]", page.logoImage.centerX);
        formData.append("logoImage[centerY]", page.logoImage.centerY);
        formData.append("logoImage[width]", page.logoImage.width);
        formData.append("logoImage[height]", page.logoImage.height);
    }

    return instance
        .request({
            url: `/cms/marketing/registration-prompt`,
            method: "POST",
            data: formData,
        })
        .then(responseData);
}

export function editRegistrationPrompt(pageId, page) {
    const formData = new FormData();
    for (const property in page) {
        if (
            property === "desktopImage" ||
            property === "tabletImage" ||
            property === "mobileImage" ||
            property === "logoImage"
        ) {
            continue;
        }
        formData.append(property, page[property]);
    }

    if ("desktopImage" in page) {
        formData.append("desktopImage.image", page.desktopImage.image);
        formData.append("desktopImage[centerX]", page.desktopImage.centerX);
        formData.append("desktopImage[centerY]", page.desktopImage.centerY);
        formData.append("desktopImage[width]", page.desktopImage.width);
        formData.append("desktopImage[height]", page.desktopImage.height);
    }

    if ("tabletImage" in page) {
        formData.append("tabletImage.image", page.tabletImage.image);
        formData.append("tabletImage[centerX]", page.tabletImage.centerX);
        formData.append("tabletImage[centerY]", page.tabletImage.centerY);
        formData.append("tabletImage[width]", page.tabletImage.width);
        formData.append("tabletImage[height]", page.tabletImage.height);
    }

    if ("mobileImage" in page) {
        formData.append("mobileImage.image", page.mobileImage.image);
        formData.append("mobileImage[centerX]", page.mobileImage.centerX);
        formData.append("mobileImage[centerY]", page.mobileImage.centerY);
        formData.append("mobileImage[width]", page.mobileImage.width);
        formData.append("mobileImage[height]", page.mobileImage.height);
    }

    if ("logoImage" in page) {
        formData.append("logoImage.image", page.logoImage.image);
        formData.append("logoImage[centerX]", page.logoImage.centerX);
        formData.append("logoImage[centerY]", page.logoImage.centerY);
        formData.append("logoImage[width]", page.logoImage.width);
        formData.append("logoImage[height]", page.logoImage.height);
    }

    return instance
        .request({
            url: `/cms/marketing/registration-prompt/${pageId}`,
            method: "PUT",
            data: formData,
        })
        .then(responseData);
}
