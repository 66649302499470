import instance from "./instance";
import * as purchaseSource from "../../enums/purchaseSource";
const responseData = response => response.data;

export function getOrders(start, end, filter, source, before, after) {
    let url = `/cms/orders?start=${start}&end=${end}`;
    if (filter) {
        url += `&filter=${filter}`;
    }
    if (before) {
        url += `&before=${before}`;
    }
    if (after) {
        url += `&after=${after}`;
    }
    if (source !== purchaseSource.all) {
        url += `&source=${source}`;
    }
    return instance
        .request({
            url,
            method: "GET",
        })
        .then(responseData);
}

export function getGuestOrders(accountId, start, end, filter) {
    let url = `/cms/orders/${accountId}?start=${start}&end=${end}`;
    if (filter) {
        url += `&filter=${filter}`;
    }
    return instance
        .request({
            url,
            method: "GET",
        })
        .then(responseData);
}
