import { Alert, Glyphicon } from "react-bootstrap";
import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { StyleSheet, css } from "aphrodite";

const styles = StyleSheet.create({
    container: {
        marginBottom: 0,
        padding: 6,
    },
    icon: {
        marginRight: 6,
    },
});

export default class SuccessAlert extends PureComponent {
    static propTypes = {
        label: PropTypes.string.isRequired,
    };

    render() {
        return (
            <Alert className={css(styles.container)} bsStyle="success">
                <Glyphicon className={css(styles.icon)} glyph="ok" />
                {this.props.label}
            </Alert>
        );
    }
}
