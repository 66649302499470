import {
    Button,
    Grid,
    Row,
    Col,
    FormGroup,
    FormControl,
    ControlLabel,
    Table,
} from "react-bootstrap";
import Loader from "../Loader";
import React, { PureComponent } from "react";
import * as customers from "../../lib/api/customers";
import EditCustomerInfoModal from "../EditCustomerInfoModal";

const strings = {
    title: "Customer Account Search",
    search: "Search",
    name: "Name",
    email: "Email",
    phone: "Phone #",
    status: "Status",
    edit: "Edit",
    enterText: "Email",
    full: "full account",
    shadow: "shadow account",
};

const AccountType = {
    full: 1,
    shadow: 2,
};

export default class CustomerAccountsPage extends PureComponent {
    state = {
        loading: true,
        fullAccounts: [],
        shadowAccounts: [],
        total: 0,
        filter: "",
        editingCustomer: undefined,
        editingCustomerType: 0,
    };

    componentDidMount() {
        this._isMounted = true;
        document.title = strings.title;
        this._loadCustomerInfo();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    _onFilterChange = e => {
        this.setState({ filter: e.target.value }, this._loadCustomerInfo);
    };

    _loadCustomerInfo = () => {
        if (!this.state.loading) {
            this.setState({ loading: true });
        }
        if (this.state.filter === "") {
            this.setState({ loading: false });
            return;
        }
        customers.getCustomers(this.state.filter).then(responseData => {
            this.setState({
                fullAccounts: responseData.fullAccounts,
                shadowAccounts: responseData.shadowAccounts,
                loading: false,
            });
        });
    };

    _openEditCustomerModal = (c, accountType) => {
        this.setState({
            editingCustomer: c,
            editingCustomerType: accountType,
        });
    };

    _handleCloseEditCustomerModal = () => {
        this.setState({ editingCustomer: undefined });
    };

    _renderTableRows = () => {
        return (
            <tbody>
                {this.state.fullAccounts.map(c => (
                    <tr key={c.id}>
                        <td>{`${c.firstName} ${c.lastName}`}</td>
                        <td>{c.email}</td>
                        <td>{c.phoneNumber}</td>
                        <td>full account</td>
                        <td>
                            {/* eslint-disable-next-line react/jsx-no-bind*/}
                            <Button
                                onClick={() => this._openEditCustomerModal(c, AccountType.full)}
                            >
                                Edit
                            </Button>
                        </td>
                    </tr>
                ))}
                {this.state.shadowAccounts.map(c => (
                    <tr key={c.id}>
                        <td>{`${c.firstName} ${c.lastName}`}</td>
                        <td>{c.email}</td>
                        <td>{c.phoneNumber}</td>
                        <td>shadow account</td>
                        <td>
                            {/* eslint-disable-next-line react/jsx-no-bind*/}
                            <Button
                                onClick={() => this._openEditCustomerModal(c, AccountType.shadow)}
                            >
                                Edit
                            </Button>
                        </td>
                    </tr>
                ))}
            </tbody>
        );
    };

    render() {
        return (
            <Grid>
                <Row>
                    <h3>{strings.title}</h3>
                </Row>
                <Row>
                    <Col md={4}>
                        <FormGroup controlId="filter">
                            <ControlLabel>{strings.search}</ControlLabel>
                            <FormControl
                                type="text"
                                value={this.state.filter}
                                placeholder={strings.enterText}
                                onChange={this._onFilterChange}
                            />
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col md={12}>
                        <Table striped bordered hover>
                            <thead>
                                <tr>
                                    <th>{strings.name}</th>
                                    <th>{strings.email}</th>
                                    <th>{strings.phone}</th>
                                    <th>{strings.status}</th>
                                    <th>{strings.edit}</th>
                                </tr>
                            </thead>
                            {this.state.loading ? null : this._renderTableRows()}
                        </Table>
                        {this.state.loading ? <Loader /> : null}
                    </Col>
                </Row>
                {this.state.editingCustomer ? (
                    <EditCustomerInfoModal
                        customer={this.state.editingCustomer}
                        customerType={this.state.editingCustomerType}
                        show={!!this.state.editingCustomer}
                        handleClose={this._handleCloseEditCustomerModal}
                        // eslint-disable-next-line react/jsx-no-bind
                        onSaveCallback={() => {
                            this._loadCustomerInfo();
                            this._handleCloseEditCustomerModal();
                        }}
                    />
                ) : null}
            </Grid>
        );
    }
}
