import instance from "./instance";
const responseData = response => response.data;

export function getContests() {
    return instance
        .request({
            url: "/cms/marketing/contest",
            method: "GET",
        })
        .then(responseData);
}

export function createContest(contest) {
    return instance
        .request({
            url: "/cms/marketing/contest",
            method: "POST",
            data: contest,
        })
        .then(responseData);
}

export function editContest(contestId, contest) {
    return instance
        .request({
            url: `/cms/marketing/contest/${contestId}`,
            method: "PUT",
            data: contest,
        })
        .then(responseData);
}
