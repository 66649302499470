import { Modal, Button } from "react-bootstrap";
import React, { PureComponent } from "react";
import PropTypes from "prop-types";

const strings = {
    question: "Question",
    cancel: "Cancel",
    delete: "Delete",
    save: "Save",
};

export default class DeleteConfirmModal extends PureComponent {
    static propTypes = {
        show: PropTypes.bool.isRequired,
        title: PropTypes.string,
        handleClose: PropTypes.func.isRequired,
        handleConfirm: PropTypes.func.isRequired,
    };

    render() {
        return (
            <Modal show={this.props.show} onHide={this.props.handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{this.props.title}</Modal.Title>
                </Modal.Header>
                <Modal.Footer>
                    <Button onClick={this.props.handleClose}>{strings.cancel}</Button>
                    <Button onClick={this.props.handleConfirm} bsStyle="danger">
                        {strings.delete}
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }
}
