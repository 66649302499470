import { Button, Grid, Row, Col, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import React, { PureComponent } from "react";
import * as habitPropTypes from "../habitPropTypes";
import * as featured from "../lib/api/featured";
import PropTypes from "prop-types";
import Select from "react-select";
import { StyleSheet, css } from "aphrodite";
import logger from "../lib/logger";

const styles = StyleSheet.create({
    marginTop: {
        marginTop: 20,
    },
});

const strings = {
    add: "Add",
    remove: "Remove",
    name: "Name",
    storesWith: "Stores w/ Item",
    storesDisplaying: "Stores displaying this feature",
    sort: "Sort",
};

export default class FeaturedProductTab extends PureComponent {
    static propTypes = {
        onAdd: PropTypes.func.isRequired,
        onRemove: PropTypes.func.isRequired,
        onReorder: PropTypes.func.isRequired,
        featuredProducts: PropTypes.arrayOf(habitPropTypes.featuredProductPartial).isRequired,
    };

    componentDidMount() {
        this._isMounted = true;
        featured
            .getProducts()
            .then(resp => {
                if (this._isMounted) {
                    this.setState({ featurableProducts: resp.products, loading: false });
                }
            })
            .catch(err => logger.warn(err));
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    state = {
        selectedFeaturableProduct: null,
        featurableProducts: [],
    };

    _onFeaturableProductSelected = value => {
        this.setState({ selectedFeaturableProduct: value });
    };

    _onAddClicked = () => {
        if (!this.state.selectedFeaturableProduct) {
            return;
        }
        this.props.onAdd(this.state.selectedFeaturableProduct.value).then(() => {
            if (this._isMounted) {
                this.setState({ selectedFeaturableProduct: null });
            }
        });
    };

    render() {
        return (
            <Grid>
                <Row className={css(styles.marginTop)} bsClass="m-t">
                    <Col mdOffset={6} md={3}>
                        <Select
                            onChange={this._onFeaturableProductSelected}
                            options={this.state.featurableProducts
                                .filter(fp =>
                                    this.props.featuredProducts.reduce(
                                        (prev, p) => p.id !== fp.id && prev,
                                        true,
                                    ),
                                )
                                .map(fp => ({
                                    value: fp.id,
                                    label: fp.name,
                                }))}
                            value={this.state.selectedFeaturableProduct}
                        />
                    </Col>
                    <Col md={3}>
                        <Button bsStyle="primary" onClick={this._onAddClicked}>
                            {strings.add}
                        </Button>
                    </Col>
                </Row>
                <Row bsClass="m-t">
                    <Col className={css(styles.marginTop)} md={12}>
                        <Table striped bordered hover>
                            <thead>
                                <tr>
                                    <th>{strings.name}</th>
                                    <th>{strings.sort}</th>
                                    <th>{strings.remove}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.props.featuredProducts.map((fp, i) => (
                                    <tr key={fp.id}>
                                        <td>
                                            <Link to={`/featured/${fp.id}`}>{fp.name}</Link>
                                        </td>
                                        <td>
                                            {
                                                <div>
                                                    {i === 0 ? null : (
                                                        <Button
                                                            onClick={() =>
                                                                this.props.onReorder(fp, i - 1)
                                                            }
                                                        >
                                                            &#9650;
                                                        </Button>
                                                    )}
                                                    {i ===
                                                    this.props.featuredProducts.length -
                                                        1 ? null : (
                                                        <Button
                                                            onClick={() =>
                                                                this.props.onReorder(fp, i + 1)
                                                            }
                                                        >
                                                            &#9660;
                                                        </Button>
                                                    )}
                                                </div>
                                            }
                                        </td>
                                        <td>
                                            <Button
                                                bsStyle="primary"
                                                onClick={() => this.props.onRemove(fp.id)}
                                            >
                                                {strings.remove}
                                            </Button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            </Grid>
        );
    }
}
