import { Grid, Row, Col, FormGroup, FormControl, ControlLabel, Table } from "react-bootstrap";
import LinkButton from "../LinkButton";
import Loader from "../Loader";
import React, { PureComponent } from "react";
import * as menuStatus from "../../enums/menuStatus";
import * as modifiers from "../../lib/api/modifiers";
import logger from "../../lib/logger";

const strings = {
    title: "Modifiers | Habit CMS",
    modifiers: "Modifiers",
    filterByName: "Filter by Name:",
    filterByStatus: "Filter by Status:",
    enterText: "Enter text",
    name: "Name",
    category: "Category",
    status: "Status",
    available: "Available",
    unavailable: "Unavailable",
    edit: "Edit",
    items: "Items",
    selections: "Selections",
};

export default class ModifiersPage extends PureComponent {
    state = {
        filter: "",
        loading: true,
        modifiers: [],
        status: menuStatus.all.value,
    };

    componentDidMount() {
        this._isMounted = true;
        document.title = `${strings.title}`;
        modifiers
            .getModifiers()
            .then(resp => {
                if (this._isMounted) {
                    this.setState({ modifiers: resp.modifiers, loading: false });
                }
            })
            .catch(err => {
                logger.warn(err);
                if (this._isMounted) {
                    this.setState({ loading: false });
                }
            });
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    _onFilterChange = e => {
        this.setState({ filter: e.target.value });
    };

    _onStatusChange = e => {
        this.setState({ status: e.target.value });
    };

    render() {
        if (this.state.loading) {
            return <Loader />;
        }
        const lowerFilter = this.state.filter.toLowerCase();
        const currentModifiers = this.state.modifiers.filter(m => {
            return m.name.toLowerCase().indexOf(lowerFilter) !== -1;
        });
        return (
            <Grid>
                <Row>
                    <Col md={6}>
                        <h3>{strings.modifiers}</h3>
                    </Col>
                </Row>
                <Row>
                    <Col md={4}>
                        <FormGroup controlId="filter">
                            <ControlLabel>{strings.filterByName}</ControlLabel>
                            <FormControl
                                type="text"
                                value={this.state.filter}
                                placeholder={strings.enterText}
                                onChange={this._onFilterChange}
                            />
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col md={12}>
                        <Table striped bordered hover>
                            <thead>
                                <tr>
                                    <th>{strings.name}</th>
                                    <th>{strings.status}</th>
                                    <th>{strings.items}</th>
                                    <th>{strings.selections}</th>
                                    <th>{strings.edit}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {currentModifiers.map(m => (
                                    <tr key={m.id}>
                                        <td>{m.name}</td>
                                        <td>
                                            {m.status === menuStatus.available.value
                                                ? menuStatus.available.label
                                                : menuStatus.unavailable.label}
                                        </td>
                                        <td>{m.itemCount}</td>
                                        <td>{m.selectionCount}</td>
                                        <td>
                                            <LinkButton bsStyle="primary" to={`/modifier/${m.id}`}>
                                                {strings.edit}
                                            </LinkButton>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            </Grid>
        );
    }
}
