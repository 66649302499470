import { Button, Grid, Row, Col, Table } from "react-bootstrap";
import Loader from "../Loader";
import ImagePreview from "../ImagePreview";
import TileModal from "../TileModal";
import React, { PureComponent } from "react";
import * as tilesApi from "../../lib/api/tiles";
import * as tileType from "../../enums/tileType";
import logger from "../../lib/logger";

const strings = {
    title: "Home Tiles | Habit CMS",
    homeTiles: "Home Screen Tiles",
    add: "Add",
    image: "Image",
    itemOrUrl: "Item/Url",
    descOrPrompt: "Description/Prompt",
    sort: "Sort",
    edit: "Edit",
    active: "Active?",
    yes: "yes",
    no: "no",
};

export default class TilesPage extends PureComponent {
    state = {
        showTileModal: false,
        currentTile: null,
        loading: true,
        tiles: [],
    };

    componentDidMount() {
        this._isMounted = true;
        document.title = strings.title;
        tilesApi
            .getTiles()
            .then(resp => {
                if (this._isMounted) {
                    this.setState({ tiles: resp.tiles, loading: false });
                }
            })
            .catch(err => {
                logger.warn(err);
                if (this._isMounted) {
                    this.setState({ loading: false });
                }
            });
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    _openTileModal = currentTile => {
        this.setState({ currentTile, showTileModal: true });
    };

    _openNewTileModal = () => {
        this.setState({ showTileModal: true });
    };

    _closeTileModal = () => {
        this.setState({ currentTile: null, showTileModal: false });
    };

    _saveTile = newTile => {
        let promise = null;
        if (this.state.currentTile) {
            promise = tilesApi.updateTile(
                this.state.currentTile.id,
                newTile.type,
                newTile.image,
                newTile.crop,
                newTile.productId,
                newTile.url,
                newTile.description,
                newTile.prompt,
                newTile.isActive,
                newTile.nearbyMiles,
            );
        } else {
            promise = tilesApi.newTile(
                newTile.type,
                newTile.image,
                newTile.crop,
                newTile.productId,
                newTile.url,
                newTile.description,
                newTile.prompt,
                newTile.isActive,
                newTile.nearbyMiles,
            );
        }
        return promise
            .then(resp => {
                if (this._isMounted) {
                    let tiles = this.state.tiles;
                    if (this.state.currentTile) {
                        tiles = tiles.map(t => {
                            if (t.id === resp.id) {
                                return resp;
                            }
                            return t;
                        });
                    } else {
                        tiles = [...tiles, resp];
                    }
                    this.setState({
                        tiles,
                        showTileModal: false,
                        currentTile: null,
                    });
                }
            })
            .catch(err => logger.warn(err));
    };

    _reorderTile = (tile, newIndex) => {
        tilesApi
            .reorder(tile.id, newIndex)
            .then(() => {
                if (this._isMounted) {
                    const newTiles = this.state.tiles.filter(t => t.id !== tile.id);
                    newTiles.splice(newIndex, 0, tile);
                    this.setState({
                        tiles: newTiles,
                    });
                }
            })
            .catch(e => {
                logger.warn(e);
            });
    };

    _deleteCurrentTile = () => {
        if (!this.state.currentTile) {
            return;
        }
        tilesApi
            .deleteTile(this.state.currentTile.id)
            .then(() => {
                if (this._isMounted) {
                    const newTiles = this.state.tiles.filter(
                        t => t.id !== this.state.currentTile.id,
                    );
                    this.setState({
                        tiles: newTiles,
                        showTileModal: false,
                        currentTile: false,
                    });
                }
            })
            .catch(e => logger.warn(e));
    };

    _renderUrlTileRow = (t, i) => {
        return (
            <tr key={t.id}>
                <td>
                    <ImagePreview height={70} images={t.data.images} />
                </td>
                <td>
                    {
                        <Button bsStyle="link" href={t.data.url}>
                            {t.data.url}
                        </Button>
                    }
                </td>
                <td>{`${t.data.description}${t.data.prompt ? ` / ${t.data.prompt}` : ""}`}</td>
                <td>{t.isActive ? strings.yes : strings.no}</td>
                <td>
                    {i === 0 ? null : (
                        <Button onClick={() => this._reorderTile(t, i - 1)}>&#9650;</Button>
                    )}
                    {i === this.state.tiles.length - 1 ? null : (
                        <Button onClick={() => this._reorderTile(t, i + 1)}>&#9660;</Button>
                    )}
                </td>
                <td>
                    <Button bsStyle="primary" onClick={() => this._openTileModal(t)}>
                        {strings.edit}
                    </Button>
                </td>
            </tr>
        );
    };

    _renderProductTileRow = (t, i) => {
        return (
            <tr key={t.id}>
                <td>
                    <ImagePreview height={70} images={t.data.images} />
                </td>
                <td>{t.data.name}</td>
                <td>{`${t.data.description}${t.data.prompt ? ` / ${t.data.prompt}` : ""}`}</td>
                <td>{t.isActive ? strings.yes : strings.no}</td>
                <td>
                    {i === 0 ? null : (
                        <Button onClick={() => this._reorderTile(t, i - 1)}>&#9650;</Button>
                    )}
                    {i === this.state.tiles.length - 1 ? null : (
                        <Button onClick={() => this._reorderTile(t, i + 1)}>&#9660;</Button>
                    )}
                </td>
                <td>
                    <Button bsStyle="primary" onClick={() => this._openTileModal(t)}>
                        {strings.edit}
                    </Button>
                </td>
            </tr>
        );
    };

    render() {
        if (this.state.loading) {
            return <Loader />;
        }
        return (
            <Grid>
                <Row>
                    <Col md={9}>
                        <h3>{strings.homeTiles}</h3>
                    </Col>
                    <Col md={3}>
                        <Button bsStyle="primary" onClick={this._openNewTileModal}>
                            {strings.add}
                        </Button>
                    </Col>
                </Row>
                <Row>
                    <Col md={12}>
                        <Table striped bordered hover>
                            <thead>
                                <tr>
                                    <th>{strings.image}</th>
                                    <th>{strings.itemOrUrl}</th>
                                    <th>{strings.descOrPrompt}</th>
                                    <th>{strings.active}</th>
                                    <th>{strings.sort}</th>
                                    <th>{strings.edit}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.tiles.map((t, i) => {
                                    if (t.type === tileType.url.value) {
                                        return this._renderUrlTileRow(t, i);
                                    } else {
                                        return this._renderProductTileRow(t, i);
                                    }
                                })}
                            </tbody>
                        </Table>
                    </Col>
                </Row>
                <TileModal
                    key={this.state.currentTile ? this.state.currentTile.id : "new"}
                    show={this.state.showTileModal}
                    tile={this.state.currentTile}
                    handleClose={this._closeTileModal}
                    handleSave={this._saveTile}
                    handleDelete={this._deleteCurrentTile}
                />
            </Grid>
        );
    }
}
