import dateFns from "date-fns";
import FeedbackRow from "./FeedbackRow";
import PropTypes from "prop-types";
import React, { PureComponent } from "react";
import { Table } from "react-bootstrap";

const strings = {
    name: "Guest Name",
    email: "Email",
    phone: "Phone",
    submitted: "Submission Time",
    store: "Store",
    feedback: "Feedback",
};

export default class FeedbackTable extends PureComponent {
    static propTypes = {
        feedback: PropTypes.array.isRequired,
        setFeedbackModal: PropTypes.func.isRequired,
    };

    _formatDate = dateString => {
        const date = new Date(dateString);
        return dateFns.format(date, "MM-DD-YYYY hh:mma");
    };

    _renderFeedbacks = () =>
        this.props.feedback.map(f => (
            <FeedbackRow key={f.id} feedback={f} setFeedbackModal={this.props.setFeedbackModal} />
        ));

    render() {
        return (
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>{strings.name}</th>
                        <th>{strings.email}</th>
                        <th>{strings.phone}</th>
                        <th>{strings.submitted}</th>
                        <th>{strings.store}</th>
                        <th>{strings.feedback}</th>
                    </tr>
                </thead>
                <tbody>{this._renderFeedbacks()}</tbody>
            </Table>
        );
    }
}
