import { Button, Grid, Row, Col, FormGroup, FormControl, ControlLabel } from "react-bootstrap";
import React, { PureComponent } from "react";
import { StyleSheet, css } from "aphrodite";
import PropTypes from "prop-types";
import * as habitPropTypes from "../habitPropTypes";
import * as featured from "../lib/api/featured";
import AsyncSelect from "react-select/lib/Async";

const styles = StyleSheet.create({
    container: {
        marginTop: 20,
    },
    saveButton: {
        width: "100%",
        marginBottom: 20,
    },
});

const strings = {
    name: "Name",
    displayText: "Display Text (25 characters)",
    product: "Product",
    save: "Save",
    yes: "Yes",
    no: "No",
};

export default class FeaturedProductGeneralTab extends PureComponent {
    static propTypes = {
        isNew: PropTypes.bool.isRequired,
        initialFeaturedProduct: habitPropTypes.featuredProduct.isRequired,
        onSave: PropTypes.func.isRequired,
    };

    state = {
        name: this.props.initialFeaturedProduct.name,
        displayText: this.props.initialFeaturedProduct.displayText,
        product: this.props.initialFeaturedProduct,
        searchTerm: "",
        selectedProduct: {
            productName: this.props.initialFeaturedProduct.productName,
            productId: this.props.initialFeaturedProduct.productId,
        },
    };

    static defaultProps = {
        isNew: false,
    };

    _onSave = () => {
        const saveCallback = this.props.isNew ? featured.addProduct : featured.updateProduct;
        saveCallback(
            this.state.name,
            this.state.selectedProduct.productId,
            this.state.selectedProduct.productType,
            this.props.initialFeaturedProduct.id,
        ).then(fp => this.props.onSave(fp));
    };

    _handleNameChange = e => {
        this.setState({ name: e.target.value });
    };

    _handleDisplayTextChange = e => {
        this.setState({ dispalyText: e.target.value });
    };

    _loadProductOptions = searchTerm => {
        return featured.getFeaturableProducts(searchTerm).then(products => {
            return products.map(p => ({
                ...p,
                value: p.productId,
                label: p.productName,
            }));
        });
    };

    _handleSelectInputChange = searchTerm => {
        this.setState({ searchTerm });
    };

    _handleProductChange = product => {
        this.setState({
            selectedProduct: product,
        });
    };

    render() {
        return (
            <Grid className={css(styles.container)}>
                <Row>
                    <Col md={2}>
                        <Button
                            className={css(styles.saveButton)}
                            bsStyle="primary"
                            onClick={this._onSave}
                        >
                            {strings.save}
                        </Button>
                    </Col>
                </Row>
                <Row>
                    <Col md={6}>
                        <FormGroup controlId="name">
                            <ControlLabel>{strings.name}</ControlLabel>
                            <FormControl
                                type="text"
                                value={this.state.name}
                                placeholder={strings.enterText}
                                onChange={this._handleNameChange}
                            />
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col md={6}>
                        <FormGroup controlId="displayText">
                            <ControlLabel>{strings.displayText}</ControlLabel>
                            <FormControl
                                componentClass="textarea"
                                value={this.state.dispalyText}
                                placeholder={strings.enterText}
                                onChange={this._handleDisplayTextChange}
                            />
                        </FormGroup>
                    </Col>
                    <Col md={6}>
                        <FormGroup controlId="name">
                            <ControlLabel>{strings.product}</ControlLabel>
                            <AsyncSelect
                                cacheOptions
                                loadOptions={this._loadProductOptions}
                                defaultOptions
                                onInputChange={this._handleSelectInputChange}
                                onChange={this._handleProductChange}
                                value={{
                                    label: this.state.selectedProduct.productName,
                                    value: this.state.selectedProduct.productId,
                                }}
                            />
                        </FormGroup>
                    </Col>
                </Row>
            </Grid>
        );
    }
}
