export const required = {
    label: "Required",
    value: "required_choice",
};
export const ingredient = {
    label: "Ingredient",
    value: "ingredient",
};
export const addon = {
    label: "Addon",
    value: "optional_choice",
};
