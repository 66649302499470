import instance from "./instance";
const responseData = response => response.data;

export function getNROs() {
    return instance.request({ url: "/cms/marketing/nro", method: "GET" }).then(responseData);
}

export function getNROStores() {
    return instance.request({ url: "/cms/marketing/nro/stores", method: "GET" }).then(responseData);
}

/* nro format 

For create, all fields are required, for edit, all fields are optional

{
    name: string,
    storeId: string,
    headerText: string,
    registerParagraphText: string,
    loginParagraphText: string,
    loggedInParagraphText: string,
    completionText: string,
    slug: string,
    isActive: boolean,
    desktopImage: ImageModel,
    tabletImage: ImageModel,
    mobileImage: ImageModel,
}

ImageModel:
{
    image: File,
    centerX: number,
    centerY: number,
    width: number,
    height: number,
}
*/

export function createNRO(nro) {
    const formData = new FormData();
    for (const property in nro) {
        if (
            property === "desktopImage" ||
            property === "tabletImage" ||
            property === "mobileImage"
        ) {
            continue;
        }
        formData.append(property, nro[property]);
    }

    /* desktop image */
    formData.append("desktopImage.image", nro.desktopImage.image);
    formData.append("desktopImage[centerX]", nro.desktopImage.centerX);
    formData.append("desktopImage[centerY]", nro.desktopImage.centerY);
    formData.append("desktopImage[width]", nro.desktopImage.width);
    formData.append("desktopImage[height]", nro.desktopImage.height);

    /* tablet image */
    formData.append("tabletImage.image", nro.tabletImage.image);
    formData.append("tabletImage[centerX]", nro.tabletImage.centerX);
    formData.append("tabletImage[centerY]", nro.tabletImage.centerY);
    formData.append("tabletImage[width]", nro.tabletImage.width);
    formData.append("tabletImage[height]", nro.tabletImage.height);

    /* mobile image */
    formData.append("mobileImage.image", nro.mobileImage.image);
    formData.append("mobileImage[centerX]", nro.mobileImage.centerX);
    formData.append("mobileImage[centerY]", nro.mobileImage.centerY);
    formData.append("mobileImage[width]", nro.mobileImage.width);
    formData.append("mobileImage[height]", nro.mobileImage.height);

    return instance
        .request({
            url: `/cms/marketing/nro`,
            method: "POST",
            data: formData,
        })
        .then(responseData);
}

export function editNRO(nroId, nro) {
    const formData = new FormData();
    for (const property in nro) {
        if (
            property === "desktopImage" ||
            property === "tabletImage" ||
            property === "mobileImage"
        ) {
            continue;
        }
        formData.append(property, nro[property]);
    }

    if ("desktopImage" in nro) {
        formData.append("desktopImage.image", nro.desktopImage.image);
        formData.append("desktopImage[centerX]", nro.desktopImage.centerX);
        formData.append("desktopImage[centerY]", nro.desktopImage.centerY);
        formData.append("desktopImage[width]", nro.desktopImage.width);
        formData.append("desktopImage[height]", nro.desktopImage.height);
    }

    if ("tabletImage" in nro) {
        formData.append("tabletImage.image", nro.tabletImage.image);
        formData.append("tabletImage[centerX]", nro.tabletImage.centerX);
        formData.append("tabletImage[centerY]", nro.tabletImage.centerY);
        formData.append("tabletImage[width]", nro.tabletImage.width);
        formData.append("tabletImage[height]", nro.tabletImage.height);
    }

    if ("mobileImage" in nro) {
        formData.append("mobileImage.image", nro.mobileImage.image);
        formData.append("mobileImage[centerX]", nro.mobileImage.centerX);
        formData.append("mobileImage[centerY]", nro.mobileImage.centerY);
        formData.append("mobileImage[width]", nro.mobileImage.width);
        formData.append("mobileImage[height]", nro.mobileImage.height);
    }

    return instance
        .request({
            url: `/cms/marketing/nro/${nroId}`,
            method: "PUT",
            data: formData,
        })
        .then(responseData);
}
