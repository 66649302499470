import { Grid, Row, Col, Tabs, Tab } from "react-bootstrap";
import FailedToLoadAlert from "../FailedToLoadAlert";
import ImageSetUploader from "../ImageSetUploader";
import FeaturedProductGeneralTab from "../FeaturedProductGeneralTab";
import Loader from "../Loader";
import LinkButton from "../LinkButton";
import React, { PureComponent } from "react";
import { StyleSheet, css } from "aphrodite";
import * as featured from "../../lib/api/featured";
import * as imageTypes from "../../enums/imageType";
import PropTypes from "prop-types";
import logger from "../../lib/logger";

const styles = StyleSheet.create({
    alertFlag: {
        display: "inline-block",
        marginRight: 10,
    },
    tabWrapper: {
        marginTop: 20,
    },
});

const strings = {
    loading: "Loading",
    titleSuffix: "| Habit CMS",
    featuredProduct: "Featured Product",
    general: "General",
    images: "Images",
    heroImage: "Hero Image",
    heroImage1080: "Kiosk Hero Image",
    inEnvironmentImage720: "Rebrand Featured Image (Mobile)",
    inEnvironmentImage1104: "Rebrand Featured Image (Kiosk)",
    modifiers: "Modifiers",
    edit: "Edit",
    stores: "Stores",
    categories: "Categories",
};

export default class FeaturedProductPage extends PureComponent {
    static propTypes = {
        history: PropTypes.object.isRequired,
        match: PropTypes.object.isRequired,
    };

    state = {
        product: null,
        loading: true,
    };

    componentDidMount() {
        this._isMounted = true;
        document.title = `${strings.loading} ${strings.titleSuffix}`;
        if (this.props.match.params.featuredProductId === "new") {
            this.setState({ product: {}, loading: false });
        } else {
            featured
                .getProduct(this.props.match.params.featuredProductId)
                .then(resp => {
                    if (this._isMounted) {
                        this.setState({ product: resp, loading: false });
                        document.title = `${resp.name} ${strings.titleSuffix}`;
                    }
                })
                .catch(err => {
                    logger.warn(err);
                    if (this._isMounted) {
                        this.setState({
                            loading: false,
                        });
                    }
                });
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    _handleUploadImage = (imageType, image, crop) => {
        return featured
            .addImage(this.state.product.id, image, crop, imageType)
            .then(images => {
                if (this._isMounted) {
                    this.setState({
                        product: {
                            ...this.state.product,
                            images:
                                imageType === imageTypes.Hero ? images : this.state.product.images,
                            images1080:
                                imageType === imageTypes.Hero1080
                                    ? images
                                    : this.state.product.images1080,
                            images720: 
                                imageType === imageTypes.InEnvironment720
                                    ? images
                                    : this.state.product.images720,
                            images1104: 
                                imageType === imageTypes.InEnvironment1104 
                                    ? images
                                    : this.state.product.images1104,
                        },
                    });
                }
                return images;
            })
            .catch(err => logger.warn(err));
    };

    _handleSaveInfo = product => {
        if (this._isMounted) {
            if (this.props.match.params.featuredProductId === "new") {
                this.props.history.replace(`/featured/${product.id}`);
            }
            this.setState({ product });
        }
    };

    render() {
        if (this.state.loading) {
            return <Loader />;
        } else if (!this.state.product) {
            return <FailedToLoadAlert type={strings.featuredProduct} />;
        }
        const product = this.state.product;
        return (
            <Grid>
                <Row>
                    <LinkButton bsStyle="link" to="/featured">
                        &#9664; Back
                    </LinkButton>
                </Row>
                <Row bsClass="m-t">
                    <Col md={12}>
                        <h4>{product.name}</h4>
                    </Col>
                </Row>
                <Row bsClass="m-t">
                    <Col md={12}>
                        <div className={css(styles.tabWrapper)}>
                            <Tabs defaultActiveKey={1} id="uncontrolled-tab-example">
                                <Tab eventKey={1} title={strings.general}>
                                    <FeaturedProductGeneralTab
                                        key={product.id}
                                        initialFeaturedProduct={product}
                                        onSave={this._handleSaveInfo}
                                        isNew={this.props.match.params.featuredProductId === "new"}
                                    />
                                </Tab>
                                <Tab eventKey={2} title={strings.images}>
                                    <Col md={4}>
                                        <ImageSetUploader
                                            label={strings.heroImage}
                                            images={product ? product.images : null}
                                            onSubmit={this._handleUploadImage.bind(
                                                this,
                                                imageTypes.Hero,
                                            )}
                                            minWidth={414}
                                            minHeight={350}
                                        />
                                    </Col>
                                    <Col md={4}>
                                        <ImageSetUploader
                                            label={strings.heroImage1080}
                                            images={product ? product.images1080 : null}
                                            onSubmit={this._handleUploadImage.bind(
                                                this,
                                                imageTypes.Hero1080,
                                            )}
                                            minWidth={1080}
                                            minHeight={913}
                                        />
                                    </Col>
                                    <Col md={4}>
                                        <ImageSetUploader
                                            label={strings.inEnvironmentImage720}
                                            images={product ? product.images720 : null}
                                            onSubmit={this._handleUploadImage.bind(
                                                this,
                                                imageTypes.InEnvironment720,
                                            )}
                                            minWidth={720}
                                            minHeight={368}
                                        />
                                    </Col>
                                    <Col md={4}>
                                        <ImageSetUploader
                                            label={strings.inEnvironmentImage1104}
                                            images={product ? product.images1104 : null}
                                            onSubmit={this._handleUploadImage.bind(
                                                this,
                                                imageTypes.InEnvironment1104,
                                            )}
                                            minWidth={1104}
                                            minHeight={816}
                                        />
                                    </Col>
                                </Tab>
                                {/* <Tab eventKey={3} title={strings.stores}>
									TODO
								</Tab>
								<Tab eventKey={4} title={strings.categories}>
									TODO
								</Tab> */}
                            </Tabs>
                        </div>
                    </Col>
                </Row>
            </Grid>
        );
    }
}
