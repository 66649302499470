import { Modal, Button, FormGroup, ControlLabel, FormControl } from "react-bootstrap";
import React, { PureComponent } from "react";
import PropTypes from "prop-types";

const strings = {
    modalTitle: "Add Re-Number",
    save: "Save",
    oldNumberLabel: "Old Store Number",
    newNumberLabel: "New Store Number",
    noOption: "Select a Store Number",
};

const noOptionValue = -1;

export default class AddRenumberModal extends PureComponent {
    static propTypes = {
        show: PropTypes.bool.isRequired,
        oldNumbers: PropTypes.arrayOf(PropTypes.number).isRequired,
        newNumbers: PropTypes.arrayOf(PropTypes.number).isRequired,
        handleClose: PropTypes.func.isRequired,
        handleSave: PropTypes.func.isRequired,
    };

    state = {
        oldNumber: noOptionValue,
        newNumber: noOptionValue,
    };

    _onSave = () => {
        this.props.handleSave(this.state.oldNumber, this.state.newNumber);
    };

    _onOldNumberChange = e => {
        this.setState({ oldNumber: e.target.value });
    };

    _onNewNumberChange = e => {
        this.setState({ newNumber: e.target.value });
    };

    render() {
        return (
            <Modal show={this.props.show} onHide={this.props.handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{strings.modalTitle}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <FormGroup>
                        <ControlLabel>{strings.oldNumberLabel}</ControlLabel>
                        <FormControl
                            componentClass="select"
                            value={this.state.oldNumber}
                            onChange={this._onOldNumberChange}
                        >
                            <option value={noOptionValue}>{strings.noOption}</option>
                            {this.props.oldNumbers.map(oldNumber => (
                                <option key={oldNumber} value={oldNumber}>
                                    {oldNumber.toString().padStart(4, "0")}
                                </option>
                            ))}
                        </FormControl>
                    </FormGroup>
                    <FormGroup>
                        <ControlLabel>{strings.newNumberLabel}</ControlLabel>
                        <FormControl
                            componentClass="select"
                            value={this.state.newNumber}
                            onChange={this._onNewNumberChange}
                        >
                            <option value={noOptionValue}>{strings.noOption}</option>
                            {this.props.newNumbers.map(newNumber => (
                                <option key={newNumber} value={newNumber}>
                                    {newNumber.toString().padStart(4, "0")}
                                </option>
                            ))}
                        </FormControl>
                    </FormGroup>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        onClick={this._onSave}
                        bsStyle="primary"
                        disabled={
                            this.state.oldNumber === noOptionValue ||
                            this.state.newNumber === noOptionValue ||
                            this.state.oldNumber === this.state.newNumber
                        }
                    >
                        {strings.save}
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }
}
