import "react-app-polyfill/ie11";
import "./styles/index.css";
import "bootstrap/dist/css/bootstrap-theme.css";
import "bootstrap/dist/css/bootstrap.css";
import * as sessionActions from "./actions/sessionActions";
import Router from "./components/Router";
import { Provider } from "react-redux";
import React from "react";
import ReactDOM from "react-dom";
import store from "./store";
import logger from "./lib/logger";

store
    .dispatch(sessionActions.loadSavedAuth())
    .catch(err => {
        logger.warn(err);
    })
    .then(() => {
        ReactDOM.render(
            <Provider store={store}>
                <Router />
            </Provider>,
            document.getElementById("root"),
        );
    });
