import { Modal } from "react-bootstrap";
import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { StyleSheet, css } from "aphrodite";

const strings = {
    reportTitle: "This search was conducted against the Habit Digital Database",
    searchInput: "Search Input: ",
    searchDate: "Search Date: ",
    found: "Results Found: ",
    accountEmail: "Account Email: ",
    accountCreateDate: "Account Creation Date: ",
};

const styles = StyleSheet.create({
    row: {
        display: "flex",
        width: "100%",
        justifyContent: "space-between",
        marginBottom: "12px",
    },
    label: {
        minWidth: "180px",
        fontSize: "16px",
    },
    value: {
        textAlign: "left",
        flex: 1,
        fontSize: "16px",
    },
});

export default class AuditReportModal extends PureComponent {
    static propTypes = {
        show: PropTypes.bool.isRequired,
        handleClose: PropTypes.func.isRequired,
        reportData: PropTypes.shape({
            searchInput: PropTypes.string.isRequired,
            searchDate: PropTypes.string,
            found: PropTypes.bool,
            accountEmail: PropTypes.string,
            accountCreateDate: PropTypes.string,
        }).isRequired,
    };

    _getFormattedDate = dateString => {
        const date = new Date(dateString);
        return `${date.toDateString()} ${date.toLocaleTimeString()}`;
    };

    render() {
        return (
            <Modal show={this.props.show} onHide={this.props.handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{strings.reportTitle}</Modal.Title>
                </Modal.Header>
                {this.props.reportData !== null ? (
                    <Modal.Body>
                        <div className={css(styles.row)}>
                            <span className={css(styles.label)}>{strings.searchInput}</span>
                            <span className={css(styles.value)}>
                                {this.props.reportData.searchInput}
                            </span>
                        </div>
                        <div className={css(styles.row)}>
                            <span className={css(styles.label)}>{strings.searchDate}</span>
                            <span className={css(styles.value)}>
                                {this._getFormattedDate(this.props.reportData.searchDate)}
                            </span>
                        </div>
                        <div className={css(styles.row)}>
                            <span className={css(styles.label)}>{strings.found}</span>
                            <span className={css(styles.value)}>
                                {this.props.reportData.found ? "Yes" : "no"}
                            </span>
                        </div>
                        <div className={css(styles.row)}>
                            <span className={css(styles.label)}>{strings.accountEmail}</span>
                            <span className={css(styles.value)}>
                                {this.props.reportData.accountEmail
                                    ? this.props.reportData.accountEmail
                                    : "None"}
                            </span>
                        </div>
                        <div className={css(styles.row)}>
                            <span className={css(styles.label)}>{strings.accountCreateDate}</span>
                            <span className={css(styles.value)}>
                                {this.props.reportData.accountCreateDate
                                    ? this._getFormattedDate(
                                          this.props.reportData.accountCreateDate,
                                      )
                                    : "None"}
                            </span>
                        </div>
                    </Modal.Body>
                ) : null}
            </Modal>
        );
    }
}
