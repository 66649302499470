import {
    Alert,
    Glyphicon,
    Modal,
    Button,
    FormGroup,
    ControlLabel,
    FormControl,
    HelpBlock,
} from "react-bootstrap";
import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import * as menuStatus from "../enums/menuStatus";
import { StyleSheet, css } from "aphrodite";

const strings = {
    status: "86'd",
    statusReason: "86'd Reason",
    reasonHelp: "This will appear in the app",
    save: "Save",
    cancel: "Cancel",
    changesHere: "Changes made will only affect this location's menu",
    yes: "Yes",
    no: "No",
};

const styles = StyleSheet.create({
    alertText: {
        marginLeft: 10,
        display: "inline-block",
    },
});

export default class StoreProductModal extends PureComponent {
    static propTypes = {
        show: PropTypes.bool.isRequired,
        title: PropTypes.string.isRequired,
        status: PropTypes.string.isRequired,
        statusReason: PropTypes.string,
        handleClose: PropTypes.func.isRequired,
        handleSave: PropTypes.func.isRequired,
    };

    state = {
        status: this.props.status,
        statusReason: this.props.statusReason ? this.props.statusReason : "",
    };

    _onSave = () => {
        this.props.handleSave(this.state.status, this.state.statusReason);
    };

    _onStatusChange = e => {
        this.setState({ status: e.target.value });
    };

    _onStatusReasonChange = e => {
        this.setState({ statusReason: e.target.value });
    };

    render() {
        return (
            <Modal show={this.props.show} onHide={this.props.handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{this.props.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <FormGroup>
                        <Alert bsClass={`alert alert-info`}>
                            <Glyphicon glyph="exclamation-sign" />
                            <div className={css(styles.alertText)}>{strings.changesHere}</div>
                        </Alert>
                        <ControlLabel>{strings.status}</ControlLabel>
                        <FormControl
                            componentClass="select"
                            value={this.state.status}
                            onChange={this._onStatusChange}
                        >
                            <option value={menuStatus.available.value}>{strings.no}</option>
                            <option value={menuStatus.unavailable.value}>{strings.yes}</option>
                        </FormControl>
                    </FormGroup>
                    {this.state.status === menuStatus.unavailable.value ? (
                        <FormGroup>
                            <ControlLabel>{strings.statusReason}</ControlLabel>
                            <FormControl
                                value={this.state.statusReason}
                                onChange={this._onStatusReasonChange}
                            />
                            <HelpBlock>{strings.reasonHelp}</HelpBlock>
                        </FormGroup>
                    ) : null}
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={this.props.handleClose}>{strings.cancel}</Button>
                    <Button onClick={this._onSave} bsStyle="primary">
                        {strings.save}
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }
}
