import instance from "./instance";
const responseData = response => response.data;

export function getModifiers() {
    return instance
        .request({
            url: `/cms/modifiers`,
            method: "GET",
        })
        .then(responseData);
}

export function getModifier(modifierId) {
    return instance
        .request({
            url: `/cms/modifiers/${modifierId}`,
            method: "GET",
        })
        .then(responseData);
}

export function updateModifier(
    modifierId,
    name,
    status,
    ingredientNoChangeSelectionName,
    optionalNoChangeSelectionName,
) {
    return instance
        .request({
            url: `/cms/modifiers/${modifierId}`,
            method: "POST",
            data: {
                name,
                status,
                ingredientNoChangeSelectionName,
                optionalNoChangeSelectionName,
            },
        })
        .then(responseData);
}

export function updateSelection(modifierId, selectionId, name, status) {
    return instance
        .request({
            url: `/cms/modifiers/${modifierId}/selection/${selectionId}`,
            method: "POST",
            data: {
                name,
                status,
            },
        })
        .then(responseData);
}
