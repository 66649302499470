import { Modal, Button, Row, Col } from "react-bootstrap";
import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { StyleSheet, css } from "aphrodite";
import dateFns from "date-fns";

const strings = {
    close: "Close",
    yes: "Yes",
    no: "No",
    lastOrderDate: "Last Order Date",
    birthDay: "Birth Day",
    charClub: "Char Club",
    isPhoneVerified: "Phone Number Verified",
    phoneCode: "Phone Verification Code",
    offerGranted: "Offer Granted",
    offerUsed: "Offer Used",
    never: "Never",
    na: "N/A",
};

const styles = StyleSheet.create({
    row: {
        marginBottom: 8,
    },
    subHeader: {
        margin: 0,
    },
});

export default class GuestDetailsModal extends PureComponent {
    static propTypes = {
        show: PropTypes.bool.isRequired,
        guest: PropTypes.object.isRequired,
        handleClose: PropTypes.func.isRequired,
    };

    _formatLastOrderDate = dateString => {
        if (!dateString) {
            return strings.never;
        }
        const date = new Date(dateString);
        return dateFns.format(date, "MM-DD-YYYY hh:mma");
    };

    render() {
        if (!this.props.show) {
            return null;
        }
        const guest = this.props.guest;
        return (
            <Modal show={this.props.show} onHide={this.props.handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{`${this.props.guest.firstName} ${this.props.guest.lastName}`}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row className={css(styles.row)}>
                        <Col md={6}>
                            <h5 className={css(styles.subHeader)}>{strings.lastOrderDate}</h5>
                            <div className="m-b">
                                {this._formatLastOrderDate(guest.lastOrderDate)}
                            </div>
                        </Col>
                        {guest.birthMonth && guest.birthYear ? (
                            <Col md={6}>
                                <h5 className={css(styles.subHeader)}>{strings.birthDay}</h5>
                                <div className="m-b">{`${guest.birthMonth}-${guest.birthYear}`}</div>
                            </Col>
                        ) : null}
                    </Row>
                    <Row className={css(styles.row)}>
                        <Col md={6}>
                            <h5 className={css(styles.subHeader)}>{strings.charClub}</h5>
                            <div className="m-b">{guest.charClub ? strings.yes : strings.no}</div>
                        </Col>
                        <Col md={6}>
                            <h5 className={css(styles.subHeader)}>{strings.isPhoneVerified}</h5>
                            <div className="m-b">
                                {guest.isPhoneNumberVerified ? strings.yes : strings.no}
                            </div>
                        </Col>
                    </Row>
                    <Row className={css(styles.row)}>
                        <Col md={6}>
                            <h5 className={css(styles.subHeader)}>{strings.offerGranted}</h5>
                            <div className="m-b">
                                {guest.offerGranted ? strings.yes : strings.no}
                            </div>
                        </Col>
                        <Col md={6}>
                            <h5 className={css(styles.subHeader)}>{strings.offerUsed}</h5>
                            <div className="m-b">{guest.offerUsed ? strings.yes : strings.no}</div>
                        </Col>
                    </Row>
                    <Row className={css(styles.row)}>
                        <Col md={6}>
                            <h5 className={css(styles.subHeader)}>{strings.phoneCode}</h5>
                            <div className="m-b">
                                {guest.phoneNumberVerificationCode
                                    ? guest.phoneNumberVerificationCode
                                    : strings.na}
                            </div>
                        </Col>
                        <Col md={6} />
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={this.props.handleClose}>{strings.close}</Button>
                </Modal.Footer>
            </Modal>
        );
    }
}
