import { Grid, Row, Col, FormGroup, FormControl, ControlLabel, Table } from "react-bootstrap";
import LinkButton from "../LinkButton";
import Loader from "../Loader";
import PageButtons from "../PageButtons";
import React, { PureComponent } from "react";
import * as storeStatus from "../../enums/storeStatus";
import * as stores from "../../lib/api/stores";
import logger from "../../lib/logger";
import { css, StyleSheet } from "aphrodite";

const styles = StyleSheet.create({
    center: {
        textAlign: "center",
    },
});

const strings = {
    title: "Stores | Habit CMS",
    stores: "Stores",
    filter: "Filter:",
    enterText: "Enter text",
    name: "Name",
    status: "Status",
    online: "Online",
    offline: "Offline",
    edit: "Edit",
    of: "of",
    storeNumber: "Store #",
    address: "Address",
};

const PAGE_SIZE = 20;

export default class StoresPage extends PureComponent {
    state = {
        currentPage: 0,
        filter: "",
        loading: true,
        stores: [],
        total: 0,
    };

    componentDidMount() {
        this._isMounted = true;
        document.title = strings.title;
        this._loadStores();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    _onFilterChange = e => {
        this.setState({ filter: e.target.value }, this._loadStores);
    };

    _loadStores = page => {
        if (!page) {
            page = 0;
        }
        if (!this.state.loading) {
            this.setState({ loading: true });
        }
        const currentReqTerms = {
            page: page,
            filter: this.state.filter,
        };
        this._currentReqTerms = currentReqTerms;
        stores
            .getStores(page * PAGE_SIZE, page * PAGE_SIZE + PAGE_SIZE, this.state.filter)
            .then(resp => {
                if (this._isMounted && this._currentReqTerms === currentReqTerms) {
                    this.setState({
                        loading: false,
                        stores: resp.stores,
                        total: resp.total,
                        currentPage: page,
                    });
                }
            })
            .catch(err => {
                logger.warn(err);
                if (this._isMounted) {
                    this.setState({ loading: false });
                }
            });
    };

    _handlePageChange = page => {
        this._loadStores(page - 1);
    };

    render() {
        return (
            <Grid>
                <Row>
                    <h3>{strings.stores}</h3>
                </Row>
                <Row>
                    <Col md={4}>
                        <FormGroup controlId="filter">
                            <ControlLabel>{strings.filter}</ControlLabel>
                            <FormControl
                                type="text"
                                value={this.state.filter}
                                placeholder={strings.enterText}
                                onChange={this._onFilterChange}
                            />
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col md={12}>
                        <Table striped bordered hover>
                            <thead>
                                <tr>
                                    <th>{strings.name}</th>
                                    <th>{strings.storeNumber}</th>
                                    <th>{strings.address}</th>
                                    <th>{strings.status}</th>
                                    <th>{strings.edit}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.loading
                                    ? null
                                    : this.state.stores.map(s => (
                                          <tr key={s.id}>
                                              <td>{s.name}</td>
                                              <td>{s.storeNumber}</td>
                                              <td>{`${s.address.street1}${
                                                  s.address.street2 ? " " + s.address.street2 : ""
                                              } ${s.address.city}, ${s.address.state}`}</td>
                                              <td>
                                                  {s.status === storeStatus.online
                                                      ? strings.online
                                                      : strings.offline}
                                              </td>
                                              <td>
                                                  <LinkButton
                                                      bsStyle="primary"
                                                      to={`/store/${s.id}`}
                                                  >
                                                      {strings.edit}
                                                  </LinkButton>
                                              </td>
                                          </tr>
                                      ))}
                            </tbody>
                        </Table>
                        {this.state.loading ? <Loader /> : null}
                    </Col>
                </Row>
                <Row>
                    <Col md={2}>
                        <div>
                            {Math.min((this.state.currentPage + 1) * PAGE_SIZE, this.state.total)}{" "}
                            {`${strings.of} `}
                            {this.state.total}
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col md={12} className={css(styles.center)}>
                        <PageButtons
                            numPages={this.state.total / PAGE_SIZE}
                            currentPage={this.state.currentPage + 1}
                            handlePageChange={this._handlePageChange}
                        />
                    </Col>
                </Row>
            </Grid>
        );
    }
}
