import instance from "./instance";
const responseData = response => response.data;

export function getProducts() {
    return instance
        .request({
            url: `/cms/featured`,
            method: "GET",
        })
        .then(responseData);
}

export function getProduct(featuredProductId) {
    return instance
        .request({
            url: `/cms/featured/${featuredProductId}`,
            method: "GET",
        })
        .then(responseData);
}

export function getFeaturableProducts(searchTerm) {
    return instance
        .request({
            url: `/cms/featured/featurable?search=${searchTerm}`,
            method: "GET",
        })
        .then(responseData);
}

export function addProduct(name, productId, productType) {
    return instance
        .request({
            url: `/cms/featured/`,
            method: "POST",
            data: {
                name,
                productId,
                productType,
            },
        })
        .then(responseData);
}

export function updateProduct(name, productId, productType, featuredProductId) {
    return instance
        .request({
            url: `/cms/featured/${featuredProductId}`,
            method: "POST",
            data: {
                name,
                productId,
                productType,
            },
        })
        .then(responseData);
}

export function addImage(featuredProductId, image, crop, imageType) {
    const formData = new FormData();
    formData.append("image", image);
    formData.append("centerX", crop.x);
    formData.append("centerY", crop.y);
    formData.append("width", crop.width);
    formData.append("height", crop.height);
    return instance
        .request({
            url: `/cms/featured/${featuredProductId}/image/${imageType}`,
            method: "POST",
            data: formData,
        })
        .then(responseData);
}

export function useInNav(featuredProductId) {
    return instance
        .request({
            url: `/cms/featured/${featuredProductId}/nav`,
            method: "POST",
        })
        .then(responseData);
}

export function deleteProduct(featuredProductId) {
    return instance
        .request({
            url: `/cms/featured/${featuredProductId}`,
            method: "DELETE",
        })
        .then(responseData);
}
