/* eslint-disable react/jsx-no-bind */

import * as contests from "../../lib/api/contests";
import FailedToLoadAlert from "../FailedToLoadAlert";
import LandingPageHeader from "../LandingPageHeader";
import LandingPageTable from "../LandingPageTable";
import Loader from "../Loader";
import logger from "../../lib/logger";
import PropTypes from "prop-types";
import React, { PureComponent } from "react";
import { Col, Grid, Row } from "react-bootstrap";

const strings = {
    documentTitle: "Contests | Habit CMS",
    pageTitle: "Contests",
    filterByName: "Filter by Name:",
    title: "Title",
    contestUrl: "contest",
    editContestUrl: "/contest",
    newSlug: "new",
    contestsTable: "Contests Table",
};

class ContestsPage extends PureComponent {
    static propTypes = {
        history: PropTypes.object.isRequired,
    };

    state = {
        isLoading: true,
        isError: false,
        contests: [],
        inUseNames: new Set(),
        inUseSlugs: new Set(),
        showInactiveContests: false,
        filter: "",
    };

    componentDidMount() {
        this._isMounted = true;
        document.title = strings.documentTitle;
        contests
            .getContests()
            .then(resp => {
                if (this._isMounted) {
                    this.setState({
                        contests: resp.contests,
                        inUseNames: new Set(resp.contests.map(c => c.name)),
                        inUseSlugs: new Set(resp.contests.map(c => c.slug)),
                    });
                }
            })
            .catch(e => {
                logger.warn(e);
                if (this._isMounted) {
                    this.setState({ isError: true });
                }
            })
            .finally(() => {
                if (this._isMounted) {
                    this.setState({ isLoading: false });
                }
            });
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    render() {
        const loweredFilter = this.state.filter.toLowerCase().trim();
        const visibleContests = this.state.contests.filter(c => {
            if (!c.isActive && !this.state.showInactiveContests) {
                return false;
            }

            return c.name.toLowerCase().indexOf(loweredFilter) !== -1;
        });

        return (
            <Grid>
                <LandingPageHeader
                    title={strings.pageTitle}
                    showInactivePages={this.state.showInactiveContests}
                    onChangeShowInactivePages={() =>
                        this.setState({ showInactiveContests: !this.state.showInactiveContests })
                    }
                    filterLabel={strings.filterByName}
                    filter={this.state.filter}
                    onChangeFilter={e => this.setState({ filter: e.target.value })}
                    onAddPage={() =>
                        this.props.history.push(`${strings.editContestUrl}/${strings.newSlug}`, {
                            inUseNames: this.state.inUseNames,
                            inUseSlugs: this.state.inUseSlugs,
                        })
                    }
                />
                <Row>
                    <Col md={12}>
                        <LandingPageTable
                            history={this.props.history}
                            items={visibleContests}
                            extraColumns={[
                                {
                                    headerText: strings.title,
                                    mapItemToCell: contest => (
                                        <td key={`${strings.title}-${contest.id}`}>
                                            {contest.name}
                                        </td>
                                    ),
                                },
                            ]}
                            landingPageUrl={strings.contestUrl}
                            editBaseUrl={strings.editContestUrl}
                            mapItemToEditStatePayload={contest => ({
                                contest,
                                inUseNames: this.state.inUseNames,
                                inUseSlugs: this.state.inUseSlugs,
                            })}
                        />
                        {this.state.isLoading ? <Loader /> : null}
                    </Col>
                </Row>
                {this.state.isError ? (
                    <Row>
                        <FailedToLoadAlert type={strings.contestsTable} />
                    </Row>
                ) : null}
            </Grid>
        );
    }
}

export default ContestsPage;
