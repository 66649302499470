/* eslint-disable no-console */
export default {
    log(...args) {
        console.log(...args);
    },

    debug(...args) {
        console.debug(...args);
    },

    warn(msg) {
        console.warn(msg);
    },

    error(err) {
        console.error(err);
    },

    info(msg) {
        console.info(msg);
    },

    trace(...args) {
        console.trace(...args);
    },

    table(...args) {
        console.table(...args);
    },
};
