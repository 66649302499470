import { Alert, Grid, Row, Col } from "react-bootstrap";
import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { StyleSheet, css } from "aphrodite";

const styles = StyleSheet.create({
    mt: {
        marginTop: 20,
    },
});

const strings = {
    title: "Failed to Load",
    message: "Something went wrong while loading this",
    message2: ". Please try again later.",
    object: "Object",
};

export default class FailedToLoadAlert extends PureComponent {
    static propTypes = {
        type: PropTypes.string,
    };

    render() {
        return (
            <Grid>
                <Row>
                    <Col md={12}>
                        <Alert
                            className={css(styles.mt)}
                            bsStyle="danger"
                            onDismiss={this.handleDismiss}
                        >
                            <h4>{`${strings.title} ${
                                this.props.type ? this.props.type : strings.object
                            }`}</h4>
                            <p>{`${strings.message} ${
                                this.props.type ? this.props.type : strings.object
                            }${strings.message2}`}</p>
                        </Alert>
                    </Col>
                </Row>
            </Grid>
        );
    }
}
